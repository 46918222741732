import React, { useRef } from "react";
import IndustriesBanner from "../../components/Banner/IndustriesBanner";
import MainTextCard from "../../components/Development/MainTextCard";
import styles from "./Industries.module.css";
import ecomm from "../../images/ecommerce.svg";
import ecommerece from "../../images/web1.png";
import real from "../../images/web4.png";
import realestate from "../../images/realestate.svg";
import edu from "../../images/web2.png";
import edu_ind from "../../images/education-industry.svg";
import health from "../../images/web3.png";
import health_care from "../../images/healthcare.svg";
import IndustriesLink from "./IndustriesLink";
import scop from "../../images/scop.svg";
import travel from "../../images/industry2.png";
import Devlopment from "../../components/Development/Devlopment";
import GotProject from "../../components/Got_Project/GotProject";
import Footer from "../../components/Footer/Footer";
import { TabTitle } from "../../Utils/GeneralFunction";

const Industries = () => {
  TabTitle("iSoft Technologies - Industries");
  const button1Ref = useRef();
  const button2Ref = useRef();
  const button3Ref = useRef();
  const button4Ref = useRef();
  const button5Ref = useRef();
  const buttonhead = useRef();
  const handleScroll = (ref) => {
    window.scrollTo({
      behavior: "smooth",
      top: ref.current.offsetTop,
    });
  };
  return (
    <>
      <IndustriesBanner
        heading="Industries We Serve"
        subheading="Learn how we help your industry"
        detail="We draw from our experience in other industries to make an impact in yours."
        background_image="main"
      />
      <section className={`${styles.industry_section}`}>
        <div ref={buttonhead} className={`${styles.section_links}`}>
          <div className="container">
            <ul className={`${styles.scroll_links}`}>
              <IndustriesLink
                onClick={() => handleScroll(button1Ref)}
                point={{ to: "#ecom", title: "Lean More" }}
                no="01"
                title="Ecommerce"
              />
              <IndustriesLink
                onClick={() => handleScroll(button2Ref)}
                point={{ to: "edu", title: "Lean More" }}
                no="02"
                title="Education"
              />
              <IndustriesLink
                onClick={() => handleScroll(button3Ref)}
                point={{ to: "real", title: "Lean More" }}
                no="03"
                title="Real Estate"
              />
              <IndustriesLink
                onClick={() => handleScroll(button4Ref)}
                point={{ to: "health", title: "Lean More" }}
                no="04"
                title="Healthcare"
              />
              <IndustriesLink
                onClick={() => handleScroll(button5Ref)}
                point={{ to: "travel", title: "Lean More" }}
                no="05"
                title="Travel & Tourism"
              />
            </ul>
          </div>
        </div>

        <MainTextCard
          heading="Your new teams’ cross-industry knowledge will take your project to a whole new level"
          detail=<div>
            <h3 class="font-weight-700">
              Your new teams’ cross-industry knowledge will take your project to
              a whole new level.
            </h3>
            <h4 className={`font-weight-400 text--web  ${styles.content}`}>
              With an experience of 11 years and 1000+ professionals onboard,
              iSoft Technologies offers full fledge software development
              services for both IT and non-IT organizations from various
              industries worldwide. Since its foundation, iSoft Technologies has
              built over 600 bespoke solutions for more than 30 industries. We
              acquired extensive experience for choosing a few key business
              domains in order to specialize in them. However, we keep
              developing industry-specific software for any field.
            </h4>
          </div>
        />
        {/* <SxrollTopBtn scrolling={() => handleScroll(buttonhead)} /> */}
      </section>
      <Devlopment
        background={true}
        linking="/industries/ecommerce"
        btn={button1Ref}
        title="Ecommerce"
        detail="Our customers from retail leverage our technology skills to digitalize their processes and improve buyers’ journeys. For retail, we offer the following expertise: e-commerce, ERP and CRM systems and predictive analytics. While developing IT projects for your industry, we consider the challenges you address: the need for better customer experience, improved supply chain and inventory, higher brand loyalty, and increased revenue.
"
        ids="ecom"
        image={ecommerece}
        industry={true}
        indust_icon={true}
        icon_image={ecomm}
        indust_points={true}
        title_list="Ecommerce Software We Develop"
        title_list2="Our Ecommerce software development services"
        detail1=" Service marketplaces"
        detail2="Business to Business (B2B)"
        detail3=" Peer to Peer"
        detail4=" Product marketplaces"
        listing1={true}
        listing2={true}
        detail5=" Business to Customer"
        detail_1=" Discovery phase"
        detail_2="  MVP creation"
        detail_3="  Marketplace"
      />
      <Devlopment
        background={true}
        btn={button2Ref}
        largecol={true}
        linking="/industries/education"
        title="
Education"
        detail="iSoft Technologies has been providing full-cycle custom eLearning development services to business entities and educational institutions since 2011. We deploy innovative eLearning applications, help expand the business online, and make the educational process efficient, interactive, and cost-effective. Our portfolio includes long-lasting relations and impressive cases for small, medium, and large educational institutions.
"
        edu={true}
        image={edu}
        indust_points={true}
        indust_icon={true}
        icon_image={edu_ind}
        indust_icon_sec={true}
        title_list="Education Software We Develop"
        title_list2="Our Education software development services"
        detail1=" Student Information & Management System Business to Business (B2B)"
        detail2="Learning Management Systems (LMS) Product marketplaces"
        detail3="Cloud Services for Education"
        listing1={true}
        detail4=" eLearning Software Solution"
        detail_1=" Discovery phase"
        detail_2=" UI/UX Design"
        detail_3=" Maintenance and Support"
        listing_1={true}
        detail_4="Security Management"
      />
      <Devlopment
        background={true}
        btn={button3Ref}
        title="Real Estate"
        detail="From responsive company websites and marketplaces to ERP systems, our real estate web developers and mobile experts can bring any project idea to life. Selling and managing real estate is a complex process with many different steps and pieces of software involved. iSoft Technologies is experienced in building custom web and mobile applications that tie into your firm’s suite of third-party solutions that cater to your business process. We delivers custom real estate software development services to create, grow and automate real estate businesses.

"
        linking="/industries/realestate"
        image={real}
        industry={true}
        indust_icon={true}
        icon_image={realestate}
        indust_points={true}
        title_list="Real Estate Software We Develop"
        title_list2="Our Real Estate software development services"
        detail1=" Real estate website development"
        detail2="Online marketplaces"
        detail3="Property management software"
        detail4="Real Estate CRMs"
        detail5=" ERP"
        detail6=" Crowdfunding platforms"
        detail7="Web & mobile real estate apps"
        listing1={true}
        listing2={true}
        listing3={true}
        listing4={true}
        detail_1="Mobile apps"
        detail_2="Clones of top real estate websites"
        detail_3="UX/UI Design"
        listing_1={true}
        listing_2={true}
        detail_4="Full-cycle software development"
        detail_5="Life-long maintenance and support"
      />
      <Devlopment
        background={true}
        health={true}
        btn={button4Ref}
        title="Healthcare"
        detail="We help our customers from the healthcare industry provide better services and treatment to patients. Our expertise is custom software development and modernization of front-desk management systems, health applications, patient data systems, patient data security. Our recent projects enriched our experience building IoT-enabled platforms for clinical trials and national-level systems for medical error reporting and medical research funding.
"
        linking="/industries/healthcare"
        approach_change={true}
        arrow_value={true}
        approach={true}
        indust_icon_sec={true}
        textclr={true}
        icon_image={health_care}
        image={health}
        title_list="Healthcare Software We Develop"
        title_list2="Our Healthcare software development services"
        detail1="Hospital Information System"
        detail2="Telemedicine Software Development"
        detail3="Patient Portal Development"
        detail4="Hospital Management Software"
        detail5="Pharmacy Management System"
        detail6="SAP Services for Healthcare"
        listing1={true}
        listing2={true}
        listing3={true}
        detail_1="Electronic Medical Record and Electronic Health Record solutions"
        detail_2="Mobile Healthcare Applications"
        detail_3="Remote Patient Monitoring Software"
        listing_1={true}
        detail_4="Healthcare AI Software Solutions"
        detail_5="Life-long maintenance and support"
      />
      <Devlopment
        btn={button5Ref}
        title="Travel & Tourism"
        detail="The Digital Era has transformed the travel Industry. Consumers are relying more and more on digital channels and platforms for all of their travel needs, from researching to booking. The survival and success of Travel & Hospitality companies depends on customized services and exceptional guest experience, before, during and after the travel.
"
        linking="/industries/travel-tourism"
        image={travel}
        industry={true}
        indust_icon={true}
        icon_image={scop}
        indust_points={true}
        title_list="Travel & Tourism Software We Develop"
        title_list2="Our Travel & Tourism software development services"
        detail1="Booking Engines"
        detail2="Hotel Management"
        detail3=" Travel Portal Development"
        detail4="Payment Management Software"
        listing1={true}
        listing2={true}
        listing3={true}
        detail5=" Custome CRM Development"
        detail6="Mobile App Development"
        detail_1=" Discovery phase"
        detail_2="Solution Architecture"
        listing_1={true}
        listing_2={true}
        detail_3=" UX/UI Design"
        detail_4="Platform Maintenance"
        detail_5="Security Management"
      />
      <GotProject every={true} />
      <Footer contacting={true} />
    </>
  );
};

export default Industries;
