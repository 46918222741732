import React from "react";

import IndustriesBanner from "../../components/Banner/IndustriesBanner";
import CaseStudiesBanner from "../../components/CaseStudy/CaseStudiesBanner";
import MainTextCard from "../../components/Development/MainTextCard";
import Footer from "../../components/Footer/Footer";
import GotProject from "../../components/Got_Project/GotProject";
import IndustriesAcc from "../../components/Industries/IndustriesAcc";
import IndustriesDevs from "../../components/Industries/IndustriesDevs";
import IndustriesExperties from "../../components/Industries/IndustriesExperties";
import ecommimage from "../../images/healthCare.jpg";
import { TabTitle } from "../../Utils/GeneralFunction";
import styles from "./IndustriesEcommerce.module.css";
const IndustriesHealthCare = () => {
  TabTitle("Industries | HealthCare");
  return (
    <>
      <IndustriesBanner
        heading="Helping Healthcare Businesses Take a Step Toward Digital Transformation"
        subheading="Healthcare App Development Services"
        detail="We develop Healthcare websites by focusing on the ease of the patients and the nature of the services offered by the institute."
        background_image="health"
        industry_btn={true}
        ind_btn_text=" Request a proposal"
      />
      <MainTextCard
        page="ecomm"
        heading=" Scale with Our Ecommerce App Development Services"
        detail=<div>
          <h2 className="font-weight-700">
            Scale with our Healthcare App development services
          </h2>

          <h4 className={`font-weight-400 text--web pt-1 ${styles.content}`}>
            The Healthcare apps developed by iSoft Technologies always have the aim of making it simpler for the patients to find the doctor and get an appointment.
          </h4>
          <h4 className={`font-weight-400 text--web pt-1 ${styles.content}`}>
            In order to make it easier for the institute to let people know what services are being offered, every department is highlighted individually. It has the services mentioned in it along with the names of the doctors who have specialized in them. If the institute provides a service of ambulance, collection of test sample from home or any other, proper prominence is given to it in the development procedure so that the patients take advantage of such convenient offers of the institute. Taking phone calls isn’t necessary, the institute can easily schedule the appointments through the website.
          </h4>
        </div>
      />
      <IndustriesDevs
        page="health"
        title="Healthcare Software Development"
        heading="Providing relevant and efficient software services"
        imagebig={ecommimage}
        textmain="Get profit from a bespoke Real Estate solution."
        textcontent="Have resource to the services of experienced eCommerce developers at Innowise Group"
      />
      <IndustriesExperties
        title="Our Healthcare Development Expertise"
        heading="We use the most perfect combination of technologies while creating a Healthcare app, this is how it gets way easier for the patients to get medical services with just a few clicks, and the institute to highlight its services, departments, and medical facilities. Our team makes sure that the patient doesn’t have to go through lengthy lists of doctors to find the one that they need, so precise lists of departments are incorporated. Additionally, the institute won’t have to call the patient asking for the basic details, the moment the patient will book an appointment, he/she will have to fill a form in which they will enter their data. The proficiency of our developers also allows us to create customized healthcare websites according to the healthcare institute’s requirements."
        page="health"
      />
      <CaseStudiesBanner title="Healthcare case studies" />
      <IndustriesAcc
        page="health"
        heading="Healthcare web development all your questions answered"
        content="Find the answers below to the most common questions regarding Healthcare app development or contact us directly."
      />
      <GotProject every={true} />
      <Footer contacting={true} />
    </>
  );
};

export default IndustriesHealthCare;
