import React from "react";
import styles from "./MobExpert.module.css";
const MobExpert = ({ image, title, detail }) => {
  return (
    <>
      <div className={`${styles.team_feature}`}>
        <span className={`${styles.team_icon}`}>
          <img alt="" src={image} />
        </span>
        <h2 className="font-weight-700">{title}</h2>
        <h4 className="font-weight-400 text--web">{detail}</h4>
      </div>
    </>
  );
};

export default MobExpert;
