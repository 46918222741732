import React from "react";
import { NavLink } from "react-router-dom";
import { companydropdown } from "./navitems";

const Company = ({ companies, onClick }) => {
  // const [company, setCompany] = useState(false);
  return (
    <>
      {companies === true && (
        <ul className="dropdown-menu dropdown-menu-small">
          {companydropdown.map((items) => {
            return (
              <li key={items.id}>
                <NavLink to={items.url} onClick={onClick}>
                  {items.title}
                </NavLink>
              </li>
            );
          })}
        </ul>
      )}
    </>
  );
};

export default Company;
