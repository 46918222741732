import React from "react";
import { Routes, Route } from "react-router-dom";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import HomeScreen from "./screens/Home_Screen/Home_Screen";
import GotProject from "./components/Got_Project/GotProject";
import About from "./screens/About/About";
import CaseStudy from "./screens/Case_Study/Case_Study";
import Career from "./screens/Career/Career";
import Service from "./screens/Servicess/Service";
import CareerDetail from "./screens/Career_Details/CareerDetail";
import Contact from "./screens/ContactUs/Contact";
import AgileDevelopment from "./screens/AgileDevelopment/AgileDevelopment";
import WebDevlopment from "./screens/WebDevlopment/WebDevlopment";
import DigitalProductDesign from "./screens/DigitalProductDesign/DigitalProductDesign";
import CustomSoftDetail from "./screens/CustomSoftware/CustomSoftDetail";
import Coding from "./screens/CodingLanguage/Coding";
import AllCaseStudies from "./screens/AllCaseStudies/AllCaseStudies";
import MobileDevDetail from "./screens/MobileDev/MobileDevDetail";
import QualityAssurance from "./screens/Quality/QualityAssurance";
import SoftwareArch from "./screens/Architecture/SoftwareArch";
import Industries from "./screens/Industries/Industries";
import Database from "./screens/DataBase/Database";
import Freamework from "./screens/Framework/Freamework";
import Hosting from "./screens/Hosting/Hosting";
import Integration from "./screens/IIntegration/Integration";
import Testing from "./screens/Testing/Testing";
import WebTechnology from "./screens/WebTech/WebTechnology";
import GreenCase from "./screens/Case_Study/GreenCase";
import IndustryDetails from "./screens/IndustryDetail/IndustryDetails";
import CareerDetailPhp from "./screens/Career_Details/CareerDetailPhp";
import ScrollToTop from "./Utils/ScrollToTop";
import YellowCase from "./screens/Case_Study/YellowCase";
import CaseStudy4 from "./screens/Case_Study/CaseStudy4";
import IndustriesEcommerce from "./screens/Industries/IndustriesEcommerce";
import IndustriesEducation from "./screens/Industries/IndustriesEducation";
import IndustriesHealthCare from "./screens/Industries/IndustriesHealthCare";
import IndustriesRealEstate from "./screens/Industries/IndustriesRealEstate";
import IndustriesTravel from "./screens/Industries/IndustriesTravel";

const App = ({ every, contacting }) => {
  return (
    <>
      <Header />
      <ScrollToTop />
      <main>
        <div>
          <Routes>
            <Route path="/" element={<HomeScreen />} />
            <Route path="/about-us" element={<About />} />
            <Route path="our-work" element={<AllCaseStudies />} />
            <Route path="our-work/universal-visas" element={<CaseStudy />} />
            <Route path="our-work/behind-the-veil" element={<YellowCase />} />
            <Route path="our-work/phundee" element={<CaseStudy4 />} />
            <Route path="career" element={<Career />} />
            <Route path="services" element={<Service />} />
            <Route
              path="career/mern-stack-developer-job-details"
              element={<CareerDetail />}
            />
            <Route
              path="career/full-stack-developer-job-details"
              element={<CareerDetailPhp />}
            />
            <Route path="contact-us" element={<Contact />} />
            <Route
              path="services/agile-development"
              element={<AgileDevelopment />}
            />
            <Route path="industries-details" element={<IndustryDetails />} />
            <Route
              path="services/web-development"
              element={<WebDevlopment />}
            />
            <Route
              path="services/digital-product-design"
              element={<DigitalProductDesign />}
            />
            <Route
              path="services/custom-software-development"
              element={<CustomSoftDetail />}
            />
            <Route path="Coding" element={<Coding />} />
            <Route
              path="services/mobile-development"
              element={<MobileDevDetail />}
            />
            <Route
              path="services/quality-assurance"
              element={<QualityAssurance />}
            />
            <Route
              path="services/software-architecture"
              element={<SoftwareArch />}
            />
            <Route path="industries" element={<Industries />} />
            <Route
              path="industries/ecommerce"
              element={<IndustriesEcommerce />}
            />
            <Route
              path="industries/education"
              element={<IndustriesEducation />}
            />
            <Route
              path="industries/healthcare"
              element={<IndustriesHealthCare />}
            />
            <Route
              path="industries/realestate"
              element={<IndustriesRealEstate />}
            />
            <Route
              path="industries/travel-tourism"
              element={<IndustriesTravel />}
            />
            <Route path="DataBase" element={<Database />} />
            <Route path="Hosting" element={<Hosting />} />
            <Route path="FrameWork" element={<Freamework />} />
            <Route path="Testing/Quality" element={<Testing />} />
            <Route path="Integration" element={<Integration />} />
            <Route path="Web-Technology" element={<WebTechnology />} />
            <Route path="our-work/yabee.me" element={<GreenCase />} />
            <Route path="*" element={<HomeScreen />} />
          </Routes>

          {every === true && <GotProject />}
          {contacting === true && <Footer />}
        </div>
      </main>
    </>
  );
};

export default App;
