import React from "react";
import styles from "./TestimonialsCard.module.css";
export const TestimonialsCard = ({
  details,
  headingName,
  textrating,
  image,
}) => {
  return (
    <>
      <div className="swiper-slide">
        <div className={`${styles.testimonial_slide} text-center`}>
          <p className={styles.main_text}>{details}</p>
          <div className={styles.testimonial_name}>
            <strong>{headingName}</strong>
            <div className={`${styles.testimonial_rating}`}>
              <span className={`${styles.rating_text} text--web`}>
                {textrating}
              </span>
              <span className={`${styles.ratings}`}>
                <img alt="" src={image} />
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
