import React from "react";
import styles from "./intro.module.css";
const Intro = ({ heading, detail }) => {
  return (
    <>
      <section className={`${styles.company_section}`}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10 design-text text-center">
              <h2 className="font-weight-700 mb-3">{heading}</h2>
              <h4 className="font-weight-400 text--web txt">{detail}</h4>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Intro;
