import React from "react";
import IndustryList from "../Industries/IndustryList";
import styles from "./Development.module.css";
import image_arrow from "../../images/arrow1.svg";
import image_arrow1 from "../../images/arrow1-1.svg";
import { Link } from "react-router-dom";
const Devlopment = ({
  linking,
  edu,
  health,
  no,
  title,
  heading,
  detail,
  image,
  industry,
  ourapp,
  sub_detail,
  approach,
  background,
  textclr,
  approach_change,
  indust_icon,
  largecol,
  indust_icon_sec,
  arrow_value,
  icon_image,
  indust_points,
  detail1,
  detail2,
  detail3,
  detail4,
  detail5,
  detail6,
  detail7,
  detail_1,
  detail_2,
  detail_3,
  detail_4,
  detail_5,
  detail_6,
  detail_7,
  title_list,
  listing1,
  listing2,
  listing3,
  listing4,
  listing_1,
  listing_2,
  listing_3,
  listing_4,
  btn,
  title_list2,
  services,
}) => {
  return (
    <>
      {industry === true ? (
        <section ref={btn} className={` ${styles.industry_realestate} `}>
          <div className="container">
            <div
              className={`row ${services === true ? `align-items-center` : ``}`}
            >
              <div className={`col-lg-6 ${styles.industry_text}`}>
                {indust_icon === true ? (
                  <span className={`${styles.industry_iconz}`}>
                    <img alt="" src={icon_image} />
                  </span>
                ) : (
                  <p className="text--gradient font-weight-600">{no}</p>
                )}
                <h2 className={`font-weight-700 ${styles.industry_link}`}>
                  <Link className="d-inline-block" to={linking}>
                    {title}
                    {indust_icon === true && (
                      <span>
                        <img alt="" src={image_arrow} />
                      </span>
                    )}
                  </Link>
                </h2>
                <p className="text--web font-weight-600">{heading}</p>
                <h4 className="font-weight-400 text--web">{detail}</h4>
              </div>
              <div className="col-lg-6">
                <div className={`${styles.industry_thumb}`}>
                  <img alt="" src={image} />
                </div>
              </div>

              {indust_points === true && (
                <>
                  <div className="col-lg-6 industry-list-block">
                    <h4 className="font-weight-700 text--gradient mb-3">
                      {title_list}
                    </h4>
                    <ul className="industry-list">
                      <IndustryList detail={detail1} />
                      <IndustryList detail={detail2} />

                      <IndustryList detail={detail3} />
                      {listing1 === true && <IndustryList detail={detail4} />}
                      {listing2 === true && <IndustryList detail={detail5} />}
                      {listing3 === true && <IndustryList detail={detail6} />}
                      {listing4 === true && <IndustryList detail={detail7} />}
                    </ul>
                  </div>
                  <div className="col-lg-6 industry-list-block">
                    <h4 className="font-weight-700 text--gradient mb-3">
                      {title_list2}
                    </h4>
                    <ul className="industry-list">
                      <IndustryList detail={detail_1} />
                      <IndustryList detail={detail_2} />
                      <IndustryList detail={detail_3} />
                      {listing_1 === true && <IndustryList detail={detail_4} />}
                      {listing_2 === true && <IndustryList detail={detail_5} />}
                      {listing_3 === true && <IndustryList detail={detail_6} />}
                      {listing_4 === true && <IndustryList detail={detail_7} />}
                    </ul>
                  </div>
                </>
              )}
            </div>
            {ourapp === true && (
              <h4 className="font-weight-400 text--web commonline">
                {sub_detail}
              </h4>
            )}
          </div>
        </section>
      ) : (
        <section
          ref={btn}
          className={
            approach === true
              ? `bg--gradient ${styles.customapproach} ${
                  background === true ? ` ` : `uiux-dev`
                } `
              : `industry-education ${
                  background === true ? `` : `backend-dev`
                } `
          }
        >
          <div className="container">
            <div
              className={`row ${services === true ? `align-items-center` : ``}`}
            >
              <div
                className={`col-lg-6 dev ${
                  background === true ? `${styles.colfirst}` : ``
                } `}
              >
                <div className={`${styles.industry_thumb}`}>
                  <img alt="" src={image} />
                </div>
              </div>
              <div
                className={`col-lg-6 ${styles.industry_text} dev ${styles.colsecond}`}
              >
                {indust_icon_sec === true ? (
                  <span className={`${styles.industry_iconz}`}>
                    <img alt="" src={icon_image} />
                  </span>
                ) : (
                  <p className="text-white font-weight-600">{no}</p>
                )}
                <h2
                  className={`font-weight-700 text-white ${styles.industry_link}`}
                >
                  <Link className="d-inline-block" to={linking}>
                    {title}
                    {edu === true && (
                      <span>
                        <img alt="" src={image_arrow} />
                      </span>
                    )}
                    {health === true && (
                      <span>
                        <img alt="" src={image_arrow1} />
                      </span>
                    )}
                  </Link>
                </h2>
                <p className="text-white font-weight-600">{heading}</p>
                <h4 className="font-weight-400 text-white">{detail}</h4>
              </div>
              {indust_points === true && (
                <>
                  <div
                    className={
                      largecol === true
                        ? `col-lg-7 industry-list-block`
                        : `col-lg-6 industry-list-block`
                    }
                  >
                    <h4
                      className={
                        textclr === true
                          ? `font-weight-700 text-white mb-3`
                          : `font-weight-700 text--gradient mb-3`
                      }
                    >
                      {title_list}
                    </h4>
                    <ul className="industry-list">
                      <IndustryList
                        textwhite={true}
                        arrow={arrow_value}
                        detail={detail1}
                      />
                      <IndustryList
                        textwhite={true}
                        arrow={arrow_value}
                        detail={detail2}
                      />

                      <IndustryList
                        textwhite={true}
                        arrow={arrow_value}
                        detail={detail3}
                      />
                      {listing1 === true && (
                        <IndustryList
                          textwhite={true}
                          arrow={arrow_value}
                          detail={detail4}
                        />
                      )}
                      {listing2 === true && (
                        <IndustryList
                          textwhite={true}
                          arrow={arrow_value}
                          detail={detail5}
                        />
                      )}
                      {listing3 === true && (
                        <IndustryList
                          textwhite={true}
                          arrow={arrow_value}
                          detail={detail6}
                        />
                      )}
                      {listing4 === true && (
                        <IndustryList
                          textwhite={true}
                          arrow={arrow_value}
                          detail={detail7}
                        />
                      )}
                    </ul>
                  </div>
                  <div
                    className={
                      largecol === true
                        ? `col-lg-5 industry-list-block`
                        : `col-lg-6 industry-list-block`
                    }
                  >
                    <h4
                      className={
                        textclr === true
                          ? `font-weight-700 text-white mb-3`
                          : `font-weight-700 text--gradient mb-3`
                      }
                    >
                      {title_list2}
                    </h4>
                    <ul className="industry-list">
                      <IndustryList
                        textwhite={true}
                        arrow={arrow_value}
                        detail={detail_1}
                      />
                      <IndustryList
                        textwhite={true}
                        arrow={arrow_value}
                        detail={detail_2}
                      />
                      <IndustryList
                        textwhite={true}
                        arrow={arrow_value}
                        detail={detail_3}
                      />
                      {listing_1 === true && (
                        <IndustryList
                          textwhite={true}
                          arrow={arrow_value}
                          detail={detail_4}
                        />
                      )}
                      {listing_2 === true && (
                        <IndustryList
                          textwhite={true}
                          arrow={arrow_value}
                          detail={detail_5}
                        />
                      )}
                      {listing_3 === true && (
                        <IndustryList
                          textwhite={true}
                          arrow={arrow_value}
                          detail={detail_6}
                        />
                      )}
                      {listing_4 === true && (
                        <IndustryList
                          textwhite={true}
                          arrow={arrow_value}
                          detail={detail_7}
                        />
                      )}
                    </ul>
                  </div>
                </>
              )}
              {approach_change === true && (
                <>
                  <div className="col-lg-5 industry-list-block">
                    <h4
                      className={
                        textclr === true
                          ? `font-weight-700 text-white mb-3`
                          : `font-weight-700 text--gradient mb-3`
                      }
                    >
                      {title_list}
                    </h4>
                    <ul className="industry-list">
                      <IndustryList
                        textwhite={true}
                        arrow={arrow_value}
                        detail={detail1}
                      />
                      <IndustryList
                        textwhite={true}
                        arrow={arrow_value}
                        detail={detail2}
                      />

                      <IndustryList
                        textwhite={true}
                        arrow={arrow_value}
                        detail={detail3}
                      />
                      {listing1 === true && (
                        <IndustryList
                          textwhite={true}
                          arrow={arrow_value}
                          detail={detail4}
                        />
                      )}
                      {listing2 === true && (
                        <IndustryList
                          textwhite={true}
                          arrow={arrow_value}
                          detail={detail5}
                        />
                      )}
                      {listing3 === true && (
                        <IndustryList
                          textwhite={true}
                          arrow={arrow_value}
                          detail={detail6}
                        />
                      )}
                      {listing4 === true && (
                        <IndustryList
                          textwhite={true}
                          arrow={arrow_value}
                          detail={detail7}
                        />
                      )}
                    </ul>
                  </div>
                  <div className="col-lg-7 industry-list-block">
                    <h4
                      className={
                        textclr === true
                          ? `font-weight-700 text-white mb-3`
                          : `font-weight-700 text--gradient mb-3`
                      }
                    >
                      {title_list2}
                    </h4>
                    <ul className="industry-list">
                      <IndustryList
                        textwhite={true}
                        arrow={arrow_value}
                        detail={detail_1}
                      />
                      <IndustryList
                        textwhite={true}
                        arrow={arrow_value}
                        detail={detail_2}
                      />
                      <IndustryList
                        textwhite={true}
                        arrow={arrow_value}
                        detail={detail_3}
                      />
                      {listing_1 === true && (
                        <IndustryList
                          textwhite={true}
                          arrow={arrow_value}
                          detail={detail_4}
                        />
                      )}
                      {listing_2 === true && (
                        <IndustryList
                          textwhite={true}
                          arrow={arrow_value}
                          detail={detail_5}
                        />
                      )}
                      {listing_3 === true && (
                        <IndustryList
                          textwhite={true}
                          arrow={arrow_value}
                          detail={detail_6}
                        />
                      )}
                      {listing_4 === true && (
                        <IndustryList
                          textwhite={true}
                          arrow={arrow_value}
                          detail={detail_7}
                        />
                      )}
                    </ul>
                  </div>
                </>
              )}
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Devlopment;
