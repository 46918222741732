import React, { useCallback, useRef } from "react";
import service_left from "../../images/aboutNew1.png";
import styles from "./ExploreCard.module.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";
import { exploredata } from "./exploredata";
import { useState } from "react";

const Explore = (props) => {
  const sliderRef = useRef(null);
  const [styling, setStyling] = useState(1);
  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();

    const prev = sliderRef.current.swiper.realIndex + 1;

    exploredata.forEach((menu) => {
      if (menu.id === prev) return setStyling(menu.id);
    });
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
    const next = sliderRef.current.swiper.realIndex + 1;

    exploredata.forEach((menu) => {
      if (menu.id === next) return setStyling(menu.id);
    });
  }, []);

  return (
    <>
      <section className="some-services">
        <span className="service-vector">
          <img alt="" src={props.picForHome ||service_left} />
        </span>
        <div className="container service-title">
          <div className="row  align-items-end">
            <div className="col-lg-6">
              <h2 className="font-weight-700">
                Explore Our Services with just One Click.
              </h2>
            </div>
            <div className="col-lg-6">
              <h4 className="">
                Getting the job done was never easier.
                <span className="font-weight-700">
                  See what we can do for your company.
                </span>
              </h4>
            </div>
          </div>
        </div>

        <div className="swiper serviceSlider">
          <div className="swiper-wrapper">
            <Swiper
              className="swiper-border"
              ref={sliderRef}
              loop={true}
              spaceBetween={15}
              breakpoints={{
                320: {
                  slidesPerView: 1.5,
                },
                768: {
                  slidesPerView: 2,
                },
                991: {
                  slidesPerView: 3,
                },
                1440: {
                  slidesPerView: 4,
                },
              }}
            >
              {exploredata.map((menu) => {
                return (
                  <SwiperSlide key={menu.id}>
                    <Link to={menu.url}>
                      <div className="swiper-slide">
                        <div
                          onMouseEnter={() => setStyling(true)}
                          onMouseLeave={() =>
                            setStyling(sliderRef.current.swiper.realIndex + 1)
                          }
                          className={
                            styling === menu.id
                              ? `${styles.service_box_active}`
                              : `${styles.service_box}`
                          }
                        >
                          <span className={`${styles.service_icon}`}>
                            <img alt="" src={menu.display} />
                            <img alt="" src={menu.subdisplay} />
                          </span>
                          <h3>{menu.title}</h3>
                          <p>{menu.content}</p>
                        </div>
                      </div>
                    </Link>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>

          <div className="swiper-customnav container">
            <div className="swiper-button-next" onClick={handleNext}></div>
            <div className="swiper-button-prev" onClick={handlePrev}></div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Explore;
