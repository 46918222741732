import React from "react";
import IndustriesBanner from "../../components/Banner/IndustriesBanner";
import MainTextCard from "../../components/Development/MainTextCard";
import { TabTitle } from "../../Utils/GeneralFunction";

const IndustryDetails = () => {
  TabTitle("iSoft Technologies - Industries Details");
  return (
    <>
      <IndustriesBanner
        heading="ALL-IN-ONE FINTECH SOFTWARE DEVELOPMENT"
        subheading="Transform business with fintech solutions"
        detail="Use financial technology to create a seamless user experience, innovate, and deliver products your customers need.."
        ind_btn_text="Schedule a free expert session"
        industry_btn={true}
      />
      <MainTextCard
        heading="Exceed the expectations of your customer"
        detail="For banks, insurers, and other financial companies consistent digital journey is a must. Their customers are expecting complex and highly personalized products, but also a frictionless experience.

"
        ind_detail={true}
        detail2="Fintechs are quickly taking the field where incumbents have been failing. Using emerging technology, they deliver customer-centric products and find new efficiencies."
      />
    </>
  );
};

export default IndustryDetails;
