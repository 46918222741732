import React from "react";
import { Link } from "react-router-dom";

import styles from "./IndustriesCard.module.css";
export const IndustriesCard = ({
  imagepri,
  imagesec,
  headingpri,
  headingsec,
  linkpri,
  linksec,
  learn,
  clicking,
}) => {
  return (
    <>
      <div className={`col-lg-4 ${styles.ind_col} col-md-6`}>
        <div className={styles.industry_slide}>
          <span className={styles.ind_thumb}>
            <img alt="" src={imagepri} />
          </span>
          <div className="ind-text">
            <h3>{headingpri}</h3>
            <Link
              className={`text--gradient ${styles.btn_industry} font-weight-700`}
              to={learn}
              onClick={clicking}
            >
              Learn more <i className="fa-solid fa-arrow-right-long"></i>
            </Link>
          </div>
        </div>
      </div>

      {/* <div className="industries-wrapper row">
        <div className="col-lg-4 col-md-6">
          <div className={styles.industry_slide}>
            <span className={styles.ind_thumb}>
              <img alt="" src={imagepri} />
            </span>
            <div className="ind-text">
              <h3>{headingpri}</h3>
              <Link
                className={`text--gradient  ${styles.btn_industry}  font-weight-700`}
                to={linkpri.to}
              >
                {linksec.title} <i className="fas fa-arrow-right"></i>
              </Link>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className={styles.industry_slide}>
              <span className={styles.ind_thumb}>
                <img alt="" src={imagesec} />
              </span>
              <div className="ind-text">
                <h3>{headingsec}</h3>
                <Link
                  className={` text--gradient ${styles.btn_industry}  font-weight-700`}
                  to={linksec.to}
                >
                  {linksec.title}
                  <i className="fas fa-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};
