import React from "react";
import { Link } from "react-router-dom";
import styles from "./IndustriesLink.module.css";
const IndustriesLink = ({ title, no, point, onClick }) => {
  return (
    <>
      <li>
        <Link onClick={onClick} className={`${styles.linking}`} to={point}>
          <span className={`${styles.txt}`}>{title}</span>
          <span className={`${styles.nums}`}>{no}</span>
        </Link>
      </li>
    </>
  );
};

export default IndustriesLink;
