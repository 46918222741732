import React from "react";
import "./DevelopmentCard.css";
export const DevelopmentCard = ({ image }) => {
  return (
    <>
      <div className="col-4">
        <div className="experty-container bg-white">
          <img alt="" src={image} />
        </div>
      </div>
    </>
  );
};
