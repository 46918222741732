export const emailconfig = () => {
  let paths = window.location.hostname;

  let splitpath = paths.split(".");
  var lastWord = splitpath[splitpath.length - 1];

  if (lastWord === "pk") {
    return process.env.REACT_APP_EMAIL_PK;
  } else if (lastWord === "com") {
    return process.env.REACT_APP_EMAIL_COM;
  } else {
    return "https://localhost";
  }
};
