import React from "react";

export const CareerVision = ({ image, title, detail }) => {
  return (
    <>
      <div className="col-lg-6">
        <div className="career-vision bg--gradient text-white text-center">
          <img className="c-icon" alt="" src={image} />
          <h3 className="font-weight-700 text-capitalize">{title}</h3>
          <h4 className="font-weight-400">{detail}</h4>
        </div>
      </div>
    </>
  );
};
