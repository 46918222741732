import React, { useCallback, useRef } from "react";
import office1 from "../../images/about1.jpg";
import office2 from "../../images/about2.jpg";
import office3 from "../../images/about3.jpg";
import office4 from "../../images/about4.jpg";
import greencase1 from "../../images/casestudy-slide1.jpg";
import greencase2 from "../../images/casestudy-slide2.jpg";
import greencase3 from "../../images/casestudy-slide3.jpg";
import greencase4 from "../../images/casestudy-slide4.jpg";
import redcase1 from "../../images/office-gallery1.jpg";
import redcase2 from "../../images/office-gallery2.jpg";
import redcase3 from "../../images/office-gallery3.jpg";
import redcase4 from "../../images/office-gallery4.jpg";
import yellow1 from "../../images/yellow1.jpg";
import yellow2 from "../../images/yellow2.jpg";
import yellow3 from "../../images/yellow3.jpg";
import yellow4 from "../../images/yellow4.jpg";
import greengrey1 from "../../images/casestudy4-1.jpg";
import greengrey2 from "../../images/casestudy4-2.jpg";
import greengrey3 from "../../images/casestudy4-3.jpg";
import greengrey4 from "../../images/casestudy4-4.jpg";
import { Swiper, SwiperSlide } from "swiper/react";
import { OfficeCard } from "./OfficeCard";

const Office = ({ greencase, redcase, aboutgal, yellowcase, greengrey }) => {
  const sliderRef = useRef(null);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);
  return (
    <>
      <div className="swiper officeSwiper casestudy-gallery">
        <div className="swiper-wrapper">
          <Swiper
            loop={true}
            spaceBetween={20}
            breakpoints={{
              767: {
                slidesPerView: 2,
              },
              991: {
                slidesPerView: 3,
              },
              1024: {
                slidesPerView: 4,
              },
            }}
            ref={sliderRef}
          >
            <SwiperSlide>
              {redcase === true && <OfficeCard study={true} image={redcase1} />}
              {yellowcase === true && (
                <OfficeCard study={true} image={yellow1} />
              )}
              {greencase === true && (
                <OfficeCard study={true} image={greencase1} />
              )}
              {greengrey === true && (
                <OfficeCard study={true} image={greengrey1} />
              )}
              {aboutgal === true && <OfficeCard image={office1} />}
            </SwiperSlide>
            <SwiperSlide>
              {yellowcase === true && (
                <OfficeCard study={true} image={yellow2} />
              )}
              {redcase === true && <OfficeCard study={true} image={redcase2} />}
              {greencase === true && (
                <OfficeCard study={true} image={greencase2} />
              )}
              {greengrey === true && (
                <OfficeCard study={true} image={greengrey2} />
              )}
              {aboutgal === true && <OfficeCard image={office2} />}
            </SwiperSlide>
            <SwiperSlide>
              {yellowcase === true && (
                <OfficeCard study={true} image={yellow3} />
              )}
              {redcase === true && <OfficeCard study={true} image={redcase3} />}
              {greencase === true && (
                <OfficeCard study={true} image={greencase3} />
              )}
              {greengrey === true && (
                <OfficeCard study={true} image={greengrey3} />
              )}
              {aboutgal === true && <OfficeCard image={office3} />}
            </SwiperSlide>
            <SwiperSlide>
              {yellowcase === true && (
                <OfficeCard study={true} image={yellow4} />
              )}
              {redcase === true && <OfficeCard study={true} image={redcase4} />}
              {greencase === true && (
                <OfficeCard study={true} image={greencase4} />
              )}
              {greengrey === true && (
                <OfficeCard study={true} image={greengrey4} />
              )}
              {aboutgal === true && <OfficeCard image={office4} />}
            </SwiperSlide>
          </Swiper>
        </div>
        <div className="office-nav">
          <div className="swiper-button-next" onClick={handleNext}></div>

          <div className="swiper-button-prev" onClick={handlePrev}></div>
        </div>
      </div>
    </>
  );
};

export default Office;
