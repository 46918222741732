import React, { useCallback, useRef } from "react";
import rating from "../../images/ratings.png";
import testimonial from "../../images/testimonial.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { TestimonialsCard } from "./TestimonialsCard";
import styles from "./Testimonials.module.css";
const Testimonials = () => {
  const sliderRef = useRef(null);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  return (
    <>
      <section id="web-testimonial" className={`${styles.web_testimonial}`}>
        <div className="container">
          <h2 className="font-weight-700 text-center">Testimonials</h2>
          <span className={`${styles.testimonial_iconz}`}>
            <img alt="" src={testimonial} />
          </span>

          <div className="swiper testimonialSwiper">
            <div className="swiper-wrapper">
              <Swiper spaceBetween={20} loop={true} ref={sliderRef}>
                <SwiperSlide>
                  <TestimonialsCard
                    headingName="Swapynetwork, "
                    textrating="5.0/5.0"
                    details=" iSoft technologies have a strong understanding of current development best practices. Exceptionally skilled with PHP (Laravel), Vue.js, and MERN Stack. Outstanding services, Super professional, Service-oriented, and Top-Notch Communication Skills. I would gladly recommend them for all development projects!"
                    image={rating}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <TestimonialsCard
                    headingName="Ultra Envision Advertising Saudi Arabia,"
                    textrating="5.0/5.0"
                    details="Superb Team, they help me to fix the site on time which saved me in front of my client. If you have the opportunity to work with them, I say go for it! You are guaranteed an enjoyable and productive experience. Jazakhallahu Khairan."
                    image={rating}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <TestimonialsCard
                    headingName="HFConsulting United States,"
                    textrating="5.0/5.0"
                    details="Excellent developers with great deep knowledge of PHP (Laravel) and Vue.js. At ease both in the backend and frontend. I worked with them for 2 years. I've always found iSoft technologies to be loyal, punctual, professional, and commercially minded. Their work is of a very high standard and I highly recommend them."
                    image={rating}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <TestimonialsCard
                    headingName="Marc Fleishman,"
                    textrating="5.0/5.0"
                    details=" Truly amazing services. I gave them a very complex task, and I was very skeptical that anybody from the IT industry could not achieve it quickly. They had very strong solutions, and the software they provided is very solid and bug-free. I totally thank them for this fantastic  I am truly thankful for all their hard work and dedication. I recommend them to anyone in the IT Industry."
                    image={rating}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <TestimonialsCard
                    headingName="Behind The veil United Kingdom,"
                    textrating="4.7/5.0"
                    details="Very impressive and skilled professionals. They are very patient with all the changes I would make. Very creative and reliable; Lovely to work with and would highly recommend their services. My website works fantastically and couldn't have asked for a better service."
                    image={rating}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <TestimonialsCard
                    headingName="Macbay Media Germany"
                    textrating="5.0/5.0"
                    details="Excellent skills, both technically and conversational. Impressive management skills, Attentive to detail, and Consistent in meeting deadlines.!"
                    image={rating}
                  />
                </SwiperSlide>
              </Swiper>
            </div>

            <div className="testimonial-nav">
              <div className="swiper-button-next" onClick={handleNext}></div>
              <div className="swiper-button-prev" onClick={handlePrev}></div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Testimonials;
