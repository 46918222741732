import React from "react";
import Banner from "../../components/Banner/Banner";
import styles from "./DigitalProductDesign.module.css";
import contact_footer from "../../images/contact-footer.png";
import vector2 from "../../images/vector2.png";
import vector4 from "../../images/vector4.png";
import user from "../../images/users.svg";
import blub from "../../images/blub.svg";
import location from "../../images/locations.svg";
import products from "../../images/products.svg";
import datagather from "../../images/datagather.svg";

import bannerservice1 from "../../images/banner-services1.png";
import digital_vector from "../../images/digital-vector.png";
import board from "../../images/board.svg";
import wireframe from "../../images/wireframe.svg";
import vector4_1 from "../../images/vector4-1.png";
import vector2_1 from "../../images/vector2-1.png";
import sprint from "../../images/sprint.svg";
import DigitalProductCard from "../../components/Development/DigitalProductCard";
import GotProject from "../../components/Got_Project/GotProject";
import Footer from "../../components/Footer/Footer";
import { TabTitle } from "../../Utils/GeneralFunction";

const DigitalProductDesign = () => {
  TabTitle("Services | Digital Product Design");
  return (
    <>
      <Banner
        heading="Craft delightful UX for your digital products"
        detail="  Powering full cycle custom development services for past 12 years. 
                            Get partnered with our tech rock stars such as cloud architects, 
                            frontend/backend developers, product designers.
                           "
        btn_detail="Start a project"
        image={contact_footer}
        image_mobile={bannerservice1}
        image_large={bannerservice1}
        digital={true}
      />
      <section className={`${styles.prodesign_section}`}>
        <div className="container">
          <div className="row justify-content-center">
            <div className={`col-lg-10 ${styles.design_text} text-center`}>
              <h2 className="font-weight-700 mb-2">Product Design</h2>
              <h4 className="font-weight-700 text--gradient">
                Design a simple solution for a complex problem
              </h4>
              <h4 className={`font-weight-400 text--web ${styles.txt}`}>
                We’ve mastered building the right product, while building the
                product right.From design workshops all the way to user testing,
                product design encompasses every aspect of how people interact
                with the product and the experience it provides. Using our
                findings from the research phase, we take a human-centered
                design approach to structure an experience that solves the
                problem in the simplest way possible.
              </h4>
              <ul className="design-scrolls">
                <li>
                  <a className="scrollings" href="#empathize-section">
                    <span className="ds-text">Empathize</span>
                    <span className="ds-number">01</span>
                  </a>
                </li>
                <li>
                  <a className="scrollings" href="#define-section">
                    <span className="ds-text">Define</span>
                    <span className="ds-number">02</span>
                  </a>
                </li>
                <li>
                  <a className="scrollings" href="#idea-section">
                    <span className="ds-text">Ideate</span>
                    <span className="ds-number">03</span>
                  </a>
                </li>
                <li>
                  <a className="scrollings" href="#prototype-section">
                    <span className="ds-text">Prototype</span>
                    <span className="ds-number">04</span>
                  </a>
                </li>
                <li>
                  <a className="scrollings" href="#test-section">
                    <span className="ds-text">Test</span>
                    <span className="ds-number">05</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section id="empathize-section" className={`${styles.empathize_section}`}>
        <div className="container">
          <div className="row">
            <DigitalProductCard
              show={true}
              no="01."
              title="Empathize"
              heading="   Fine Tuning the Experience"
              detail=" UX design outlines what the experience is actually like for
                people using and interacting with a product. We use several
                essential tools to fine tune and perfect the experience.
                Beginning with experience mapping and defining user
                requirements, we determine what the user journey looks like and
                start to add some structure to it. From there, we move on to
                user flows, information architecture, and wireframes to hone in
                on the details."
            />

            <div className="col-lg-6">
              <div className="digital-vectors">
                <span className="vector1">&nbsp;</span>
                <span className="vector2">
                  <img alt="" src={vector2} />
                </span>
                <span className="vector3">&nbsp;</span>
                <span className="vector4">
                  <img alt="" src={vector4} />
                </span>
                <div className="text-elipse elipse-gray">
                  <span className="elipse-icon">
                    <img alt="" src={user} />
                  </span>
                  <p className="text--light font-weight-600">
                    <span>User</span>
                    <span>interviews</span>
                  </p>
                </div>
                <div className="text-elipse elipse-graylg">
                  <span className="elipse-icon">
                    <img alt="" src={location} />
                  </span>
                  <p className="text--light font-weight-600">
                    <span>Empathy & customer</span>
                    <span>journey maps</span>
                  </p>
                </div>
                <div className="text-elipse elipse-gradient bg--gradient">
                  <span className="elipse-icon">
                    <img alt="" src={blub} />
                  </span>
                  <p className="text-white font-weight-600">
                    <span>Body &</span>
                    <span>brainstorming</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="define-section" className="define-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-oder-last">
              <div className="digital-vectors small">
                <span className="bg-vector">
                  <img alt="" src={digital_vector} />
                </span>
                <span className="vector1">&nbsp;</span>
                <span className="vector2">
                  <img alt="" src={vector2} />
                </span>
                <span className="vector3">&nbsp;</span>
                <span className="vector4">
                  <img alt="" src={vector4} />
                </span>
                <div className="text-elipse elipse-graylg">
                  <span className="elipse-icon">
                    <img alt="" src={datagather} />
                  </span>
                  <p className="text--light font-weight-600">
                    <span>Data gathering</span>
                  </p>
                </div>
                <div className="text-elipse elipse-gradient bg--gradient">
                  <span className="elipse-icon">
                    <img alt="" src={products} />
                  </span>
                  <p className="text-white font-weight-600">
                    <span>Product</span>
                    <span>workshop</span>
                  </p>
                </div>
              </div>
            </div>
            <DigitalProductCard
              no="02."
              title="Define"
              heading=" Testing the Experience In Its Rawest Form"
              detail="While user testing can be done at any stage of the product design and development process, we prefer to focus on it while building wireframes for two main reasons. One is speed. We’re able to adapt to change and iterate much more quickly if something isn’t working in the wireframe than we would if we were dealing with a full display layer. The second reason for testing during the wireframe stage is that we’re better able to test the experience itself rather than the users’ perception of the experience based on the visual layer."
              white={true}
            />
          </div>
        </div>
      </section>
      <section id="idea-section" className="idea-section">
        <div className="container">
          <div className="row">
            <DigitalProductCard
              no="03."
              title="Ideate"
              heading="   Creating a Cohesive Design System"
              detail="  UI design involves everything in the display layer of a product.
                It’s what users actually see and interact with. Colors, fonts,
                grid structure, UX writing. We design all of these elements into
                one cohesive design system. And we don’t just design things
                because they look nice. Every design decision we make is
                evidence-based and backed by thorough research to ensure that
                the UI is successful."
            />

            <div className="col-lg-6">
              <div className="digital-vectors right-aligned">
                <span className="bg-vector">
                  <img alt="" src={digital_vector} />
                </span>
                <span className="vector1">&nbsp;</span>
                <span className="vector2">
                  <img alt="" src={vector2} />
                </span>
                <span className="vector3">&nbsp;</span>
                <span className="vector4">
                  <img alt="" src={vector4} />
                </span>
                <div className="text-elipse elipse-graylg">
                  <span className="elipse-icon">
                    <img alt="" src={board} />
                  </span>
                  <p className="text--light font-weight-600">
                    <span>Moodboarding</span>
                  </p>
                </div>
                <div className="text-elipse elipse-gradient bg--gradient">
                  <span className="elipse-icon">
                    <img alt="" src={wireframe} />
                  </span>
                  <p className="text-white font-weight-600">
                    <span>Wireframing</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        id="prototype-section"
        className="prototype-section bg--gradient uiux-dev"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-oder-last">
              <div className="digital-vectors xl">
                <span className="bg-vector">
                  <img alt="" src={digital_vector} />
                </span>
                <span className="vector1">&nbsp;</span>
                <span className="vector2">
                  <img alt="" src={vector2_1} />
                </span>
                <span className="vector3">&nbsp;</span>
                <span className="vector4">
                  <img alt="" src={vector4_1} />
                </span>
                <div className="text-elipse elipse-graylg">
                  <span className="elipse-icon">
                    <img alt="" src={sprint} />
                  </span>
                  <p className="text--light font-weight-600">
                    <span>Design sprints</span>
                  </p>
                </div>
              </div>
            </div>
            <DigitalProductCard
              no="04."
              title="Prototype"
              heading="See Your Product in Action"
              detail="   A prototype of your product is an extremely useful tool in a
                number of ways. It can serve as a proof of concept that your
                idea is viable and worth moving forward. Prototypes also assist
                in showing investors what your product can do rather than simply
                telling them. Our prototypes take several different forms based
                on the needs of the client. In some cases, we’re building a
                clickable front-end only prototype with placeholder data to give
                an idea of the look and feel of the product. Other times, we
                work with the development team to build out a more robust
                prototype to really show what the product can do."
              white={true}
            />
          </div>
        </div>
      </section>
      <section id="test-section" className="test-section">
        <div className="container test-wrapper">
          <div className="row">
            <DigitalProductCard
              no="05."
              title="Test"
              heading=" Putting the Micro in Micro-Interactions"
              detail="  As part of the UI design system, we also pay thoughtful
                attention to how the various elements interact with one another.
                Small, micro-interactions elevate specific features within the
                product’s overall experience. We design them with intention and
                are considerate to keep them micro so as to not distract the
                user from the overall experience. Subtlety and minimalism is the
                key to successful micro-interactions."
            />

            <div className="col-lg-6">
              <div className="test-elipse">
                <h2 className="font-weight-700">Repeat</h2>
                <h4 className="font-weight-400 text--web">
                  After prototyping comes user testing, but it’s important to
                  note that this is rarely the end of the Design Thinking
                  process.
                </h4>
              </div>
            </div>
          </div>
        </div>
      </section>
      <GotProject every={true} />
      <Footer contacting={true} />
    </>
  );
};

export default DigitalProductDesign;
