import React from "react";
import styles from "./AboutIsoft.module.css";
import vector from "../../images/vectors.png";

const Aboutisoft = ({
  heading,
  detail,
  custom,
  custom_head,
  agile,
  mob,
  detail_next,
  cent,
  extra,
  arch,
  everyimage,
  sub_heading,
}) => {
  return (
    <>
      <div className={cent === true ? `col-lg-11  text-center` : `col-lg-11`}>
        <h2
          className={
            custom_head === true ? `${styles.space}` : `${styles.head_text}`
          }
        >
          {heading}
        </h2>
        {extra === true && (
          <h4 className="font-weight-700 text--gradient mt-1">{sub_heading}</h4>
        )}
      </div>
      <div
        className={
          mob === true ? `col-lg-12 ${styles.mob_margin}` : `col-lg-12`
        }
      >
        <div className={`${styles.work_thumb}`}>
          <img alt="" src={everyimage} />
          <span className={`${styles.vectors}`}>
            <img alt="" src={vector} />
          </span>
        </div>
      </div>
      <div className="col-lg-11">
        {agile === true && (
          <h4
            className={
              arch === true
                ? `font-weight-400 text--web ${styles.commonline_arch}`
                : `font-weight-400 text--web ${styles.commonline}`
            }
          >
            {detail}
          </h4>
        )}
        {custom === true && (
          <h4 className={`font-weight-400 text--web ${styles.commonline} mt-4`}>
            {detail_next}
          </h4>
        )}
      </div>
    </>
  );
};

export default Aboutisoft;
