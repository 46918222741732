import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination } from "swiper";
import ContactusCard from "../../components/Contactus/ContactusCard";
import contact1 from "../../images/contact1.svg";
import rating from "../../images/ratings.png";
import Footer from "../../components/Footer/Footer";
import { TabTitle } from "../../Utils/GeneralFunction";
import emailjs from "@emailjs/browser";

import { useForm } from "react-hook-form";
import sendimage from "../../images/check.png";
import errorimage from "../../images/cross.png";
import ReCAPTCHA from "react-google-recaptcha";

import { emailconfig } from "../../config/emailconfig";
const Contact = (event) => {
  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };
  TabTitle("iSoft Technologies - Contact Us");
  SwiperCore.use([Pagination]);
  const form = useRef();
  const recaptchaRef = React.createRef();
  const [statusMessage, setStatusMessage] = useState("");
  const [verified, setVerified] = useState(false);
  const [isCaptchaMsg, setIsCaptchaMsg] = useState(false);
  const sendEmail = () => {
    emailjs
      .sendForm(
        "service_g23ejtd",
        "template_4fc3jn6",
        form.current,
        "wcYZLEqqRwdVFLlcY"
      )
      .then(
        () => {
          setStatusMessage("yes");
          setTimeout(() => {
            setStatusMessage(null);
          }, 10000);
        },
        () => {
          setStatusMessage("no");
          setTimeout(() => {
            setStatusMessage(null);
          }, 10000);
        }
      );
  };
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const finalsubmit = (datas, e) => {
    setIsCaptchaMsg(true);
    if (verified) {
      sendEmail(datas);
      setIsCaptchaMsg(false);
      setVerified(false);
      reset();
    }
  };
  const onChange = (value) => {
    setVerified(true);
  };
  const resetmsg = () => {
    // setIsCaptchaMsg(false);
    setStatusMessage(null);
  };
  const emailpath = emailconfig();
  return (
    <>
      <section className="contact-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 contact-left">
              <h2 className="font-weight-700 text-white">Got a Project?</h2>
              <h4 className="font-weight-400 text-white mt-3">
                Custom Solutions for companies and individuals. Fill in our
                short form. It will only take a minute.
              </h4>
              <form
                onSubmit={handleSubmit(finalsubmit)}
                ref={form}
                className="form-contact"
              >
                <div className="form-group mb-4">
                  <label htmlFor="conName" className="form-label">
                    Full Name*
                  </label>
                  <input
                    type="text"
                    name="user_name"
                    onClickCapture={resetmsg}
                    className={`form-control ${
                      errors.user_name ? `form-control-required` : ``
                    }`}
                    id="conName"
                    {...register("user_name", {
                      required: true,
                    })}
                  />
                  {errors.user_name && (
                    <span className="validate-msg">Name is required</span>
                  )}
                </div>
                <div className="form-group mb-4">
                  <label htmlFor="conBudget" className="form-label">
                    Interested In*
                  </label>

                  <select
                    name="user_interested"
                    className={`form-control ${
                      errors.user_interested ? `form-control-required` : ``
                    }`}
                    onClickCapture={resetmsg}
                    {...register("user_interested", {
                      required: "Select your interest",
                    })}
                  >
                    <option value="">Select</option>
                    <option>Project discussion</option>
                    <option>Hiring remote resources</option>
                    <option>Scale up a team</option>
                    <option>Career opportunity</option>
                    <option>Other</option>
                  </select>
                  {errors.user_interested && (
                    <span className="validate-msg">
                      {errors.user_interested.message}
                    </span>
                  )}
                </div>
                <div className="form-group mb-4">
                  <label htmlFor="conMail" className="form-label">
                    Business e-mail*
                  </label>
                  <input
                    name="user_email"
                    type="email"
                    className={`form-control ${
                      errors.user_email ? `form-control-required` : ``
                    }`}
                    onClickCapture={resetmsg}
                    {...register("user_email", {
                      required: true,
                      pattern:
                        /^(([^<>()\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    })}
                  />
                  {errors.user_email && (
                    <span className="validate-msg">
                      Please check your email address
                    </span>
                  )}
                </div>
                <div className="form-group mb-4">
                  <label htmlFor="conBudget" className="form-label">
                    What budget do you plan to allocate to your project?
                  </label>
                  <select
                    name="user_budget"
                    className={`form-control ${
                      errors.user_budget ? `form-control-required` : ``
                    }`}
                    onClickCapture={resetmsg}
                    {...register("user_budget", {
                      required: "Select your budget",
                    })}
                  >
                    <option value="">-</option>
                    <option>100,000</option>
                    <option>150,000</option>
                  </select>
                  {errors.user_budget && (
                    <span className="validate-msg">
                      {errors.user_budget.message}
                    </span>
                  )}
                </div>
                <div className="form-group mb-4">
                  <label htmlFor="csComent" className="form-label">
                    What are your ideas and needs regarding your project?
                  </label>
                  <textarea
                    name="message"
                    className={`form-control ${
                      errors.message ? `form-control-required` : ``
                    }`}
                    onClickCapture={resetmsg}
                    {...register("message", {
                      required: true,

                      maxLength: 200,
                    })}
                  ></textarea>
                  {errors.message && (
                    <span className="validate-msg">
                      Message shouldn't exceed 200 characters
                    </span>
                  )}
                </div>
                <div className="flex-holder pt-3 mb-4">
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    name="captcha"
                    sitekey="6LewqBMiAAAAAFgCK7NBtjvL2mNxg_YJVrnR--kw"
                    onChange={onChange}
                    onExpired={() => {
                      recaptchaRef.current.reset();
                    }}
                  />

                  <p>
                    or contact us directly at
                    <a
                      href="https://mail.google.com/mail/u/0/#inbox?compose=CllgCHrhThSlJztHRhRfdkXvpvTzLdLqmqSmWXqGNqJMSWsTrdwBcMfnwSrZltQxpQzHFmXKxBV"
                      onClick={() =>
                        openInNewTab(
                          "https://mail.google.com/mail/u/0/#inbox?compose=CllgCHrhThSlJztHRhRfdkXvpvTzLdLqmqSmWXqGNqJMSWsTrdwBcMfnwSrZltQxpQzHFmXKxBV"
                        )
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span>{emailpath}</span>
                    </a>
                  </p>
                </div>
                <div className="succes-mail-msg">
                  {statusMessage === "yes" && (
                    <div>
                      <img alt="" className="message-image" src={sendimage} />
                      <span>Your mail has been submitted!</span>
                    </div>
                  )}
                  {statusMessage === "no" && (
                    <div>
                      <img alt="" className="message-image" src={errorimage} />
                      <span>Your mail has not sent.</span>
                    </div>
                  )}
                  {verified === false && isCaptchaMsg === true && (
                    <div className="captcha">
                      <span>Please verify captcha </span>
                    </div>
                  )}
                </div>

                <button
                  type="submit"
                  value="Send"
                  className="btn--gradient btn-contact"
                  // disabled={!verified && !isCaptchaMsg}
                >
                  Send us a message
                </button>
              </form>
            </div>
            <div className="col-lg-5 contact-right">
              <div className="vector-contact">
                <img alt="" src={contact1} />
              </div>
              <div className="swiper contactSlider">
                <div className="swiper-wrapper">
                  <Swiper
                    slidesPerView={1}
                    spaceBetween={20}
                    loop={true}
                    pagination={{
                      el: ".swiper-pagination",
                      clickable: true,
                    }}
                  >
                    <SwiperSlide>
                      <ContactusCard
                        image={rating}
                        personname="Sami Pippuri "
                        detail="    We thank iSoft Technologies for the wonderful job in
                          helping us develop our program. Everyone was
                          professional, excellent and hard working. Thanks to
                          them, we were able to achieve our goal on time, and we
                          look forward to continue working with them in the
                          future."
                      />
                    </SwiperSlide>
                  </Swiper>
                </div>
                <div className="swiper-pagination"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer footertop={true} />
    </>
  );
};

export default Contact;
