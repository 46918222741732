import React from "react";
import styles from "./industrylist.module.css";
const IndustryList = ({ detail, textwhite, arrow }) => {
  return (
    <>
      <li className={arrow === true ? `${styles.white}` : `${styles.left}`}>
        <h4
          className={
            textwhite === true
              ? `font-weight-400 text--web text-white ${styles.left_marg}`
              : `font-weight-400 text--web ${styles.left_marg}`
          }
        >
          {detail}
        </h4>
      </li>
    </>
  );
};

export default IndustryList;
