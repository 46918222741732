import React from "react";
import case_vector from "../../images/casestudy-vector.png";

import solution_png from "../../images/solution.png";
import { CaseStudyCard } from "../../components/CaseStudy/CaseStudyCard";

import { TechStackCard } from "../../components/CaseStudy/TechStackCard";
import feature_big from "../../images/feature-big.jpg";
import plane from "../../images/plane.png";

import styles from "./casestudypage.module.css";

import { OfficeDetail } from "../../components/Office/OfficeDetail";
import Office from "../../components/Office/Office";
import GotProject from "../../components/Got_Project/GotProject";
import Footer from "../../components/Footer/Footer";
import { TabTitle } from "../../Utils/GeneralFunction";
import CaseStudyQuote from "../../components/CaseStudy/CaseStudyQuote";
import CaseStudySummary from "../../components/CaseStudy/CaseStudySummary";
import CaseStudySolution from "../../components/CaseStudy/CaseStudySolution";
import CaseStudyFeature from "../../components/CaseStudy/CaseStudyFeature";
import CaseStudyBanner from "../../components/CaseStudy/CaseStudyBanner";
const Case_Study = () => {
  TabTitle("Our Work | Universal Visas");
  return (
    <>
      <div className="redcolor">
        <CaseStudyBanner
          color="red"
          title="  Universal Visas is a UK based visa & passport services
                    providing company."
          content=" It offers visa & passport services to the people of diverse
                    nationalities who are willing to travel to any country
                    across the globe."
          customer="Universal Visas"
          customer_site=" universalvisas.com"
          linking="https://universalvisas.com/"
          images={case_vector}
        />

        <section className={` ${styles.casestudy_columns} position-relative`}>
          <div className={`${styles.box_design} container`}>
            <div className="row">
              <div className={`col-lg-6 ${styles.casestudy_intro}`}>
                <CaseStudyCard
                  heading="Business Need"
                  details=" As a product designing company, we designers will put life into your raw ideas. Hi-tech design will be offered to you according to your own needs. From the initial stages like business analysis and discovery stage to the final one i.e. user experience, we provide services as a team that makes your product usage transparent."
                />
              </div>
              <div className={`col-lg-6 ${styles.casestudy_intro}`}>
                <CaseStudyCard
                  heading="Results"
                  details="  Be ready to see an existing example of your raw idea. A result according to your demands will be there in front of your eyes with amazing design and magnificent output. It will make you believe how much attention has been given to detailing and designing at each stage. The user experience you will have will make you contented and satisfied with iSoft Technologies’ services."
                />
              </div>
            </div>
          </div>
        </section>
        <CaseStudyQuote color="red" />

        <section className={`${styles.casestudy_summary}`}>
          <div className="container">
            <div className="row">
              <CaseStudySummary
                summary={true}
                color="red"
                heading="Summary"
                detail1=" Universal Visas is a UK based visa & passport services
                  providing company. It offers visa & passport services to the
                  people of diverse nationalities who are willing to travel to
                  any country across the globe. The company offers efficient
                  visa and passport information and services to both private and
                  business applicants either they are travelling internationally
                  for business or tourist purpose."
                detail2="  The simply designed website and mobile application by iSoft
                  Technologies provided applicants visa application process
                  quicker and easier. Along with visa application forms, website
                  and mobile application contains most recent and accurate
                  information pertaining to each country’s visa application
                  criteria."
                detail3="  Moreover, iSoft Technologies designed and developed website
                  and mobile application in such a way that customers can track
                  and order their visa online."
              />

              <CaseStudySummary color="red" location={true} />
              <CaseStudySummary
                challenge={true}
                color="red"
                content=<div className={`${styles.redcolor} `}>
                  <h3 className={`${styles.highlighted_txt}`}>
                    Universal visa needed a <span>web application</span>
                    that would provide information regarding
                    <span>
                      {" "}
                      multiple countries with respect to multiple visas
                    </span>{" "}
                    Moreover, the main challenge was to provide
                    <span> fee management portal </span>
                    that provides information regarding fee for various
                    countries & different clients i.e. business or tourist etc.
                    While designing the website, the challenge was to design
                    website in such a way will make visa process simple.
                    <span>
                      Website includes latest and up-to-date information
                    </span>
                    related to the visa application requirements of each country
                    together with <span> visa application forms.</span>
                  </h3>
                </div>
              />
            </div>
          </div>
        </section>

        <CaseStudySolution
          color="red"
          detail1="iSoft Technology identified a multi-prolong solution by dividing the software into two components i.e. website interface and mobile application."
          detail2="The solutions provided by iSoft technologies generated a way to organize and store data, once user put information into the user profile, it can be used & retrieved for other countries and for life long visas. iSoft technologies also provide the facility to efficiently edit the information."
          image={solution_png}
        />
        <CaseStudyFeature
          plane={true}
          color="red"
          imagemain={feature_big}
          imageplane={plane}
          detail1="Universal Visas website & mobile application comprises with the features of visa and passport management services. It has the feature of CMS. It provides the latest and up-to-date information related to the visa application requirements of each country together with visa application form."
          detail2="It also has the feature to order visa online and can be traced and tracked online. Universal visas provides with the facility of submitting documents, getting invoice, embassy closure dates, services directory and search for what user require for visa. Moreover, upon receipt of the application, Universal Visas will send an email confirming that supporting materials have arrived and have been submitted to the embassy. The online tracking tool allows the applicant to view real time tracking of their application from the website."
        />
        <section className={`${styles.our_techs}`}>
          <div className="container">
            <h2 className="font-weight-700">Tech Stack</h2>
            <div className="row">
              <TechStackCard
                color="red"
                title="PHP"
                detail="PHP is utilized by 78.9% of the websites all over the world. This open-source scripting language "
              />
              <TechStackCard
                color="red"
                title="Laravel"
                detail="Laravel is one of the most popular PHP backend frameworks as its powerful built-in system makes it simpler to 
 "
              />
              <TechStackCard
                color="red"
                title="Vue.js"
                detail="Vue is a JavaScript framework for building user interfaces. It builds on top of standard HTML, CSS and Javascript."
              />
              <TechStackCard
                color="red"
                title="MySQL"
                detail="The world’s most popular database, MySQL greatly benefits businesses because of its "
              />
              <TechStackCard
                color="red"
                title="Saas"
                detail="SASS is a CSS (cascading style sheet) pre-processor that assists with lessening redundancies in CSS."
              />
              <TechStackCard
                color="red"
                title="Oauth 2.0"
                detail="OAuth 2.0 is the industry-standard protocol for authorization. it focuses on client developer simplicity while "
              />
            </div>
          </div>
        </section>
        <section className="our-office margin-bottom">
          <div className="container">
            <div className="office-container text-white text-center">
              <OfficeDetail
                title="Gallery of Project"
                details="We're incredibly lucky to workwith partners from a wide spectrum of industries who shre our enthusiasm and commitment towards delivering results."
              />
            </div>
          </div>
          <div className="office-swiper">
            <Office study={true} redcase={true} />
          </div>
        </section>
      </div>
      <GotProject every={true} />
      <Footer contacting={true} />
    </>
  );
};

export default Case_Study;
