import React from "react";
import { Link } from "react-router-dom";
import styles from "./CaseStudyBanner.module.css";

import arrow from "../../images/arrow.png";
const CaseStudyBanner = ({
  title,
  content,
  customer,
  customer_site,
  images,
  disarrow,
  linking,
  color,
}) => {
  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };
  return (
    <>
      <section
        className={
          color === "red"
            ? `redcolor casestudy-banner `
            : color === "aquacolor"
            ? `aquacolor casestudy-banner`
            : color === "yellowcolor"
            ? `yellowcolor casestudy-banner`
            : color === "greencolor"
            ? `greencolor casestudy-banner`
            : ``
        }
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-lg-7">
              <div className={`${styles.pagecontent}`}>
                <h1 className="text-capitalize">{title}</h1>
                <h4 className={`font-weight-400 ${styles.text_pagecontent}`}>
                  {content}
                </h4>
                <div className={`${styles.casestudy_customer}`}>
                  <h4 className="font-weight-700">CUSTOMER:</h4>
                  <h2 className="font-weight-700">{customer}</h2>
                  <Link
                    to={linking}
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => openInNewTab(linking)}
                  >
                    {customer_site}
                    {disarrow === true ? "" : <img alt="" src={arrow} />}
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-5">
              <div className={`${styles.casestudy_vector}`}>
                <img alt="" src={images} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CaseStudyBanner;
