import React from "react";
import case4 from "../../images/case-study4.png";

import solution4 from "../../images/solutions4.png";
import casestudy4 from "../../images/casestudy4-lg.jpg";
import { CaseStudyCard } from "../../components/CaseStudy/CaseStudyCard";

import { TechStackCard } from "../../components/CaseStudy/TechStackCard";
import styles from "./CaseStudy_4.module.css";
import { OfficeDetail } from "../../components/Office/OfficeDetail";
import Office from "../../components/Office/Office";
import GotProject from "../../components/Got_Project/GotProject";
import Footer from "../../components/Footer/Footer";
import { TabTitle } from "../../Utils/GeneralFunction";

import CaseStudyBanner from "../../components/CaseStudy/CaseStudyBanner";
import CaseStudyQuote from "../../components/CaseStudy/CaseStudyQuote";
import CaseStudySummary from "../../components/CaseStudy/CaseStudySummary";
import CaseStudySolution from "../../components/CaseStudy/CaseStudySolution";
import CaseStudyFeature from "../../components/CaseStudy/CaseStudyFeature";
const CaseStudy4 = () => {
  TabTitle("Our Work | Phundee");

  return (
    <>
      <div className="greencolor">
        <CaseStudyBanner
          images={case4}
          color="greencolor"
          title="Phundee.com is a crowd-funding platform 
used by people for collecting funds for 
film-industry related projects. "
          content="It transforms Yabee.me website into a marketplace. Through a dedicated dashboard, vendors can create, update and track their products and orders. "
          customer="Phundee"
          disarrow={true}
          // customer_site="Phundee.com"
          linking="https://yabee.me/"
        />

        <section className={` ${styles.casestudy_columns} position-relative`}>
          <div className={`${styles.box_design} container`}>
            <div className="row">
              <div className={`col-lg-6 ${styles.casestudy_intro}`}>
                <CaseStudyCard
                  heading="Business Need"
                  details="iSoft Technologies created the website according to its aim and added slots for the campaigners to put their data in it and then run the campaigns. Funders were also facilitated in the form of rewards and their money was kept safe and given to the deserving ones. The website was developed in such a way that it was doing justice with the company’s requirements regarding both the campaigners and the funders."
                />
              </div>
              <div className={`col-lg-6 ${styles.casestudy_intro}`}>
                <CaseStudyCard
                  heading="Result"
                  details="The website was easy to use for both the parties and fulfilling the data needs of the company as well. Its performance was remarkable and the escrow system worked perfectly. A very well-organized website was delivered to the client which was going really well with the business needs of the company."
                />
              </div>
            </div>
          </div>
        </section>
        <CaseStudyQuote color="greencolor" />

        <section className={`${styles.casestudy_summary}`}>
          <div className="container">
            <div className="row">
              <CaseStudySummary
                color="greencolor"
                summary={true}
                heading="Summary"
                detail1="Phundee.com is a crowd-funding platform through which people who work on different film industry-related projects obtain funds from other individuals from all over the world. Those who require funds arrange an online campaign through signing up on this platform, and they provide massive data regarding their particular project for verification before their campaign gets live. Once it is live, according to the data, a time span is allotted to it during which the funds can be collected. Moreover, a dashboard is designed for the campaigners where they upload the progress of their project so that those who have funded them stay aware of their tasks and know that the money they have given is being used at the right place.


"
                detail2="The funders are also given a reward as a token of appreciation e.g. if the funds are being collected for making a music album, a free album is gifted to the funders for helping in the initiative. Also, the facility of refunding is also available on the platform – if the campaigners fail to collect the required amount during the allotted time span, the funders are refunded because if the required amount isn’t fulfilled, then the collected one is also of no use. In order to make it easier for the funders to know the details of the project, FAQs, updates and comments slots are made as well."
              />

              <CaseStudySummary color="greencolor" location={true} />
              <CaseStudySummary
                challenge={true}
                color="greencolor"
                content=<div className={`${styles.greencolor} `}>
                  <h3 className={`${styles.highlighted_txt}`}>
                    A complete process of legislation takes place through an
                    escrow system so that if there is any fraud going on, then
                    the <span>amount is refunded to the funders.</span> iSoft
                    Technologies had to make this process smoother for both the
                    campaigners and funders so that it doesn’t take much time
                    and <span>works properly. </span> On the other hand,{" "}
                    <span> since excessive information</span> of the campaigners
                    was the demand of the client, many slots had to be created
                    in the form of several forms so that complete information
                    regarding the project is obtained.
                  </h3>
                </div>
              />
            </div>
          </div>
        </section>

        <CaseStudySolution
          image={solution4}
          color="greencolor"
          detail1="iSoft Technologies provided superb user experience to both the campaigners and funders because the forms were so well-organized and maintained that it was really easy for the campaigners to fill them up, basically different sections were made so that they have an idea of what information they have to provide. Once one section is filled, they can simply click “next”, and another section immediately opens up. Additionally, the dashboard had all the options where the campaigners could mention the different aspects of the progress of their project. "
        />
        <CaseStudyFeature
          color="greencolor"
          plane={true}
          imagemain={casestudy4}
          detail1="Phundee.com is a crowd-funding platform developed by iSoft Technologies. It has the option of signing up for the campaigners, and in order to arrange a campaign, they fill the forms and afterwards their verification is done by the website. The campaigners then run a campaign for collecting funds and use it for their cause. The dashboard of the website helps them to upload the updates of their project so that the funders stay familiar with what is happening with their money. On the other hand, rewards are offered to funders for their support and they are refunded if the desired amount isn’t collected by the campaigners within the given time period. Funders also feel satisfied after giving funds because the escrow process gives them a guarantee that it will verify if the project is genuine or not, and in case if it is fake, the funders will be refunded. FAQs, comments section and update slots everything is present on the platform for facilitating the funders. "
        />
        <section className={`${styles.our_techs}`}>
          <div className="container">
            <h2 className="font-weight-700">Tech Stack</h2>
            <div className="row">
              <TechStackCard
                color="greencolor"
                title="Laravel"
                detail="Laravel is one of the most popular PHP backend frameworks as its powerful built-in system makes it simpler to "
              />
              <TechStackCard
                color="greencolor"
                title="Vue.js"
                detail="Vue is a JavaScript framework for building user interfaces. It builds on top of standard HTML, CSS and Javascript."
              />
              <TechStackCard
                color="greencolor"
                title="React.js"
                detail="React.js is the most used web framework among software developers all over the world as it is"
              />
            </div>
          </div>
        </section>
        <section className="our-office margin-bottom">
          <div className="container">
            <div className="office-container text-white text-center">
              <OfficeDetail
                title="Gallery of Project"
                details="We're incredibly lucky to workwith partners from a wide spectrum of industries who shre our enthusiasm and commitment towards delivering results."
              />
            </div>
          </div>
          <div className="office-swiper">
            {" "}
            <Office study={true} greengrey={true} />
          </div>
        </section>
      </div>
      <GotProject every={true} />
      <Footer contacting={true} />
    </>
  );
};

export default CaseStudy4;
