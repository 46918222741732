import React from "react";

export const CaseStudyFeatureCard = ({ title, titlestrong, team }) => {
  return (
    <>
      <h4>
        <strong>{titlestrong}</strong>
        {title}

        {team === true && (
          <>
            <span className="d-block mt-2">Team augmentation</span>
            <span className="d-block mt-2">QA automation</span>
          </>
        )}
      </h4>
    </>
  );
};
