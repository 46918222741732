import React from "react";
import Tabs from "../../components/Tabs/Tabs";
import experty1 from "../../images/experty1.png";
import experty2 from "../../images/experty2.png";
import experty7 from "../../images/experty7.png";
import php from "../../images/php.png";
import dotnet from "../../images/dotnet.png";
import sql from "../../images/mysql.png";
import experty3 from "../../images/experty3.png";
import experty10 from "../../images/experty10.png";
import experty11 from "../../images/experty11.png";
import experty12 from "../../images/experty12.png";
import experty13 from "../../images/experty13.png";
import experty14 from "../../images/experty14.png";
import experty15 from "../../images/experty15.png";
import experty16 from "../../images/experty16.png";
import styles from "./TechCard.module.css";
const TechStackCard = ({ web }) => {
  return (
    <>
      <section className={`${styles.taber_section} text-center`}>
        {web === true ? (
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h2 className="font-weight-700 mb-4">Tech stack</h2>
                <div className="tech-tabs">
                  <ul
                    className="nav nav-tabs justify-content-center"
                    id="myTab"
                    role="tablist"
                  >
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link active"
                        id="One-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#One-tab-pane"
                        type="button"
                        role="tab"
                        aria-controls="One-tab-pane"
                        aria-selected="true"
                      >
                        <div className={`tech-thumb ${styles.tech_space}`}>
                          <img alt="" src={experty1} />
                        </div>
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="Two-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#Two-tab-pane"
                        type="button"
                        role="tab"
                        aria-controls="Two-tab-pane"
                        aria-selected="false"
                      >
                        <div className={`tech-thumb ${styles.tech_space}`}>
                          <img alt="" src={experty2} />
                        </div>
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="Three-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#Three-tab-pane"
                        type="button"
                        role="tab"
                        aria-controls="Three-tab-pane"
                        aria-selected="false"
                      >
                        <div className={`tech-thumb ${styles.tech_space}`}>
                          <img alt="" src={experty7} />
                        </div>
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="Four-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#Four-tab-pane"
                        type="button"
                        role="tab"
                        aria-controls="Four-tab-pane"
                        aria-selected="false"
                      >
                        <div className={`tech-thumb ${styles.tech_space}`}>
                          <img alt="" src={php} />
                        </div>
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="Five-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#Five-tab-pane"
                        type="button"
                        role="tab"
                        aria-controls="Five-tab-pane"
                        aria-selected="false"
                      >
                        <div className={`tech-thumb ${styles.tech_space}`}>
                          <img alt="" src={dotnet} />
                        </div>
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="Six-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#Six-tab-pane"
                        type="button"
                        role="tab"
                        aria-controls="Six-tab-pane"
                        aria-selected="false"
                      >
                        <div className={`tech-thumb ${styles.tech_space}`}>
                          <img alt="" src={experty10} />
                        </div>
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="Sev-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#Sev-tab-pane"
                        type="button"
                        role="tab"
                        aria-controls="Sev-tab-pane"
                        aria-selected="false"
                      >
                        <div className={`tech-thumb ${styles.tech_space}`}>
                          <img alt="" src={sql} />
                        </div>
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="Eight-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#Eight-tab-pane"
                        type="button"
                        role="tab"
                        aria-controls="Eight-tab-pane"
                        aria-selected="false"
                      >
                        <div className={`tech-thumb ${styles.tech_space}`}>
                          <img alt="" src={experty3} />
                        </div>
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="Nine-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#Nine-tab-pane"
                        type="button"
                        role="tab"
                        aria-controls="Nine-tab-pane"
                        aria-selected="false"
                      >
                        <div className={`tech-thumb ${styles.tech_space}`}>
                          <img alt="" src={experty11} />
                        </div>
                      </button>
                    </li>
                  </ul>
                  <div className="tab-content" id="myTabContent">
                    <div
                      className="tab-pane fade show active"
                      id="One-tab-pane"
                      role="tabpanel"
                      aria-labelledby="One-tab"
                      tabIndex="0"
                    >
                      <Tabs
                        heading="React.js"
                        detail="React.js is the most used web framework among software developers all over the world as it is very intuitive to work with and makes it easier to interact with the layout of any UI. Fast and quality assured applications can be developed with React technology and the fusion of JavaScript and HTML syntax in it automatically simplifies the whole procedure of code writing of the project, therefore, we utilize this awesome technology for creating E-Commerce Apps, Messaging Apps, Tracking Apps and what not. We also deliver solutions in React with powerful user interfaces that provide strong functionality and tremendous visuals for catering the needs of a business."
                        web={true}
                      />
                    </div>
                    <div
                      className="tab-pane fade"
                      id="Two-tab-pane"
                      role="tabpanel"
                      aria-labelledby="Two-tab"
                      tabIndex="0"
                    >
                      <Tabs
                        heading="Angular.js"
                        detail="Angular.js is another highly famous web development framework that offers fast responsiveness along with rich user experiences. This frontend framework’s Injectors, Components and Directives are a big plus for the product to be successful. Angular’s compatibility with numerous back-end programming languages makes it worth using. Our developers use both of its bundles (tools and resources) for developing modern web browsers which makes the app more productive.
"
                        web={true}
                      />
                    </div>
                    <div
                      className="tab-pane fade"
                      id="Three-tab-pane"
                      role="tabpanel"
                      aria-labelledby="Three-tab"
                      tabIndex="0"
                    >
                      <Tabs
                        heading="Node.js"
                        detail="Almost 98% of the IT professionals of the world trust Node.js development because it offers easy scalability and improves the response time of the application. Not only this, it is a very cost-effective technology which helps in building cross-platform applications having excellent performance. Node has the ability to be combined with many other advanced technologies like React.js, Angular.js and Vue.js etc. which makes it a one of its kind technology. We use Node.js for building modern applications like Chat App, Basic Users System, User Login System, Personalized Websites and much more."
                        web={true}
                      />
                    </div>
                    <div
                      className="tab-pane fade"
                      id="Four-tab-pane"
                      role="tabpanel"
                      aria-labelledby="Four-tab"
                      tabIndex="0"
                    >
                      <Tabs
                        heading="PHP"
                        detail="PHP is utilized by 78.9% of the websites all over the world. This open-source scripting language is extremely simple and its command functions are easy to comprehend. Furthermore, the syntax is logical and well-organized which makes the final product impressive and efficient. We integrate PHP with other technologies like Java and create a large number of applications. Since it works efficiently with different databases as well, we make it work with MySQL and PostgreSQL too. By using PHP, our developers develop brilliant products like E-learning Platforms, Content Management Systems, and Learning Management Systems etc."
                        web={true}
                      />
                    </div>
                    <div
                      className="tab-pane fade"
                      id="Five-tab-pane"
                      role="tabpanel"
                      aria-labelledby="Five-tab"
                      tabIndex="0"
                    >
                      <Tabs
                        heading=".NET"
                        detail=".NET is a complete package of all the useful technical stuff as it consists of editors, languages and libraries for building various types of websites and applications. Its deployment is very flexible and maintenance is pretty easy, therefore, it is a great open-source developer platform. Our team creates wonderful applications with .NET by using its amazing MVC (Model-View-Controller) architecture, superior UI controls and intuitive UI."
                        web={true}
                      />
                    </div>
                    <div
                      className="tab-pane fade"
                      id="Six-tab-pane"
                      role="tabpanel"
                      aria-labelledby="Six-tab"
                      tabIndex="0"
                    >
                      <Tabs
                        detail="Java has the ability to create dynamic websites because of its simplicity and security. Moreover, its maintenance is also quite economical, as for running, Java programs depend upon a specific hardware infrastructure. We use this programming language and software platform for enhancing the flexibility of the code. It’s OOPs concept works very well for us when it comes to reusing the object in any other program. Additionally, it makes it easier for us to organize the bigger modules into smaller ones and create marvelous websites. The websites we develop using Java are Customized websites, Personalized Websites, Company websites and much more."
                        heading="Java"
                        web={true}
                      />
                    </div>
                    <div
                      className="tab-pane fade"
                      id="Sev-tab-pane"
                      role="tabpanel"
                      aria-labelledby="Sev-tab"
                      tabIndex="0"
                    >
                      <Tabs
                        heading="MariaDB"
                        detail="MariaDB is a fork of MySQL. In other words, it is an enhanced, drop-in replacement of MySQL. A drop-in replacement means that you can substitute the standard MySQL server with the analog version of the MariaDB server and take full advantage of the improvements in the MariaDB without the need to modify your application code."
                        web={true}
                      />
                    </div>
                    <div
                      className="tab-pane fade"
                      id="Eight-tab-pane"
                      role="tabpanel"
                      aria-labelledby="Eight-tab"
                      tabIndex="0"
                    >
                      <Tabs
                        heading="Vue"
                        detail=" Vue is a JavaScript framework for building user interfaces. It builds on top of standard HTML, CSS and JavaScript, and provides a declarative and component-based programming model that helps you efficiently develop user interfaces, be it simple or complex."
                        web={true}
                      />
                    </div>
                    <div
                      className="tab-pane fade"
                      id="Nine-tab-pane"
                      role="tabpanel"
                      aria-labelledby="Nine-tab"
                      tabIndex="0"
                    >
                      <Tabs
                        heading="Microsoft SQL Server"
                        detail="The world’s most popular database, MySQL greatly benefits businesses because of its data security and transactional processing. Its optimum speed and high availability solutions are its superior qualities. We utilize this database for the comprehensive support that every application development needs because MySQL is outstanding when it comes to faster maintenance, debugging and upgrades. Also, MySQL Enterprise’s 24*7 open-source freedom support makes it very unique and feasible for us to use MySQL Server.
"
                        web={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr className="mb-0"></hr>
          </div>
        ) : (
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h2 className="font-weight-700 mb-4">Tech stack</h2>
                <div className="tech-tabs">
                  <ul
                    className="nav nav-tabs justify-content-center"
                    id="myTab"
                    role="tablist"
                  >
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link active"
                        id="One-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#One-tab-pane"
                        type="button"
                        role="tab"
                        aria-controls="One-tab-pane"
                        aria-selected="true"
                      >
                        <div className={`tech-thumb ${styles.tech_space}`}>
                          <img alt="" src={experty1} />
                        </div>
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="Two-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#Two-tab-pane"
                        type="button"
                        role="tab"
                        aria-controls="Two-tab-pane"
                        aria-selected="false"
                      >
                        <div className={`tech-thumb ${styles.tech_space}`}>
                          <img alt="" src={experty7} />
                        </div>
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="Three-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#Three-tab-pane"
                        type="button"
                        role="tab"
                        aria-controls="Three-tab-pane"
                        aria-selected="false"
                      >
                        <div className={`tech-thumb ${styles.tech_space}`}>
                          <img alt="" src={experty12} />
                        </div>
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="Four-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#Four-tab-pane"
                        type="button"
                        role="tab"
                        aria-controls="Four-tab-pane"
                        aria-selected="false"
                      >
                        <div className={`tech-thumb ${styles.tech_space}`}>
                          <img alt="" src={experty13} />
                        </div>
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="Five-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#Five-tab-pane"
                        type="button"
                        role="tab"
                        aria-controls="Five-tab-pane"
                        aria-selected="false"
                      >
                        <div className={`tech-thumb ${styles.tech_space}`}>
                          <img alt="" src={experty14} />
                        </div>
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="Six-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#Six-tab-pane"
                        type="button"
                        role="tab"
                        aria-controls="Six-tab-pane"
                        aria-selected="false"
                      >
                        <div className={`tech-thumb ${styles.tech_space}`}>
                          <img alt="" src={experty15} />
                        </div>
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="Sev-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#Sev-tab-pane"
                        type="button"
                        role="tab"
                        aria-controls="Sev-tab-pane"
                        aria-selected="false"
                      >
                        <div className={`tech-thumb ${styles.tech_space}`}>
                          <img alt="" src={experty16} />
                        </div>
                      </button>
                    </li>
                  </ul>
                  <div className="tab-content" id="myTabContent">
                    <div
                      className="tab-pane fade show active"
                      id="One-tab-pane"
                      role="tabpanel"
                      aria-labelledby="One-tab"
                      tabIndex="0"
                    >
                      <Tabs
                        heading="React.js"
                        detail="React.js is the most used web framework among software developers all over the world as it is very intuitive to work with and makes it easier to interact with the layout of any UI. Fast and quality assured applications can be developed with React technology and the fusion of JavaScript and HTML syntax in it automatically simplifies the whole procedure of code writing of the project, therefore, we utilize this awesome technology for creating E-Commerce Apps, Messaging Apps, Tracking Apps and what not. We also deliver solutions in React with powerful user interfaces that provide strong functionality and tremendous visuals for catering the needs of a business.
"
                        web={true}
                      />
                    </div>
                    <div
                      className="tab-pane fade"
                      id="Two-tab-pane"
                      role="tabpanel"
                      aria-labelledby="Two-tab"
                      tabIndex="0"
                    >
                      <Tabs
                        heading="Node.js"
                        detail="Almost 98% of the IT professionals of the world trust Node.js development because it offers easy scalability and improves the response time of the application. Not only this, it is a very cost-effective technology which helps in building cross-platform applications having excellent performance. Node has the ability to be combined with many other advanced technologies like React.js, Angular.js and Vue.js etc. which makes it a one of its kind technology. We use Node.js for building modern applications like Chat App, Basic Users System, User Login System, Personalized Websites and much more."
                        web={true}
                      />
                    </div>
                    <div
                      className="tab-pane fade"
                      id="Three-tab-pane"
                      role="tabpanel"
                      aria-labelledby="Three-tab"
                      tabIndex="0"
                    >
                      <Tabs
                        heading="Symfony"
                        detail="When it comes to best user experience, Symfony definitely shows up in the list of technologies as it creates numerous functionalities on the page. Symfony is a quite adaptable PHP framework because it is completely configurable due to its Dependency Injector and Event Dispatcher. As it allows us to focus on the actual highlights of an application, our developers deliver superb projects by using it – like websites, web solutions, social networks and a lot more."
                        web={true}
                      />
                    </div>
                    <div
                      className="tab-pane fade"
                      id="Four-tab-pane"
                      role="tabpanel"
                      aria-labelledby="Four-tab"
                      tabIndex="0"
                    >
                      <Tabs
                        heading="Laravel"
                        detail="Laravel is one of the most popular PHP backend frameworks as its powerful built-in system makes it simpler to automate a lot of repetitive coding tasks. On the other hand, its syntax is easy to understand and the built-in libraries are quite useful. We use this robust and scalable technology for developing various types of applications including Educational Websites, E-learning Websites and Blogging Applications."
                        web={true}
                      />
                    </div>
                    <div
                      className="tab-pane fade"
                      id="Five-tab-pane"
                      role="tabpanel"
                      aria-labelledby="Five-tab"
                      tabIndex="0"
                    >
                      <Tabs
                        heading="TypeScript"
                        detail="TypeScript is a strongly typed programming language that builds on JavaScript, giving you better tooling at any scale. It is a strict syntactical superset of JavaScript and adds optional static typing to the language."
                        web={true}
                      />
                    </div>
                    <div
                      className="tab-pane fade"
                      id="Six-tab-pane"
                      role="tabpanel"
                      aria-labelledby="Six-tab"
                      tabIndex="0"
                    >
                      <Tabs
                        heading="Next.js"
                        detail="Next.js is a JavaScript framework that allows developers to come up with server-side views and static web applications using React. It allows for a very simple and straightforward project journey since there's no need for configuring the basics, instead, the initial stages of the projects can be done by executing a few short and simple commands.
"
                        web={true}
                      />
                    </div>
                    <div
                      className="tab-pane fade"
                      id="Sev-tab-pane"
                      role="tabpanel"
                      aria-labelledby="Sev-tab"
                      tabIndex="0"
                    >
                      <Tabs
                        heading="Go"
                        detail="Go is a statically typed, compiled programming language. It is syntactically similar to C, but with memory safety, garbage collection, structural typing, and CSP-style concurrency.
"
                        web={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr className="mb-0"></hr>
          </div>
        )}
      </section>
    </>
  );
};

export default TechStackCard;
