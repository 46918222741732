import services1 from "../../images/service1.svg";
import services1_1 from "../../images/service1-1.svg";
import services2 from "../../images/service2.svg";
import services2_2 from "../../images/service2-2.svg";
import services3 from "../../images/service3.svg";
import services3_3 from "../../images/service3-3.svg";
import services4 from "../../images/service4.svg";
import services4_4 from "../../images/service4-4.svg";
import mobileserv from "../../images/mobileserv.svg";
import mobileserv1 from "../../images/mobileserv1.svg";
import customserv from "../../images/customserv.svg";
import customserv1 from "../../images/customserv1.svg";
import qualityserv from "../../images/qualityserv.svg";
import qualityserv1 from "../../images/qualityserv1.svg";
export const exploredata = [
  {
    id: 1,
    title: "Digital Product Design",
    content:
      "Take your web and mobile app to the next level with  adaptive design and transform your company digitally.",
    display: services1,
    subdisplay: services1_1,
    url: "/services/digital-product-design",
  },

  {
    id: 2,
    title: "Agile Development Team",
    content: "Expand your team with our experts upon your demands",
    display: services4,
    subdisplay: services4_4,
    url: "/services/agile-development",
  },
  {
    id: 3,
    title: "Web Development",
    content:
      "Product development at all stages with continued support after launch.",
    display: services2,
    subdisplay: services2_2,
    url: "/services/web-development",
  },
  {
    id: 4,
    title: "Mobile Development",
    content:
      "We built our first mobile app in 2011. Since then, we’ve helped thousands of companies connect with people wherever they are in the world.",
    display: mobileserv,
    subdisplay: mobileserv1,
    url: "/services/mobile-development",
  },
  {
    id: 5,
    title: "Software Architecture",
    content: "You can build a dependable and dynamic product core",
    display: services3,
    subdisplay: services3_3,
    url: "/services/software-architecture",
  },
  {
    id: 6,
    title: "Custom Software Development",
    content:
      "Build or scale a competitive product ready for future growth and millions of users.",
    display: customserv,
    subdisplay: customserv1,
    url: "/services/custom-software-development",
  },
  {
    id: 7,
    title: "Quality Assurance As a Service",
    content: "Dive into the insights of quality assurance and testing.",
    display: qualityserv,
    subdisplay: qualityserv1,
    url: "/services/quality-assurance",
  },
];
