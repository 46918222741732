import React from "react";
import "./CareerDetailCard.module.css";
const CareerDetailCarad = ({ location, title, address }) => {
  return (
    <>
      <h4 className={`text--gradient-sm pagetitle font-weight-700`}>
        {location}
      </h4>
      <h1 className="text-white text-capitalize">{title}</h1>
      <h4 className={`text-white pagetitle font-weight-700`}>{address}</h4>
    </>
  );
};

export default CareerDetailCarad;
