import React from "react";
import styles from "./CaseStudyCard.module.css";
export const CaseStudyCard = ({ heading, details }) => {
  return (
    <>
      <h2 className={`font-weight-700 ${styles.caseh2}`}>{heading}</h2>
      <h4 className={`${styles.caseh4}`}>{details}</h4>
    </>
  );
};
