import React from "react";

import IndustriesBanner from "../../components/Banner/IndustriesBanner";
import CaseStudiesBanner from "../../components/CaseStudy/CaseStudiesBanner";
import MainTextCard from "../../components/Development/MainTextCard";
import Footer from "../../components/Footer/Footer";
import GotProject from "../../components/Got_Project/GotProject";
import IndustriesAcc from "../../components/Industries/IndustriesAcc";
import IndustriesDevs from "../../components/Industries/IndustriesDevs";
import IndustriesExperties from "../../components/Industries/IndustriesExperties";
import ecommimage from "../../images/industry-realestate.svg";
import { TabTitle } from "../../Utils/GeneralFunction";
import styles from "./IndustriesEcommerce.module.css";
const IndustriesRealEstate = () => {
  TabTitle("Industries | Real Estate");
  return (
    <>
      <IndustriesBanner
        heading="Helping Real Estate Businesses Take a Step Toward Digital Transformation"
        subheading="Real Estate Software Development Company"
        detail="Real Estate sector is rapidly embracing major technology advancement."
        background_image="realEstate"
        industry_btn={true}
        ind_btn_text=" Request a proposal"
      />
      <MainTextCard
        page="ecomm"
        heading=" Making property buying and selling real-time"
        detail=<div>
          <h2 className="font-weight-700">
            Scale with our Real Estate Software development services
          </h2>
          <h3 className={`font-weight-400 text--web h3-22-font`}>
            Land owners who want to sell their properties are always looking for the buyers, and the buyers, on the other hand, try to find the best property along with a reasonable price.
          </h3>

          <h4 className={`font-weight-400 text--web pt-1 ${styles.content}`}>
           iSoft Technologies is capable of developing such kinds of Real Estate websites that makes it super easy for both the parties to connect with each other and then make a deal. The most crucial data regarding the property like its location, area and price are given on the website so that the customers quickly decide which one they want to go for. Furthermore, as the property gets sold, the company can update the pictures and information of the fresh property right away so that the business never cools down.
          </h4>
          <h4 className={`font-weight-400 text--web pt-1 ${styles.content}`}>
           The pictures of the residential or commercial properties are set up in this way that the customer can really feel that he/she is present in the property and observing it. Negotiation can also take place between the company and the customer through the chat box made during the development process. Customers can book the property from the website by giving their information within a few minutes as well.
          </h4>
        </div>
      />
      <IndustriesDevs
        textcontent="Have resource to the services of experienced Real Estate developers at Innowise Group"
        textmain="Get profit from a bespoke Real Estate solution."
        page="realestate"
        imagebig={ecommimage}
        title="Real Estate Software Development"
        heading="Providing relevant and efficient software services"
      />
      <IndustriesExperties
        title="Our Real Estate Development Expertise"
        heading="We use the most perfect combination of technologies while creating a Healthcare app, this is how it gets way easier for the patients to get medical services with just a few clicks, and the institute to highlight its services, departments, and medical facilities. Our team makes sure that the patient doesn’t have to go through lengthy lists of doctors to find the one that they need, so precise lists of departments are incorporated. Additionally, the institute won’t have to call the patient asking for the basic details, the moment the patient will book an appointment, he/she will have to fill a form in which they will enter their data. The proficiency of our developers also allows us to create customized healthcare websites according to the healthcare institute’s requirements."
        page="realestate"
      />
      <CaseStudiesBanner
        title="Real Estate
case studies"
      />
      <IndustriesAcc
        heading="Real Estate web development all your questions answered

"
        content="Find the answers below to the most common questions regarding Real Estate app development or contact us directly."
        page="realestate"
      />
      <GotProject every={true} />
      <Footer contacting={true} />
    </>
  );
};

export default IndustriesRealEstate;
