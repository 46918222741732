import React from "react";
import styles from "./CodingCard.module.css";

const CodingCard = ({
  detail,
  heading,
  image_main,
  list,
  second,
  grad,
  oneline,
  line1,
  secline,
  line2,
  line3,
  line4,
  stripe,
  stripe1,
  stripe2,
  stripe3,
  stripe4,
  fourthline,
  thirdline,
  web_tech,
}) => {
  return (
    <>
      {list === true ? (
        <section
          className={web_tech === true ? `` : `${styles.language_section1}`}
        >
          <div className={web_tech === true ? `container p-0` : `container`}>
            <div className="row">
              <div className="col-lg-12">
                <div className={`${styles.language_tech} mt-5`}>
                  <span className={`${styles.tech_icon}`}>
                    <img alt="" src={image_main} />
                  </span>

                  {grad === true ? (
                    <h2
                      className={
                        second === true
                          ? `font-weight-700 mb-3`
                          : `font-weight-700 mb-3 text--gradient-sm`
                      }
                    >
                      {heading}
                    </h2>
                  ) : (
                    <h2 className="font-weight-700 mb-3">{heading}</h2>
                  )}
                  <h3 className="font-weight-400 text--web txt mb-4">
                    {detail}
                  </h3>

                  {stripe === true && (
                    <div className="row">
                      <div className="col-md-6">
                        <ul className="arrow-list pt-1 mt-4">
                          <li>
                            <p className="font-weight-400 text--web">
                              {stripe1}
                            </p>
                          </li>
                          <li>
                            <p className="font-weight-400 text--web">
                              {stripe2}
                            </p>
                          </li>
                        </ul>
                      </div>
                      <div className="col-md-6">
                        <ul className="arrow-list pt-1 mt-4">
                          <li>
                            <p className="font-weight-400 text--web">
                              {stripe3}
                            </p>
                          </li>
                          <li>
                            <p className="font-weight-400 text--web">
                              {stripe4}
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  )}
                  {oneline === true && (
                    <ul className="arrow-list">
                      <li>
                        <p
                          className={`font-weight-400 text--web ${styles.margining_left}`}
                        >
                          {line1}
                        </p>
                      </li>
                      {secline === true && (
                        <li>
                          <p
                            className={`font-weight-400 text--web ${styles.margining_left}`}
                          >
                            {line2}
                          </p>
                        </li>
                      )}
                      {thirdline === true && (
                        <li>
                          <p
                            className={`font-weight-400 text--web ${styles.margining_left}`}
                          >
                            {line3}
                          </p>
                        </li>
                      )}
                      {fourthline === true && (
                        <li>
                          <p
                            className={`font-weight-400 text--web ${styles.margining_left}`}
                          >
                            {line4}
                          </p>
                        </li>
                      )}
                    </ul>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <section className={`${styles.codinglang} bg--gradient`}>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className={`${styles.language_tech}`}>
                  <span className={`${styles.tech_icon}`}>
                    <img alt="" src={image_main} />
                  </span>
                  <h2 className="font-weight-700 mb-3 text-white">{heading}</h2>
                  <h3 className="font-weight-400 text-white txt mb-4">
                    {detail}
                  </h3>
                  <ul className="arrow-list text-white">
                    <li>
                      <p
                        className={`font-weight-400 text-white text--web ${styles.margining_left}`}
                      >
                        {line1}
                      </p>
                    </li>
                    <li>
                      <p
                        className={`font-weight-400 text-white text--web ${styles.margining_left}`}
                      >
                        {line2}
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default CodingCard;
