import React from "react";

const RequirementCard = ({ detail }) => {
  return (
    <>
      <li>
        <h4 className="font-weight-400 text--web">{detail}</h4>
      </li>
    </>
  );
};

export default RequirementCard;
