import React from "react";
import Banner from "../../components/Banner/Banner";
import contact_footer from "../../images/contact-footer.png";

import cypress from "../../images/cypress.png";
import jest from "../../images/jest.png";
import agile_dev from "../../images/agile-dev.png";
import Intro from "../../components/AgileDev/Intro";
import styles from "./Quality.module.css";
import Tabs from "../../components/Tabs/Tabs";
import AgileDevCard from "../../components/AgileDev/AgileDevCard";
import Aboutisoft from "../../components/About/Aboutisoft";

import quality from "../../images/team.jpg";

import OurApproach from "../../components/Approach/OurApproach";
import Footer from "../../components/Footer/Footer";
import GotProject from "../../components/Got_Project/GotProject";
import approach from "../../images/approach.svg";
import quality_assurance from "../../images/QA1.jpg";
import qa1 from "../../images/qa1.svg";
import qa2 from "../../images/qa2.svg";
import qa3 from "../../images/qa3.svg";
import qa4 from "../../images/qa4.svg";
import qa5 from "../../images/qa5.svg";
import qa6 from "../../images/qa6.svg";
import { TabTitle } from "../../Utils/GeneralFunction";
const QualityAssurance = () => {
  TabTitle("Services | Quality Assurance");
  return (
    <>
      <Banner
        heading="Quality Assurance"
        detail=" Ensure the Quality of Your Software
"
        agile={true}
        btn_detail="Start a project"
        image={contact_footer}
        image_mobile={agile_dev}
        image_large={agile_dev}
      />
      <Intro
        heading="Versed in comprehensive testing best practices"
        detail="our quality assurance analysts work with your team to identify, fix, and prevent software quality issues. We use our own mix of manual testing, compatibility testing, performance testing, automation engineering, and continuous integration. Additionally, we offer standalone software quality assessment and a managed-service automation framework built to help our clients manage cost, reduce time-to-market, and increase test coverage."
      />
      <section className={`${styles.taber_section}`}>
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className={`${styles.tab_thumb}`}>
                <img alt="" src={quality_assurance} />
              </div>
            </div>
            <div className="col-lg-6">
              <h2 className="font-weight-700 mb-4">Tech stack</h2>
              <div className="tech-tabs">
                <ul
                  className={`nav nav-tabs ${styles.mob_nav}`}
                  id="myTab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="One-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#One-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="One-tab-pane"
                      aria-selected="true"
                    >
                      <span className={`tech-thumb ${styles.tech_des}`}>
                        <img alt="" src={cypress} />
                      </span>
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="Two-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#Two-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="Two-tab-pane"
                      aria-selected="false"
                    >
                      <span className={`tech-thumb ${styles.tech_des}`}>
                        <img alt="" src={jest} />
                      </span>
                    </button>
                  </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="One-tab-pane"
                    role="tabpanel"
                    aria-labelledby="One-tab"
                    tabIndex="0"
                  >
                    <Tabs
                      heading="Cypress"
                      detail="Cypress is a Node server process. Cypress and the Node process constantly communicate, synchronize, and perform tasks on behalf of each other. Having access to both parts (front and back) gives us the ability to respond to your application's events in real time, while at the same time work outside of the browser for tasks that require a higher privilege."
                    />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="Two-tab-pane"
                    role="tabpanel"
                    aria-labelledby="Two-tab"
                    tabIndex="0"
                  >
                    <Tabs
                      heading="Jest
"
                      detail="Jest is a testing platform for client-side JavaScript applications and React applications specifically. It ensure correctness of any JavaScript codebase. It allows you to write tests with an approachable, familiar and feature-rich API that gives you results quickly."
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr className="mb-0"></hr>
        </div>
      </section>
      <section className={`${styles.feature_section}`}>
        <div className="container">
          <div className="row">
            <Aboutisoft
              everyimage={quality}
              extra={true}
              mob={true}
              heading="  QA services behind the success of your product"
              sub_heading="The Ideal Blend of Quality Assurance Methods"
            />

            <AgileDevCard
              heading="Gather"
              detail="  Collect information on the business value of a product and its users"
              image={qa1}
            />
            <AgileDevCard
              heading="Define"
              detail="Based on your business value and customer needs, define what “Quality” means for your product"
              image={qa2}
            />

            <AgileDevCard
              heading="Plan"
              detail="Design and plan tests that spot issues and measure the current quality levels."
              image={qa3}
            />
            <AgileDevCard
              heading="Test"
              detail=" Design and plan tests that spot issues and measure the current quality levels"
              image={qa4}
            />
            <AgileDevCard
              heading="Maintain"
              detail="Create procedures that help to maintain high quality and ensure that your customers not only enjoy your products but that they can rely on them too."
              image={qa5}
            />
            <AgileDevCard
              heading="Development handover"
              detail="Get ready for implementing designs into working products"
              image={qa6}
            />
          </div>
          <hr className="mb-0"></hr>
        </div>
      </section>
      <OurApproach
        heading="    Why Businesses Choose Us For Mobile Application Development?"
        title_main="Our Approach"
        image={approach}
        detail1="  QA as a Service is a modern way to build QA processes that are tailor-made, scalable and on-demand. Once we grasp your business context & challenges, we’ll prepare a dedicated engagement model. It’ll be tailored to your needs, expectations and possibilities. As external experts, we’ll make sure that test results are not biased by any factors. Established and optimized QA processes from day one. We made predictable (yet flexible) model for ensuring high quality of your product."
      />
      <GotProject every={true} />
      <Footer contacting={true} />
    </>
  );
};

export default QualityAssurance;
