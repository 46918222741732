import React from "react";
import { Link } from "react-router-dom";
import styles from "./Expert.module.css";
const Expert = () => {
  return (
    <>
      <div className="web-cta">
        <div className="container">
          <div className={styles.cta_wrapper}>
            <h2 className="font-weight-700">Talk to an expert.</h2>
            <h3 className="font-weight-400 mt-2 mb-3">
              Get in touch with us to discuss innovative product ideas &
              business opportunities.
            </h3>
            <Link
              className={`btn--gradient ${styles.btn_design} `}
              to="/contact-us"
            >
              Get started!
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Expert;
