import React from "react";
import styles from "./AboutCounterCard.module.css";
export const AboutCounterCard = ({ headingpri, headingsec, add }) => {
  return (
    <>
      <div className={`mt-5 ${styles.counter_item} `}>
        <h2 className={`${styles.number_item}`}>
          <span className={`${styles.counter_numberz}`} data-to="10">
            {headingpri}
          </span>
          {add === true && <span>+</span>}
        </h2>
        <h2>{headingsec}</h2>
      </div>
    </>
  );
};
