import React from "react";
import homeNew1 from "../../images/homeNew1.jpg"
import rating from "../../images/ratings.png";
import about from "../../images/teamNew.jpg";
import experty1 from "../../images/experty1.png";
import experty2 from "../../images/experty2.png";
import experty3 from "../../images/experty3.png";
import experty5 from "../../images/experty5.png";
import experty17 from "../../images/experty17.png";
import experty7 from "../../images/experty7.png";
import experty9 from "../../images/experty9.png";
import mobiledev2 from "../../images/mobiledev2.png";
import Testimonials from "../../components/Testimonials/Testimonials";
import Expert from "../../components/Expert/Expert";
import SwiperCore, { Pagination, Autoplay } from "swiper";
import Explore from "../../components/Explrore/Explore";
import { Link } from "react-router-dom";
import { Card } from "../../components/HomeScreen/Card";
import { InnerCard } from "../../components/HomeScreen/InnerCard";
import { IndustriesCard } from "../../components/Industries/IndustriesCard";
import { TechExpertiesCard } from "../../components/TechExperties/TechExpertiesCard";
import styles from "./Home_Screen.module.css";
import { AboutCounterCard } from "../../components/About/AboutCounterCard";
import GotProject from "../../components/Got_Project/GotProject";
import Footer from "../../components/Footer/Footer";
import education from "../../images/educationz.svg";
import ecomm from "../../images/ecommerce.svg";
import travel from "../../images/travel.svg";
import real from "../../images/real.svg";
import health from "../../images/healthcares.svg";
import { TabTitle } from "../../Utils/GeneralFunction";
import CaseStudiesBanner from "../../components/CaseStudy/CaseStudiesBanner";

const Home_Screen = () => {
  SwiperCore.use([Pagination, Autoplay]);
  TabTitle("iSoft Technologies ");
  return (
    <>
      <section className={`${styles.web_banner}`}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-7">
              <Card
                heading="Leading the new digital world"
                subheading="Set in motion with the mansion of Web development."
                details="Get started with the sturdy marketplace lead with
                            team that will put together your tech stack and
                            strengthen your software delivery process for
                            radiant future."
                subdetails="Our team of 25+ skill full experts build software's
                            that have high market reach in the industry of
                            Banking, Education, and Finance etc."
                link_to="/contact-us"
                link_title="Let's Talk"
              />
            </div>
            <div className="col-lg-5">
              <div className={`${styles.banner_testimonial}`}>
                <InnerCard
                  content=<div>
                    <p>
                      <strong>Outstanding </strong>
                      service,
                      <strong> super professional, service </strong>
                      oriented and great communication!
                    </p>
                  </div>
                  image={{ rating }}
                  details="Robert Smith"
                  // subdetails="Former CTO at Whim"
                  right={true}
                />
              </div>
              <div
                className={`ms-auto ${styles.banner_testimonial} right-align`}
              >
                <InnerCard
                  content=<div>
                    <p>
                      <strong>iSoft Technologies </strong>
                      as well as best practiced.
                      <strong> Highly recommended </strong>
                      software house.
                    </p>
                  </div>
                  image={{ rating }}
                  details="Adam Marcin"
                  // subdetails="Former CTO at Whim"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <CaseStudiesBanner title="Case Studies" />
      <Explore picForHome={homeNew1}/>
      <section className={styles.about_isoft}>
        <div className="container">
          <div className={` text-center ${styles.about_isoft_title} `}>
            <h2 className="font-weight-700">About iSoft Technologies</h2>
            <h3>
              Talent wins games, but teamwork and intelligence win
              championships.{" "}
            </h3>
            <h4>
              Dedicated team is the ideal choice for the clients who figure out
              the complexity of their own projects and are prepared to put
              resources into the steady and ceaseless development of quality
              products.
            </h4>
            <div className={` mt-5 ${styles.about_isoft_thumb} `}>
              <img alt="" src={about} />
            </div>
          </div>
          <div className={styles.counter_flex}>
            <AboutCounterCard headingpri="10" headingsec="Years" />
            <AboutCounterCard
              headingpri="100"
              headingsec="Clients"
              add={true}
            />
            <AboutCounterCard
              headingpri="120"
              headingsec="Projects Done"
              add={true}
            />
            <AboutCounterCard
              headingpri="45"
              headingsec="Professionals"
              add={true}
            />

            <div className={`${styles.meetus_button} mt-5`}>
              <Link
                className={`btn-gradient-border ${styles.meetus_button_gradient}`}
                to="/about-us"
              >
                <span className={`${styles.meet}`}>Meet with us</span>
              </Link>
            </div>
          </div>
        </div>
      </section>
      <Expert />
      <section className={styles.our_experties}>
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className={styles.experty_box}>
                <h2 className="font-weight-700 text-capitalize">
                  Our Technology Stack
                </h2>
                <p>
                  The right technology stack is the groundwork of your
                  architected custom software. We fabricate your product with
                  the most latest and top core technologies, to suit any sort of
                  application.
                </p>
                <Link
                  className={`text--gradient ${styles.btn_experty} font-weight-700`}
                  to="/contact-us"
                >
                  Hire technology experts
                  <i
                    className={`fas fa-arrow-right ${styles.experty_arrow}`}
                  ></i>
                </Link>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="experty-wrapp row">
                <TechExpertiesCard image={experty7} />
                <TechExpertiesCard image={experty2} />
                <TechExpertiesCard image={experty3} />
                <TechExpertiesCard image={experty1} />

                <TechExpertiesCard image={experty5} />
                <TechExpertiesCard image={experty9} />
                <TechExpertiesCard image={mobiledev2} />
                <TechExpertiesCard image={experty17} />
              </div>

              <Link
                className={` font-weight-700 mt-4 text--gradient ${styles.btn_experty} ${styles.showmobile}`}
                to="/contact-us"
              >
                Hire technology experts <i className="fas fa-arrow-right"></i>
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section className="some-industries">
        <div className="container">
          <div className={styles.industry_wrapp}>
            <h2 className="font-weight-700">Industries We Are Serving</h2>
            <h4 className="mt-3">
              Digital acceleration with tailor made arrangements. Your team’s
              cross-industry know how will step up your venture.
            </h4>
            <div className="industries-wrapper row">
              <IndustriesCard
                headingpri="Ecommerce"
                imagepri={ecomm}
                learn="/industries/ecommerce"
              />
              <IndustriesCard
                headingpri="Travel"
                imagepri={travel}
                learn="/industries/travel-tourism"
              />
              <IndustriesCard
                headingpri="Education"
                imagepri={education}
                learn="/industries/education"
              />
              <IndustriesCard
                headingpri="Real Estate"
                imagepri={real}
                learn="/industries/realestate"
              />
              <IndustriesCard
                headingpri="Healthcare"
                imagepri={health}
                learn="/industries/healthcare"
              />
            </div>
          </div>
        </div>
      </section>

      <Testimonials />
      <GotProject every={true} />
      <Footer contacting={true} />
    </>
  );
};

export default Home_Screen;
