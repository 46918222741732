import dev1 from "../../images/dev1.svg";
import dev2 from "../../images/dev2.svg";
import dev3 from "../../images/dev3.svg";
import dev4 from "../../images/dev4.svg";
import dev5 from "../../images/dev5.svg";
export const ecommerce = [
  {
    id: 1,
    image: dev1,
    heading: "E-commerce Consulting ",
    text: "on technologies and approaches",
  },
  {
    id: 2,
    image: dev2,
    heading: "E-commerce Prototyping or MVP",
    text: " to validate the business idea",
  },
  {
    id: 3,
    image: dev3,
    heading: "Full-Stack E-commerce development",
    text: " of e-commerce solutions",
  },
  {
    id: 4,
    image: dev4,
    heading: "Enhancing",
    text: " and extending functionality",
  },
  {
    id: 5,
    image: dev5,
    heading: "E-commerce Solution Integration",
    text: " with other systems, e.g. CRM, ERP, MDM",
  },
];

export const education = [
  {
    id: 1,
    image: dev1,
    heading: "E-commerce Consulting ",
    text: "on technologies and approaches",
  },
  {
    id: 2,
    image: dev2,
    heading: "E-commerce Prototyping or MVP",
    text: " to validate the business idea",
  },
  {
    id: 3,
    image: dev3,
    heading: "Full-Stack E-commerce development",
    text: " of e-commerce solutions",
  },
  {
    id: 4,
    image: dev4,
    heading: "Enhancing",
    text: " and extending functionality",
  },
  {
    id: 5,
    image: dev5,
    heading: "E-commerce Solution Integration",
    text: " with other systems, e.g. CRM, ERP, MDM",
  },
];

export const realestate = [
  {
    id: 1,
    image: dev1,
    heading: "We provide consultation  ",
    text: "on digital transformation of your real estate business",
  },
  {
    id: 2,
    image: dev2,
    heading: "We build prototypes or MVPs ",
    text: "to validate your business idea",
  },
  {
    id: 3,
    image: dev3,
    heading: "We provide full-stack development services",
    text: " for real estate software solutions",
  },
  {
    id: 4,
    image: dev4,
    heading: "We improve the software",
    text: " and expand on its functionality",
  },
  {
    id: 5,
    image: dev5,
    heading: "We integrate your software with 3rd party solutions",
    text: " to streamline your workflow, such as real estate CRMs or payment processing systems",
  },
];

export const healthcare = [
  {
    id: 1,
    image: dev1,
    heading: "We provide consultation  ",
    text: "on digital transformation of your real estate business",
  },
  {
    id: 2,
    image: dev2,
    heading: "We build prototypes or MVPs ",
    text: "to validate your business idea",
  },
  {
    id: 3,
    image: dev3,
    heading: "We provide full-stack development services",
    text: " for real estate software solutions",
  },
  {
    id: 4,
    image: dev4,
    heading: "We improve the software",
    text: " and expand on its functionality",
  },
  {
    id: 5,
    image: dev5,
    heading: "We integrate your software with 3rd party solutions",
    text: " to streamline your workflow, such as real estate CRMs or payment processing systems",
  },
];

export const travel = [
  {
    id: 1,
    image: dev1,
    heading: "We provide consultation  ",
    text: "on digital transformation of your real estate business",
  },
  {
    id: 2,
    image: dev2,
    heading: "We build prototypes or MVPs ",
    text: "to validate your business idea",
  },
  {
    id: 3,
    image: dev3,
    heading: "We provide full-stack development services",
    text: " for real estate software solutions",
  },
  {
    id: 4,
    image: dev4,
    heading: "We improve the software",
    text: " and expand on its functionality",
  },
  {
    id: 5,
    image: dev5,
    heading: "We integrate your software with 3rd party solutions",
    text: " to streamline your workflow, such as real estate CRMs or payment processing systems",
  },
];
