import React from "react";
import "./DigitalProductCard.css";
export const DigitalProductCard = ({ image, title, heading, even }) => {
  return (
    <>
      {even === true ? (
        <div className="digital-element">
          <div className="element-inner">
            <img alt="" src={image} />
            <p>{title}</p>
          </div>
          <h4>{heading}</h4>
        </div>
      ) : (
        <>
          <div className="digital-element">
            <h4>{heading}</h4>
            <div className="element-inner">
              <img alt="" src={image} />
              <p>{title}</p>
            </div>
          </div>
        </>
      )}
    </>
  );
};
