import React from "react";
import CodingCard from "../../components/Coding/CodingCard";
import GotProject from "../../components/Got_Project/GotProject";
import Footer from "../../components/Footer/Footer";

import banner_testing from "../../images/banner-testing.png";
import BannerCoding from "../../components/Banner/BannerCoding";

import stacks from "../../images/stacks.svg";
import styles from "./Testing.module.css";
const Testing = () => {
  return (
    <>
      {" "}
      <BannerCoding
        image={banner_testing}
        heading="Testing/Quality"
        detail="  Build or rescale your project with programming minds 
                            rated 4.9 on Clutch. Using React.js, React Native, 
                            TypeScript & Next.js."
        btn_detail="Describe the project"
      />
      <CodingCard
        list={true}
        grad={true}
        second={true}
        image_main={stacks}
        heading="Cypress:"
        detail="Cypress is a Node server process. Cypress and the Node process constantly communicate, synchronize, and perform tasks on behalf of each other. Having access to both parts (front and back) gives us the ability to respond to your application's events in real time, while at the same time work outside of the browser for tasks that require a higher privilege."
        line1="Cypress also operates at the network layer by reading and altering web traffic on the fly. This enables Cypress to not only modify everything coming in and out of the browser, but also to change code that may interfere with its ability to automate the browser."
        line2=" Cypress ultimately controls the entire automation process from top to bottom, which puts it in the unique position of being able to understand everything happening in and outside of the browser. This means Cypress is capable of delivering more consistent results than any other testing tool."
        line3=" Because Cypress is installed locally on your machine, it can additionally tap into the operating system for automation tasks. This makes performing tasks such as taking screenshots, recording videos, general file system operations and network operations possible."
        oneline={true}
        secline={true}
        thirdline={true}
      />
      <CodingCard
        list={true}
        grad={true}
        second={true}
        image_main={stacks}
        heading="Jest:"
        detail="Jest is a testing platform for client-side JavaScript applications and React applications specifically. It ensure correctness of any JavaScript codebase. It allows you to write tests with an approachable, familiar and feature-rich API that gives you results quickly. the industry."
        line1="Jest is well-documented, requires little configuration and can be extended to match your requirements.
 "
        line2="est aims to work out of the box, configure free, on most JavaScript projects."
        oneline={true}
        secline={true}
      />
      <div className={`${styles.margining_top}`}></div>
      <GotProject every={true} />
      <Footer contacting={true} />
    </>
  );
};

export default Testing;
