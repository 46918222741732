import React from "react";
import styles from "./CareerTech.module.css";
export const CareerTech = ({ title, detail }) => {
  return (
    <>
      <div className={styles.work_inner}>
        <h3 className="font-weight-700 text-capitalize">{title}</h3>
        <h4 className="font-weight-400 text--web">{detail}</h4>
      </div>
    </>
  );
};
