import casestudy_1 from "../../images/casestudy1.jpg";
import casestudy_2 from "../../images/casestudy2.jpg";
import casestudy_3 from "../../images/phundee.png";
import casestudy_4 from "../../images/Behind-the-veil-Banner.png";
export const casestudyfilter = [
  {
    id: 1,
    title: "Travel",
  },
  {
    id: 2,
    title: "Ecommerce",
  },

  {
    id: 3,
    title: "Education",
  },
  {
    id: 4,
    title: "Real Estate",
  },
  {
    id: 5,
    title: "Healthcare",
  },
];

export const products = [
  {
    id: 1,
    category: "Travel",
    p_heading_pri: "Universal Visas",
    p_detail_pri:
      "Universal Visas is a UK based visa &   passport services providing company.",
    p_image: casestudy_1,
    p_heading_sec: "Keywords",
    p_detail_sec:
      "  Travel | UX/UI | Development | Integrations | Technologies | Universal Visas",
    url: "/our-work/universal-visas",
  },
  {
    category: "Ecommerce",
    id: 2,
    p_heading_pri: "Yabee.me",
    p_detail_pri:
      " Yabee.me is among the multi-vendor marketplaces         that are powered by Laravel.",
    p_image: casestudy_2,
    p_heading_sec: "Keywords",
    p_detail_sec:
      "   Ecommerce | Web Design | Cloud Technology | Enterprise Organization",
    url: "/our-work/yabee.me",
  },
  {
    category: "Travel",
    id: 3,
    p_heading_pri: "Phundee",
    p_detail_pri:
      "Phundee.com is a crowd-funding platform used by people for collecting funds for film-industry related projects. ",
    p_image: casestudy_3,
    p_heading_sec: "Keywords",
    p_detail_sec:
      "Art | UX/UI | Development | Integrations | Technologies | Phundee",
    url: "/our-work/phundee",
  },
  {
    category: "Ecommerce",
    id: 4,
    p_heading_pri: "Behind The Veil",
    p_detail_pri:
      "Behind the Veil is an event organizing website. It offers multiple services and venues for making weddings full of thrill and excitement.",
    p_image: casestudy_4,
    p_heading_sec: "Keywords",
    p_detail_sec:
      "Event Organizing | Web Design | Enterprise | Development | Organization",
    url: "/our-work/behind-the-veil",
  },
];
