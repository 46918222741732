import React from "react";
import styles from "./CaseStudyQuote.module.css";
const CaseStudyQuote = ({ color }) => {
  return (
    <>
      <section className={`${styles.our_quotes}`}>
        <div className="container">
          <blockquote className={`${styles.quoted_text} text-uppercase`}>
            <span
              className={
                color === "red"
                  ? `${styles.red_quoteicon}`
                  : color === "aquacolor"
                  ? `${styles.aquacolor_quoteicon}`
                  : color === "yellowcolor"
                  ? `${styles.yellowcolor_quoteicon}`
                  : color === "greencolor"
                  ? `${styles.greencolor_quoteicon}`
                  : ``
              }
            ></span>
            Outstanding service, super professional, service oriented and great
            communication!” Well defined core values of iSoft Technologies as
            well as best practiced. Highly recommended software house.
            <span className={`${styles.quote_name}`}>Robert Smith</span>
          </blockquote>
        </div>
      </section>
    </>
  );
};

export default CaseStudyQuote;
