import React from "react";
import styles from "./AboutTabsDetail.module.css";
const AboutTabsDetail = ({ image, heading, details, subdetails }) => {
  return (
    <>
      <div className={`${styles.tabinner} row`}>
        <div className="col-lg-6">
          <div className={`${styles.tab_thumb}`}>
            <img alt="" src={image} />
          </div>
        </div>
        <div className="col-lg-5 offset-lg-1">
          <div className={`${styles.tab_text}`}>
            <h2 className="font-weight-700">{heading}</h2>
            <p className="mt-4">{details}</p>
            <p className="mt-4">{subdetails}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutTabsDetail;
