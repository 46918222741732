import React from "react";
import db from "../../images/banner-datbases.png";
import BannerCoding from "../../components/Banner/BannerCoding";
import stacks1 from "../../images/stacks1.svg";
import stacks from "../../images/stacks.svg";
import styles from "./Database.module.css";
import CodingCard from "../../components/Coding/CodingCard";
import GotProject from "../../components/Got_Project/GotProject";
import Footer from "../../components/Footer/Footer";
import DbCard from "../../components/DBpage/DbCard";
const Database = () => {
  return (
    <>
      <BannerCoding
        image={db}
        heading="    Data Bases"
        detail="  Build or rescale your project with programming minds 
                            rated 4.9 on Clutch. Using React.js, React Native, 
                            TypeScript & Next.js."
        btn_detail="Describe the project"
      />
      <DbCard
        sql={true}
        title="My Sql:"
        duo_list={true}
        image={stacks}
        detail="The world’s most popular database, MySQL greatly benefits businesses because of its data security and transactional processing. Its optimum speed and high availability solutions are its superior qualities. We utilize this database for the comprehensive support that every application development needs because MySQL is outstanding when it comes to faster maintenance, debugging and upgrades. Also, MySQL Enterprise’s 24*7 open-source freedom support makes it very unique and feasible for us to use MySQL Server."
        list1="MySQL can be used for a variety of applications, but is most commonly found on Web servers. A website that uses MySQL may include Web pages that access information from a database. These pages are often referred to as dynamic, meaning the content of each page is generated from a database as the page loads. Websites that use dynamic Web pages are often referred to as database-driven websites."
        list2="Many database-driven websites that use MySQL also use a Web scripting language like PHP to access information from the database. MySQL commands can be incorporated into the PHP code, allowing part or all of a Web page to be generated from database information. Because both MySQL and PHP are both open source (meaning they are free to download and use), the PHP/MySQL combination has become a popular choice for database-driven websites."
        sec_detail="MariaDB is a fork of MySQL. In other words, it is an enhanced, drop-in replacement of MySQL. A drop-in replacement means that you can substitute the standard MySQL server with the analog version of the MariaDB server and take full advantage of the improvements in the MariaDB without the need to modify your application code."
        sec_title="MariaDB:"
        listing1={true}
        list3="MariaDB is fast, scalable and robust. It supports more storage engines than MySQL. It also includes many plugins and tools that make it versatile for lots of use cases."
      />

      <DbCard
        title="PostgreSQL:"
        image={stacks1}
        detail="PostgreSQL is a powerful, open source object-relational database system that uses and extends the SQL language combined with many features that safely store and scale the most complicated data workloads. It aimed to help developers build applications, administrators to protect data integrity and build fault-tolerant environments, and help you manage your data no matter how big or small the dataset. In addition to being free and open source, PostgreSQL is highly extensible."
      />
      <CodingCard
        list={true}
        grad={true}
        second={true}
        image_main={stacks}
        heading="Mongo DB:"
        detail="MongoDB is a NoSQL distributed document-based database which means it stores data as a set of key-value pairs and can be scaled horizontally across multiple nodes in a cluster. It is one of the most popular NoSQL databases in the industry."
        line1="MongoDB can be used for modern, distributed and cloud-based applications, and offers SQL features like ACID transactions, ad hoc queries, joins, indexing, and much more."
        oneline={true}
      />
      <CodingCard
        list={true}
        grad={true}
        second={true}
        image_main={stacks}
        heading="No SQL:"
        detail="PostgreSQL tries to conform with the SQL standard where such conformance does not contradict traditional features or could lead to poor architectural decisions. Many of the features required by the SQL standard are supported, though sometimes with slightly differing syntax or function."
      />
      <CodingCard
        list={true}
        grad={true}
        second={true}
        image_main={stacks}
        heading="Firebase"
        detail="The Firebase Real-time Database is a cloud-hosted database. Data is stored as JSON and synchronized in real time to every connected client. When you build cross-platform apps with our Apple platforms, Android, and JavaScript SDKs, all of your clients share one Real-time Database instance and automatically receive updates with the newest data."
      />
      <div className={`${styles.margining_top}`}></div>
      <GotProject every={true} />
      <Footer contacting={true} />
    </>
  );
};

export default Database;
