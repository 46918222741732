import React from "react";
import language from "../../images/banner-languages.png";
import styles from "./Coding.module.css";
import BannerCoding from "../../components/Banner/BannerCoding";
import CodingCard from "../../components/Coding/CodingCard";
import stacks1 from "../../images/stacks1.svg";
import stacks from "../../images/stacks.svg";
import LanguageCard from "../../components/Coding/LanguageCard";
import Footer from "../../components/Footer/Footer";
import GotProject from "../../components/Got_Project/GotProject";
import { TabTitle } from "../../Utils/GeneralFunction";
const Coding = () => {
  TabTitle("Coding");
  return (
    <>
      <BannerCoding
        image={language}
        heading="Coding Language"
        detail="Build or rescale your project with programming minds rated 4.9 on Clutch. Using React.js, React Native, TypeScript & Next.js."
        btn_detail="Describe the project"
      />

      <LanguageCard />

      <CodingCard
        image_main={stacks1}
        heading="HTML:"
        detail="HyperText Markup Language (HTML) is the basic scripting language used by web browsers to render pages on the World Wide Web. HyperText allows a user to click a link and be redirected to a new page referenced by that link."
        line2="Early versions of HTML were static (Web 1.0), while newer iterations feature a great deal of dynamic flexibility (Web 2.0, 3.0)."
        line1="Markup is the text that appears between two pointed brackets (e.g., ), and content is everything else."
      />
      <CodingCard
        list={true}
        grad={true}
        image_main={stacks}
        heading="PHP:"
        detail="PHP is utilized by 78.9% of the websites all over the world. This open-source scripting language is extremely simple and its command functions are easy to comprehend. Furthermore, the syntax is logical and well-organized which makes the final product impressive and efficient. We integrate PHP with other technologies like Java and create a large number of applications. Since it works efficiently with different databases as well, we make it work with MySQL and PostgreSQL too. By using PHP, our developers develop brilliant products like E-learning Platforms, Content Management Systems, and Learning Management Systems etc."
        line1="When a website visitor accesses a PHP page, the web server processes, or parses, the PHP code, which can output HTML to the webpage."
        oneline={true}
      />
      <CodingCard
        list={true}
        grad={true}
        image_main={stacks}
        heading="Node:"
        detail="Almost 98% of the IT professionals of the world trust Node.js development because it offers easy scalability and improves the response time of the application. Not only this, it is a very cost-effective technology which helps in building cross-platform applications having excellent performance. Node has the ability to be combined with many other advanced technologies like React.js, Angular.js and Vue.js etc. which makes it a one of its kind technology. We use Node.js for building modern applications like Chat App, Basic Users System, User Login System, Personalized Websites and much more."
        line1=" ISoft Technologies has been one of the early adopters of Node.js and have been using it to build secure, scalable and enterprise-level applications. We have helped clients save millions of dollars by providing world-class high quality solutions and expedited delivery."
        oneline={true}
      />
      <div className={`${styles.nav_link}`}></div>
      <GotProject every={true} />
      <Footer contacting={true} />
    </>
  );
};

export default Coding;
