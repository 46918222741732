import React from "react";
import styles from "./AboutCard.module.css";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
export const AboutCard = ({
  title,
  subtitle,
  detail,
  subdetail,
  add,
  upto,
}) => {
  return (
    <>
      <div className={styles.whoweinner}>
        <div className={styles.leftinner}>
          <p
            className={`  ${styles.p_head}  font-weight-600 text-uppercase mb-3`}
          >
            {title}
          </p>
          <p>{subtitle}</p>
        </div>
        <div className={styles.rightinner}>
          <div className={styles.counteritem}>
            <h2
              className={`${styles.counternumber}  ${styles.text_gradient_sm}`}
            >
              <CountUp end={upto} redraw={true}>
                {({ countUpRef, start }) => (
                  <VisibilitySensor onChange={start} delayedCall>
                    <span className={styles.countnum} ref={countUpRef}>
                      {detail}
                    </span>
                  </VisibilitySensor>
                )}
              </CountUp>

              {add === true && <span>+</span>}
            </h2>
            <p>{subdetail}</p>
          </div>
        </div>
      </div>
    </>
  );
};
