import React from "react";
import { useState } from "react";
import styles from "./IndustriesExperties.module.css";
import {
  Ecommerce,
  RealEstate,
  Education,
  HealthCare,
  Travel,
} from "./IndustriesExpertiesPage";

const IndustriesExperties = ({ title, heading, page }) => {
  const [selected] = useState(
    page === "ecomm"
      ? Ecommerce
      : page === "education"
      ? Education
      : page === "realestate"
      ? RealEstate
      : page === "health"
      ? HealthCare
      : page === "travel"
      ? Travel
      : ""
  );
  return (
    <>
      <section className={`${styles.ecommerece_experty}`}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h2 className="font-weight-700 text-white text-capitalize">
                {title}
              </h2>
              <h4 className="font-weight-400 mt-4 commonline text-white">
                {heading}
              </h4>
            </div>
            <div className={`${selected.length > 4 ? "d-flex justify-content-between" : "row"}`}>
              {selected.map((item) => {
                return (
                  <div key={item.id} className={`${selected.length > 4 ? "col-md-2" : "col-md-3"} col-6 experty-col`}>
                    <div className={`${styles.experty_commerece} bg--gradient`}>
                      <span>
                        <img alt="" src={item.image} />
                        <h4 className="font-weight-700 text--gradient">
                          {item.text}
                        </h4>
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default IndustriesExperties;
