import React from "react";

import IndustriesBanner from "../../components/Banner/IndustriesBanner";
import CaseStudiesBanner from "../../components/CaseStudy/CaseStudiesBanner";
import MainTextCard from "../../components/Development/MainTextCard";
import Footer from "../../components/Footer/Footer";
import GotProject from "../../components/Got_Project/GotProject";
import IndustriesAcc from "../../components/Industries/IndustriesAcc";
import IndustriesDevs from "../../components/Industries/IndustriesDevs";
import IndustriesExperties from "../../components/Industries/IndustriesExperties";
import ecommimage from "../../images/industry-education.svg";
import { TabTitle } from "../../Utils/GeneralFunction";
import styles from "./IndustriesEcommerce.module.css";
const IndustriesEducation = () => {
  TabTitle("Industries | Education");
  return (
    <>
      <IndustriesBanner
        heading="ALL-IN-ONE FINTECH SOFTWARE DEVELOPMENT"
        subheading="E-learning Software Development Company"
        detail="A leading E-Learning software development company that makes the learning experience productive for both students and trainers."
        background_image="education"
        industry_btn={true}
        ind_btn_text=" Request a proposal"
      />
      <MainTextCard
        page="ecomm"
        heading=" Scale with Our Ecommerce App Development Services"
        detail=<div>
          <h2 className="font-weight-700 text-capitalize">
            Scale with E-Learning Development Services
          </h2>
          <h3 className={`font-weight-400 text--web ${styles.maincontent}`}>
            In order to learn well, an advanced learning app is always a best
            choice. iSoft Technologies develop productive and well-organized web
            applications for trainers and students.
          </h3>
          <h4 className={`font-weight-400 text--web pt-1 ${styles.content}`}>
            We make it easier for the trainers to provide the lectures on the
            website. Also, the trainers can upload the assignments there of
            which the students would be notified. On the other hand, students
            can have access to each lecture regarding their course and they can
            upload assignments and see their result cards on the app as well.
          </h4>
          <h4 className={`font-weight-400 text--web pt-1 ${styles.content}`}>
            We design customized E-Learning applications that not only cater the
            needs of the institutes but provide great learning experience to
            their students too.
          </h4>
        </div>
      />
      <IndustriesDevs
        textmain="Get profit from a bespoke E-learning solution."
        textcontent="Have resource to the services of experienced E-Learning developers at Innowise Group"
        title="Custom E-learning Development Services"
        heading="Engineering capabilities and industry awareness combined"
        page="education"
        imagebig={ecommimage}
      />
      <IndustriesExperties
        title="Our E-learning Development Expertise"
        heading="We are here to provide you the modern E-Learning software development services. Our team has the ability to develop customized websites that go well with any educational idea, whether you want to teach number of subjects or just a single one, LMS (Learning Management System) will be created according to your demand. The layout of the app will speak itself that what is it about. Moreover, the overall experience of the app will be so efficient that the students won’t just learn but will also enjoy the knowledge-gaining process. 
"
        page="education"
      />
      <CaseStudiesBanner
        title="E-learning
case studies"
      />
      <IndustriesAcc
        heading="ECommerce web development all your questions answered

"
        content="Find the answers below to the most common questions regarding E-Learning software development or contact us directly.

"
        page="education"
      />
      <GotProject every={true} />
      <Footer contacting={true} />
    </>
  );
};

export default IndustriesEducation;
