import React from "react";
import styles from "./InnerCard.module.css";
export const InnerCard = ({ content, details, subdetails, image, right }) => {
  return (
    <>
      {right === true ? (
        <>
          <div className={styles.inner_card}>
            <p>{content}</p>
            <span className={styles.ratings}>
              <img src={image.rating} alt="" />
            </span>
          </div>
          <div className={` ${styles.testzname} mt-4`}>
            <p>
              {details}
              <span className="text--light">{subdetails}</span>
            </p>
          </div>
        </>
      ) : (
        <>
          <div className={styles.inner_card1}>
            <p>{content}</p>
            <span className={styles.ratings}>
              <img src={image.rating} alt="" />
            </span>
          </div>
          <div className={` ${styles.lefttestzname} mt-4`}>
            <p>
              {details}
              <span className="text--light">{subdetails}</span>
            </p>
          </div>
        </>
      )}
    </>
  );
};
