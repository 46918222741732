import React from "react";
import styles from "./CaseStudyFeature.module.css";

const CaseStudyFeature = ({
  plane,
  imagemain,
  imageplane,
  detail1,
  detail2,
  color,
}) => {
  return (
    <>
      <section
        className={`${styles.casestudy_features} ${
          color === "red"
            ? `${styles.redcolor}`
            : color === "aquacolor"
            ? `${styles.aquacolor}`
            : color === "yellowcolor"
            ? `${styles.yellowcolor}`
            : color === "greencolor"
            ? `${styles.greencolor}`
            : ``
        }`}
      >
        <div className="container">
          <div className={`${styles.feature_thumb}`}>
            <img alt="" src={imagemain} className={`${styles.firsr}`} />
            {plane === true && (
              <img alt="" src={imageplane} className={`${styles.second}`} />
            )}
          </div>
          <h2 className={`font-weight-700 ${styles.feature_heading}`}>
            <span></span> Features
          </h2>
          <p>{detail1}</p>
          <p>{detail2}</p>
          <hr></hr>
        </div>
      </section>
    </>
  );
};

export default CaseStudyFeature;
