import React from "react";
import { Link } from "react-router-dom";

import styles from "./CaseStudies.module.css";
export const CaseStudies = ({
  headingpri,
  headingsec,
  detailspri,
  detailssec,
  linking,
  image,
}) => {
  return (
    <>
      <div className={` ${styles.casestudy_item} `}>
        <div className={styles.casestudy_thumb}>
          <Link to={linking}>
            <img src={image} alt="" />
          </Link>
        </div>

        <div className="row">
          <div className="col-6">
            <div className={`${styles.casestudy_text} mt-4`}>
              <h3 className="font-weight-600 mb-1">{headingpri}</h3>
              <p>{detailspri}</p>
            </div>
          </div>
          <div className="col-6">
            <div className={`${styles.casestudy_text} mt-4`}>
              <h3 className="font-weight-600 mb-1">{headingsec}</h3>
              <p>{detailssec}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
