import React from "react";


import styles from "./AboutTeam.module.css";
export const AboutTeam = ({ image, details, title, main }) => {
  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };
  return (
    <>
      <div className=" col-lg-4 col-sm-6">
        <div className={`${styles.team_box} mt-5`}>
          <div className={styles.team_thumb}>
            <img alt="" src={image} />
          </div>
          <div className={styles.team_text}>
            <h3 className="font-weight-500">
              {title}{" "}
              {main === true && (
                <a
                  href="https://www.linkedin.com/in/muhammad-jamil-ahmed/"
                  onClick={() =>
                    openInNewTab(
                      "https://www.linkedin.com/in/muhammad-jamil-ahmed/"
                    )
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  <div className={`${styles.testimonial_icon}`}>
                    <i className="fa-brands fa-linkedin-in"></i>
                  </div>
                </a>
              )}
            </h3>
            <h4 className="font-weight-400 text--light mt-1">{details}</h4>
          </div>
        </div>
      </div>
    </>
  );
};
