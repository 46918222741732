import React from "react";
import Banner from "../../components/Banner/Banner";

import experty1 from "../../images/experty1.png";
import experty2 from "../../images/experty2.png";
import experty3 from "../../images/experty3.png";
import experty4 from "../../images/experty4.png";
import experty6 from "../../images/experty6.png";
import styles from "./AgileDev.module.css";
import Footer from "../../components/Footer/Footer";
import GotProject from "../../components/Got_Project/GotProject";
import agile from "../../images/agile1.jpg";
import agile_app from "../../images/agile_approach.svg";
import Tabs from "../../components/Tabs/Tabs";
import contact_footer from "../../images/contact-footer.png";
import communication from "../../images/communication.svg";
import scale from "../../images/scale.svg";
import experts from "../../images/experts.svg";
import testing from "../../images/testing.svg";
import manage from "../../images/manage.svg";
import agile_dev from "../../images/agile-dev.png";
import Aboutisoft from "../../components/About/Aboutisoft";
import AgileDevCard from "../../components/AgileDev/AgileDevCard";
import OurApproach from "../../components/Approach/OurApproach";
import Intro from "../../components/AgileDev/Intro";
import work from "../../images/team.jpg";
import { TabTitle } from "../../Utils/GeneralFunction";

const AgileDevelopment = () => {
  TabTitle("Services | Agile Development");
  return (
    <>
      <Banner
        heading="Agile Development Teams On Demand"
        detail="   Know what to expect and get results on time"
        btn_detail="Start a project"
        image={contact_footer}
        image_mobile={agile_dev}
        image_large={agile_dev}
        agile={true}
      />
      <Intro
        heading="At iSoft Technologies"
        detail=" our teams are fluent in agile approaches and bring our culture
                of commitment and innovation to ensure that work gets done on
                time. We believe, that while pure agile can work given the right
                environment and variables, in the real world you need to adapt
                your approach. Our ability to do so allows us to work
                side-by-side with your team members to march in the same
                direction and enable your enterprise to move faster and build
                smarter."
      />

      <section className={`${styles.taber_section}`}>
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className={`${styles.tab_thumb}`}>
                <img alt="" src={agile} />
              </div>
            </div>
            <div className="col-lg-6">
              <h2 className="font-weight-700 mb-4">Tech stack</h2>
              <div className="tech-tabs">
                <ul
                  className={`nav nav-tabs ${styles.agile_nav}`}
                  id="myTab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="One-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#One-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="One-tab-pane"
                      aria-selected="true"
                    >
                      <div className="tech-thumb">
                        <img alt="" src={experty1} />
                      </div>
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="Two-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#Two-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="Two-tab-pane"
                      aria-selected="false"
                    >
                      <div className="tech-thumb">
                        <img alt="" src={experty2} />
                      </div>
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="Three-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#Three-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="Three-tab-pane"
                      aria-selected="false"
                    >
                      <div className="tech-thumb">
                        <img alt="" src={experty3} />
                      </div>
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="Four-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#Four-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="Four-tab-pane"
                      aria-selected="false"
                    >
                      <div className="tech-thumb">
                        <img alt="" src={experty4} />
                      </div>
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="Five-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#Five-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="Five-tab-pane"
                      aria-selected="false"
                    >
                      <div className="tech-thumb">
                        <img alt="" src={experty6} />
                      </div>
                    </button>
                  </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="One-tab-pane"
                    role="tabpanel"
                    aria-labelledby="One-tab"
                    tabIndex="0"
                  >
                    <Tabs
                      heading="React.js"
                      detail=" React.js is the most used web framework among software developers all over the world as it is very intuitive to work with and makes it easier to interact with the layout of any UI. Fast and quality assured applications can be developed with React technology and the fusion of JavaScript and HTML syntax in it automatically simplifies the whole procedure of code writing of the project, therefore, we utilize this awesome technology for creating E-Commerce Apps, Messaging Apps, Tracking Apps and what not. We also deliver solutions in React with powerful user interfaces that provide strong functionality and tremendous visuals for catering the needs of a business.
"
                    />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="Two-tab-pane"
                    role="tabpanel"
                    aria-labelledby="Two-tab"
                    tabIndex="0"
                  >
                    <Tabs
                      heading="Angular.js"
                      detail="Angular.js is another highly famous web development framework that offers fast responsiveness along with rich user experiences. This frontend framework’s Injectors, Components and Directives are a big plus for the product to be successful. Angular’s compatibility with numerous back-end programming languages makes it worth using. Our developers use both of its bundles (tools and resources) for developing modern web browsers which makes the app more productive.
"
                    />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="Three-tab-pane"
                    role="tabpanel"
                    aria-labelledby="Three-tab"
                    tabIndex="0"
                  >
                    <Tabs
                      heading="Vue"
                      detail=" Vue is a JavaScript framework for building user interfaces. It builds on top of standard HTML, CSS and JavaScript, and provides a declarative and component-based programming model that helps you efficiently develop user interfaces, be it simple or complex."
                    />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="Four-tab-pane"
                    role="tabpanel"
                    aria-labelledby="Four-tab"
                    tabIndex="0"
                  >
                    <Tabs
                      heading="ES6"
                      detail="JavaScript ES6 brings new syntax and new awesome features to make your code more modern and more readable. It allows you to write less code and do more. ES6 introduces us to many great features like arrow functions, template strings, class destruction, Modules… and more."
                    />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="Five-tab-pane"
                    role="tabpanel"
                    aria-labelledby="Five-tab"
                    tabIndex="0"
                  >
                    <Tabs
                      heading="Python"
                      detail=" Python is a high-level, general-purpose programming language, used to build websites and software, automate tasks, and conduct data analysis.
"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr className="mb-0"></hr>
        </div>
      </section>
      <section className={`${styles.feature_section}`}>
        <div className="container">
          <div className="row">
            <Aboutisoft
              custom_head={true}
              agile={true}
              everyimage={work}
              cent={true}
              heading=" Explore agile team dynamics, culture & 
                        collaboration and build a great agile team."
              detail="   One of the biggest misconceptions teams make going agile 
                        is the idea that Agile means “Work Without a Plan.” Often, 
                        companies with this philosophy end up confronting a mad race 
                        at the end of the project to hit deadlines and budget. 
                        We believe in creating an entire roadmap; specific enough 
                        to provide visibility into timelines. Through our communication 
                        and reporting department, clients get real-time updates into 
                        the timelines and budget forecasts. You will always know your 
                        project’s status at the level of detail you prefer, and it 
                        will be clear, consistent, and aligned to your needs."
            />
            <AgileDevCard
              heading="Scalable teams"
              detail="    Working in an Agile mindset, our development teams focus on
                  close collaboration and a self-organized team structure. We
                  know that when it comes to projects, the people are often
                  responsible for more trouble than the technology! Our
                  developers are well rounded and can adapt to the situation."
              image={scale}
              large={true}
            />
            <AgileDevCard
              heading="Communicative"
              detail="   Communication is always at the heart of teamwork regardless of
                  the industry, and software development is no exception. In
                  great teams, people have all the necessary tools and processes
                  for regular healthy communication."
              image={communication}
            />
            <AgileDevCard
              heading="Productivity experts"
              detail=" Our teams don’t need strict top-down management. They have
                  clear goals and a shared mission. In such environment, the
                  success of the team is perceived as the success of each
                  individual."
              image={experts}
            />
            <AgileDevCard
              heading="Functional Testing"
              detail="   Our developers know you need results, which you'll see in the
                  first 2 sprints of the Agile process. We provides with the
                  services of testing services."
              image={testing}
            />
            <AgileDevCard
              heading="Well-managed"
              detail="   Our agile development managers have been serving the industry
                  for years. The code quality is main focus of each team member.
                  Our code quality is up to the mark."
              image={manage}
            />
          </div>
        </div>
      </section>

      <OurApproach
        heading="  Why Businesses Choose Us For Agile Development Team?"
        title_main="Our Approach"
        image={agile_app}
        detail1="  At iSoft Technologies, Our approach to project management focuses on both the micro and macro measurements. At any point in the project, we can bring awareness and adjust to changes in scope. We are able to loop in our clients to adjust and approve all aspects of the product with regularly scheduled showcases and user acceptance testing."
        detail2=" We know that our clients expect us to work in an Agile method while sometimes integrating with their existing waterfall processes. This stems from the fact that we view Agile as a mindset, not its different activities."
      />
      <GotProject every={true} />
      <Footer contacting={true} />
    </>
  );
};

export default AgileDevelopment;
