import React from "react";
import { useState } from "react";
import styles from "./IndustriesAcc.module.css";
import {
  ecommerce,
  education,
  healthcare,
  realestate,
  travel,
} from "./IndustriesAccordn";
const IndustriesAcc = ({ heading, content, page }) => {
  const [selected] = useState(
    page === "ecomm"
      ? ecommerce
      : page === "education"
      ? education
      : page === "realestate"
      ? realestate
      : page === "health"
      ? healthcare
      : page === "travel"
      ? travel
      : ""
  );
  return (
    <>
      <section className={` padding-bottom ${styles.some_faqs}`}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 text-center">
              <h2 className="font-weight-700 text-capitalize">{heading}</h2>
              <h4
                className={`font-weight-400 text--web commonline ${styles.somefact_txt}`}
              >
                {content}
              </h4>
            </div>
            <div className="col-lg-12">
              <div className="accordion web-accordions" id="accordionWeb">
                {selected.map((items) => {
                  return (
                    <div key={items.id} className="accordion-item">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#${items.id}`}
                          aria-expanded="false"
                          aria-controls={items.id}
                        >
                          {items.heading}
                        </button>
                      </h2>
                      <div
                        id={items.id}
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionWeb"
                      >
                        <div className="accordion-body">
                          <h4 className="font-weight-400 text--web commonline acctext">
                            {items.content}
                          </h4>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default IndustriesAcc;
