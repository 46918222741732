import React from "react";
import IndustriesBanner from "../../components/Banner/IndustriesBanner";
import CaseStudiesBanner from "../../components/CaseStudy/CaseStudiesBanner";
import MainTextCard from "../../components/Development/MainTextCard";
import Footer from "../../components/Footer/Footer";
import GotProject from "../../components/Got_Project/GotProject";
import IndustriesAcc from "../../components/Industries/IndustriesAcc";
import IndustriesDevs from "../../components/Industries/IndustriesDevs";
import IndustriesExperties from "../../components/Industries/IndustriesExperties";

import ecommimage from "../../images/industry-ecommerece.jpg";
import { TabTitle } from "../../Utils/GeneralFunction";
import styles from "./IndustriesEcommerce.module.css";

const IndustriesEcommerce = () => {
  TabTitle("Industries | Ecommerce");
  return (
    <>
      <IndustriesBanner
        heading="ALL-IN-ONE FINTECH SOFTWARE DEVELOPMENT"
        subheading="E-Commerce App Development Services"
        detail="A top-notch E-Commerce app development company which creates such high quality E-Commerce websites that compel the customers to visit the online store and buy stuff."
        background_image="ecommerce"
        industry_btn={true}
        ind_btn_text=" Request a proposal"
      />
      <MainTextCard
        page="ecomm"
        detail=<div>
          <h2 className="font-weight-700 text-capitalize">
            Scale with our E-Commerce app development services
          </h2>
          <h3 className={`font-weight-400 text--web ${styles.maincontent}`}>
            Shoppers are always looking for such platforms that offer them
            convenience, values their money and give them a great shopping
            experience.
          </h3>
          <h4 className={`font-weight-400 text--web pt-1 ${styles.content}`}>
            We develop state of the art E-Commerce websites which makes the
            customers satisfied and make it simpler for them to buy whatever
            they want to without getting into complex buying options that
            destroy their whole experience. The company will also be able to
            track that how many customers are visiting the app on daily basis
            and which product is liked the most.
          </h4>
          <h4 className={`font-weight-400 text--web pt-1 ${styles.content}`}>
            The customers will also be able to chat with you for their queries
            so that you don’t miss any order just because of any minor
            confusion. The design will be so up to the mark that clear pictures
            of the product along with its description will be shown to the
            customers.
          </h4>
        </div>
      />
      <IndustriesDevs
        title="E-Commerce Software Development Services"
        heading="Solutions for both merchants and consumers"
        imagebig={ecommimage}
        page="ecomm"
        textmain="Get profit from a bespoke eCommerce solution."
        textcontent="Have resource to the services of experienced eCommerce developers at Innowise Group"
      />
      <IndustriesExperties
        title="Our E-Commerce development expertise"
        heading="Our services of E-Commerce development will neither disappoint you nor the customer. The customers will have such an amazing shopping experience through the website developed by iSoft Technologies that they will feel like visiting again and again. Our team is capable of developing advanced look of the website according to the on-going shopping trends. They will make it possible for the customer to select the product, read its description on the page and then order it right away from the same page without visiting several pages. The page loading time will be minimum so that the customers don’t have to wait and you don’t bear any loss. Magnificent performance of the website is guaranteed"
        page="ecomm"
      />
      <CaseStudiesBanner title="eCommerce case studies" />
      <IndustriesAcc
        heading="ECommerce web development all your questions answered
"
        content="Find the answers below to the most common questions regarding E-Commerce app development or contact us directly.
"
        page="ecomm"
      />
      <GotProject every={true} />
      <Footer contacting={true} />
    </>
  );
};

export default IndustriesEcommerce;
