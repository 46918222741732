import React from "react";
import styles from "./AgileDevCard.module.css";
const AgileDevCard = ({ heading, detail, image, large }) => {
  return (
    <>
      {large === true ? (
        <div className="col-lg-12">
          <div className={`${styles.team_feature}`}>
            <span className={`${styles.team_icon}`}>
              <img alt="" src={image} />
            </span>
            <h2 className="font-weight-700 text-capitalize">{heading}</h2>
            <h4 className="font-weight-400 text--web">{detail}</h4>
          </div>
        </div>
      ) : (
        <div className="col-lg-6">
          <div className={`${styles.team_feature}`}>
            <span className={`${styles.team_icon}`}>
              <img alt="" src={image} />
            </span>
            <h2 className="font-weight-700 text-capitalize">{heading}</h2>
            <h4 className="font-weight-400 text--web">{detail}</h4>
          </div>
        </div>
      )}
    </>
  );
};

export default AgileDevCard;
