import React from "react";
import vision from "../../images/vision.svg";
import career from "../../images/team.jpg";
import vectors from "../../images/vectors.png";
import education from "../../images/educations.svg";
import mern from "../../images/mearn.svg";
import cup from "../../images/cup.svg";
import code from "../../images/code.png";
import clock from "../../images/clock.svg";
import remote_work from "../../images/remotework.svg";
import mission from "../../images/mission.svg";
import mac from "../../images/mac.svg";
import calendar from "../../images/calender.svg";
import game from "../../images/game.svg";
import building from "../../images/building.svg";
import Office from "../../components/Office/Office";
import { CareerVision } from "../../components/Career/CareerVision";
import { CareerTech } from "../../components/Career/CareerTech";
import GotProject from "../../components/Got_Project/GotProject";
import { PositionCard } from "../../components/Career/PositionCard";
import { BenefitCard } from "../../components/Career/BenefitCard";
import styles from "./careerpage.module.css";
import { OfficeDetail } from "../../components/Office/OfficeDetail";
import Footer from "../../components/Footer/Footer";
import { TabTitle } from "../../Utils/GeneralFunction";
const Career = () => {
  TabTitle("iSoft Technologies - Career");
  return (
    <>
      <section className={`${styles.career_banner}`}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12 text-center">
              <div className={`text-capitalize ${styles.pagecontent}`}>
                <h1>Making Work More HumanTakes The Right People.</h1>
                <h4
                  className={`text--gradient text-capitalize ${styles.pagetitle} font-weight-700`}
                >
                  Our employees empower us to do better!
                </h4>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className={`${styles.vision_section}`}>
        <div className="container">
          <div className="row">
            <CareerVision
              image={mission}
              title="Our mission"
              detail=" ISoft Technologies fundamentals are work built on solid
                  expertise, strong corporate culture & unity of people ready to
                  contribute value. We aim to establish professional and
                  fruitful corporation becoming reliable partners for our
                  clients."
            />
            <CareerVision
              image={vision}
              title="Our Vision"
              detail="  We have a passion for our work. This passion is inherit in
                  everything we do and breeds success in the execution of both
                  customers and corporate management."
            />
          </div>
        </div>
      </section>
      <section className={`${styles.career_reasons}`}>
        <div className="container">
          <div className="row">
            <div className={`col-lg-12 ${styles.reason_heading}`}>
              <h2 className="font-weight-700">
                Why Work At Isoft Technologies?
              </h2>
              <h4 className="font-weight-400 text--web">
                Due to the dynamic nature of our company, new projects and
                exciting opportunities emerge every day. For your career
                ambitions, personal development, and growth, we provide the
                comprehensive support of top managers and peers. You’ll be able
                to try new ideas and contribute through the strongest skills you
                possess.
              </h4>
              <div className={`${styles.work_thumb}`}>
                <img alt="" src={career} />
                <span className={`${styles.vectors}`}>
                  <img alt="" src={vectors} />
                </span>
              </div>
            </div>
            <div className="col-xl-5 col-md-6">
              <CareerTech
                title="   Contribute to product development"
                detail="   Due to the dynamic nature of our company, new projects and
                  exciting opportunities emerge every day. For your career
                  ambitions, personal development, and growth, we provide the
                  comprehensive support of top managers and peers. You’ll be
                  able to try new ideas and contribute through the strongest
                  skills you possess."
              />
            </div>
            <div className="col-xl-5 offset-xl-2 col-md-6">
              <CareerTech
                title="Study to grow and develop"
                detail="       We provide our employees with meaningful opportunities to
                  contribute, make their ideas a reality, study for personal and
                  career growth. Our employees are inspired to reach new
                  objectives and become more proficient in what they do.
                  Specialized courses and workshops allow for fast career
                  growth."
              />
            </div>
            <div className="col-xl-5 col-md-6">
              <CareerTech
                title=" Collaborate with like-minded people"
                detail=" It’s amazing to be amongst like-minded people. We’re committed to connecting people having similar ideas, thoughts, and opinions. Yet, we value freedom in expressing your ideas. We value creativity, strong skills, and extensive experience in information technology."
              />
            </div>
            <div className="col-xl-5 offset-xl-2 col-md-6">
              <CareerTech
                title="Work to get appraised"
                detail="     We highly appreciate your dedication and passion to work.
                  You’ll get special rewards for overtime, enthusiasm, and a
                  valuable contribution to mutual work. You will be able to
                  discuss the ways you could be helpful in project development,
                  business processes management, or leadership."
              />
            </div>
          </div>
          <hr className="mb-0"></hr>
        </div>
      </section>
      <section className={`${styles.current_positions}`}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="text-center">
                <p className="text--web b-1">
                  See currently open positions. We're looking forward meeting
                  you!
                </p>
                <h2 className="font-weight-700 text-capitalize">
                  Open Positions
                </h2>
              </div>
              <PositionCard
                image={mern}
                heading="  MERN technical lead"
                detail="     Senior positions in Pakistan"
                btn=" Learn more "
                linking="/career/mern-stack-developer-job-details"
              />
              <PositionCard
                image={code}
                heading="Senior Full Stack developer"
                detail=" Senior positions in Pakistan"
                btn=" Learn more "
                linking="/career/full-stack-developer-job-details"
              />
            </div>
          </div>
        </div>
      </section>
      <section className={`${styles.career_perks}`}>
        <div className="container">
          <h2 className="font-weight-700 text-center text-capitalize">
            Perks And Benefits
          </h2>
          <div className={`row ${styles.perks_wrapp}`}>
            <BenefitCard
              image={clock}
              title="Flexible hours"
              detail="We work from 10 AM - 7 PM (UTC+05:00 hours). In a week, you just have to complete your 8 working hours for 5 days (Monday-Friday) each. A break of one hour i.e. 
2 PM - 3 PM for lunch is also given. "
            />
            <BenefitCard
              image={education}
              title="Education & Literature"
              detail=" Fruitful opportunities like special courses and workshops are provided which are truly helpful in making one grow in his/her tech field rapidly."
            />
            <BenefitCard
              image={cup}
              title="Snack and coffee"
              detail="Most loved beverages like coffee and tea can be consumed in the office as a refreshment twice a day with snacks."
            />
            <BenefitCard
              image={mac}
              title="Macbook and tech"
              detail="  High-quality and efficient technical equipment and Macbooks are given to you so that you work smoothly and effectively while enjoying the experience of 
top-notch technology."
            />
            <BenefitCard
              image={remote_work}
              title="Work from home"
              detail=" Are you feeling sick or have some urgent piece of work at home but don’t want to see an “absent” or “leave” mark on your attendance portal? There is nothing to worry about, just take your system along with you a day before and then work from home just like you do in the office. Complete your working hours, coordinate with your team and you are good to go."
            />
            <BenefitCard
              image={building}
              title="Spacious Office"
              detail="  A motivating and spacious office environment is available for you so that you sit comfortably and fulfill your tasks wholeheartedly while being satisfied with your surroundings."
            />

            <BenefitCard
              image={game}
              title="Table tennis"
              detail="  It is the break-time, you are done with your lunch and feeling bored? Well, just let the boredom vanish and play table tennis with your peers for enhancing your bond with them and spend some joyful time. "
            />

            <BenefitCard
              image={calendar}
              title="Vacation & Maternity Leave"
              detail="32 holidays are given annually in total. 10 are sick leaves, 12 are casual leaves and again 10 are annual ones. But annual leaves are only given once you have completed your whole year in the company. On the other hand, we really respect the feelings of a mother and the tough time she goes through during her maternity phase, so maternity leaves are also provided to the female employees."
            />
          </div>
        </div>
      </section>
      <section className="our-office">
        <div className="container">
          <div className="office-container bg--gradient text-white text-center">
            <OfficeDetail
              title="Our Office"
              details="We're incredibly lucky to workwith partners from a wide spectrum of industries who shre our enthusiasm and commitment towards delivering results."
            />
          </div>
        </div>

        <Office aboutgal={true} />
      </section>

      <div className={`${styles.office}`}></div>
      <GotProject every={true} />
      <Footer contacting={true} />
    </>
  );
};

export default Career;
