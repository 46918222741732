import React from "react";
import GotProject from "../../components/Got_Project/GotProject";
import Footer from "../../components/Footer/Footer";

import edu_industry from "../../images/web2.png";
import ecommerece from "../../images/web1.png";
import ecommerece1 from "../../images/web4.png";

import Banner from "../../components/Banner/Banner";
import uiux from "../../images/web3.png";
import Devlopment from "../../components/Development/Devlopment";
import contact_footer from "../../images/contact-footer.png";
import web_dev from "../../images/banner-webdev.png";
import MainTextCard from "../../components/Development/MainTextCard";
import TechStackCard from "../../components/TechStack/TechStackCard";
import { TabTitle } from "../../Utils/GeneralFunction";

const WebDevlopment = () => {
  TabTitle("Services | Web Development");
  return (
    <>
      <Banner
        heading="  Web Development"
        detail="  Consistent Experience Across Platforms "
        btn_detail=" Start a project"
        image={contact_footer}
        image_mobile={web_dev}
        image_large={web_dev}
      />
      <MainTextCard
        heading="We build applications that work for your business and your users."
        detail="Whether it’s a complex enterprise platform and backend database, a responsive web app, or a marketing site, our web solutions function smoothly and deliver a consistent experience to users across all platforms. Leveraging modern approaches to web development, we bring front-end, back-end, and architecture together to meet your business needs."
      />
      <TechStackCard web={true} />
      <Devlopment
        linking=""
        services={true}
        no="01."
        title="Web Development Strategy"
        heading="   Planning for the Future State of Your Business"
        detail=" A successful web development strategy looks at not only what
                your business’s technology footprint looks like today, but what
                you’d like it to look like in a future state as well. Our
                solutions architects and web development teams work alongside
                you early on in your engagement to pinpoint exactly how you
                expect your business to evolve. Then, we strategically lay out a
                roadmap and identify what role technology plays in achieving
                your business objectives."
        image={ecommerece}
        industry={true}
      />

      <Devlopment
        linking=""
        services={true}
        no="02."
        title=" Back-End Development"
        detail="With the latest high-performance solutions and technical tools, we build custom web application development services to solve your business tasks. The back-end of your web solution is everything that happens behind the scenes. While not visible, it’s arguably the most critical piece of the software puzzle. If not developed properly, the entire system won’t function smoothly. Highly-skilled in all modern back-end technologies and languages, our team develops innovative web solutions that run exactly as intended. And in some cases, better! If we encounter an issue during development or have an idea to improve your solution, we won’t be shy. We’ll let you know the best possible way to solve the problem."
        image={edu_industry}
        heading="The Invisible Force Behind Your Product"
      />
      <Devlopment
        services={true}
        linking=""
        no="03."
        title="Front-End Development"
        heading=" Now Playing: A Seamless Front-End Experience"
        detail="  We as a custom web development company help you with standing
                out from the competition. We develop accessible and semantic
                interfaces with the most useful features for your website. If
                back-end is everything that happens behind the scenes, front-end
                is what happens on stage. A successful front-end balances
                thoughtful design and seamless functionality to create a smooth
                experience when users view and interact with your product. Our
                front-end development team knows how to deliver exactly that.
                Well-versed in the best practices of all modern front-end tools
                and platforms, we work smartly and efficiently to deliver a
                world-className=front-end experience to your users."
        image={ecommerece1}
        industry={true}
      />
      <Devlopment
        linking=""
        no="04."
        services={true}
        heading="  Meaningful design to your digital solution"
        title="UX/UI Design"
        detail="Meaningful design to your digital solution
Our web application development company designs websites, prototype etc. We effectively work out not only the user experience but the technical aspect of the product as well. An impactful application needs an innovative and interactive user interface design across all your digital channels. Our experts help create the right website for you across all major platforms and devices. Taking into consideration your user behavior and volume to promote the optimal experience, we design responsive, performance-oriented and growth-driven applications that provide you with a better result, eliminating the necessity for a full-scale redesign and website downtime.
"
        approach={true}
        image={uiux}
      />

      <GotProject every={true} />
      <Footer contacting={true} />
    </>
  );
};

export default WebDevlopment;
