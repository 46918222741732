import React from "react";

import styles from "./CareerContent.module.css";
const CareerDetailContent = ({ title, detail1, detail2, details }) => {
  return (
    <>
      <div
        className={details === true ? `` : `${styles.careerform_text_section}`}
      >
        <h3 className="font-weight-500">{title}</h3>
      </div>
      {details === true && (
        <>
          <h4
            className={`font-weight-400 text--web commonline ${styles.commonline_margin}`}
          >
            {detail1}
          </h4>
          <h4
            className={`font-weight-400 text--web commonline ${styles.commonline_margin}`}
          >
            {detail2}
          </h4>
        </>
      )}
    </>
  );
};

export default CareerDetailContent;
