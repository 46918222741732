import React, { useState } from "react";
import styles from "./ApplyForm.module.css";
import { useForm } from "react-hook-form";
import emailjs from "@emailjs/browser";
import sendimage from "../../images/check.png";
import errorimage from "../../images/cross.png";
import { useRef } from "react";
const ApplyForm = () => {
  const form = useRef();

  const [statusMessage, setStatusMessage] = useState("");

  const sendEmail = () => {
    emailjs
      .sendForm(
        "service_g23ejtd",
        "template_kisl90i",
        form.current,
        "wcYZLEqqRwdVFLlcY"
      )
      .then(
        () => {
          setStatusMessage("yes");
          setTimeout(() => {
            setStatusMessage(null);
          }, 10000);
        },
        () => {
          setStatusMessage("no");
          setTimeout(() => {
            setStatusMessage(null);
          }, 10000);
        }
      );
  };
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const finalsubmit = (datas, e) => {
    sendEmail(datas);
    reset();
  };
  const resetmsg = () => {
    setStatusMessage(null);
  };
  return (
    <>
      <div className={`${styles.apply_career}`}>
        <h2 className="font-weight-700">Apply Now</h2>
        <h4 className="text--web font-weight-300">
          Custom Solutions for companies and individuals. Fill in our short
          form. It will only take a minute.
        </h4>
        <form
          ref={form}
          onSubmit={handleSubmit(finalsubmit)}
          className="form-career"
        >
          <div className="form-group mb-3">
            <label htmlFor="carName" className="form-label">
              Full Name*
            </label>
            <input
              type="text"
              name="user_name"
              onClickCapture={resetmsg}
              className={`form-control ${
                errors.user_name ? `form-control-required` : ``
              }`}
              {...register("user_name", {
                required: true,
              })}
            />
            {errors.user_name && (
              <span className="validate-msg">Name is required</span>
            )}
          </div>
          <div className="form-group mb-3">
            <label htmlFor="carMail" className="form-label">
              Email*
            </label>
            <input
              type="email"
              name="user_email"
              onClickCapture={resetmsg}
              {...register("user_email", {
                required: true,
                pattern:
                  /^(([^<>()\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              })}
              className={`form-control ${
                errors.user_email ? `form-control-required` : ``
              }`}
            />
            {errors.user_email && (
              <span className="validate-msg">Check your email address</span>
            )}
          </div>
          <div className="form-group mb-3">
            <label htmlFor="carLink" className="form-label">
              Linkedin Profile Link*
            </label>
            <input
              type="url"
              name="user_profile"
              onClickCapture={resetmsg}
              {...register("user_profile", {
                required: true,
                pattern:
                  /(https?)?:?(\/\/)?(([w]{3}||\w\w)\.)?linkedin.com(\w+:{0,1}\w*@)?(\S+)(:([0-9])+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/,
              })}
              className={`form-control ${
                errors.user_profile ? `form-control-required` : ``
              }`}
            />
            {errors.user_profile && (
              <span className="validate-msg">
                Check your linkedIn profile address
              </span>
            )}
          </div>
          <div className="form-group mb-3">
            <label htmlFor="carUpload" className="form-label">
              Upload Resume
            </label>
            <input
              type="file"
              name="user_resume"
              onClickCapture={resetmsg}
              {...register("user_resume", {
                required: true,
              })}
              className={`form-control ${
                errors.user_resume ? `form-control-required` : ``
              }`}
            />
            {errors.user_resume && (
              <span className="validate-msg">Please select file</span>
            )}
          </div>
          <div className="succes-mail-msg">
            <p>
              {statusMessage === "yes" && (
                <div>
                  <img alt="" className="message-image" src={sendimage} />
                  <span className={`${styles.message_text}`}>
                    Your mail has been submitted!
                  </span>
                </div>
              )}
              {statusMessage === "no" && (
                <div>
                  <img alt="" className="message-image" src={errorimage} />
                  <span className={`${styles.message_text}`}>
                    Your mail has not sent.
                  </span>
                </div>
              )}
            </p>
          </div>
          <div className="text-center">
            <button
              value="Send"
              type="submit"
              className="btn--gradient btn-apply"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default ApplyForm;
