import React, { useCallback, useRef } from "react";
import tab1 from "../../images/aboutS.jpg";
import location from "../../images/location.svg";
import team1 from "../../images/team1New.png";
import team2 from "../../images/team2New.png";
import team3 from "../../images/team3New.png";
import team4 from "../../images/team4New.png";
import team5 from "../../images/team5.png";
import team6 from "../../images/team6New.png";

import Office from "../../components/Office/Office";
import Testimonials from "../../components/Testimonials/Testimonials";
import testimonials1 from "../../images/testimonial1.png";

import Explore from "../../components/Explrore/Explore";
import SwiperCore, { EffectFade } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { AboutCard } from "../../components/About/AboutCard";
import { AboutTabsCard } from "../../components/About/AboutTabsCard";
import { LocationCard } from "../../components/About/LocationCard";
import { AboutClientCard } from "../../components/About/AboutClientCard";
import { AboutTeam } from "../../components/About/AboutTeam";
import styles from "./aboutpage.module.css";
import Expert from "../../components/Expert/Expert";
import { OfficeDetail } from "../../components/Office/OfficeDetail";
import { Link } from "react-router-dom";
import AboutTabsDetail from "../../components/About/AboutTabsDetail";
import GotProject from "../../components/Got_Project/GotProject";
import Footer from "../../components/Footer/Footer";
import { TabTitle } from "../../Utils/GeneralFunction";

const About = () => {
  SwiperCore.use([EffectFade]);
  const sliderRef = useRef(null);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);
  TabTitle("iSoft Technologies - About Us");
  return (
    <>
      <section className={`${styles.about_banner}`}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className={`${styles.pagecontent}`}>
                <h4
                  className={`text--gradient-sm ${styles.pagetitle} font-weight-700`}
                >
                  About us
                </h4>
                <h1>
                  iSoft Technologies is a software development company providing
                  digital solutions and quality services to clients from all
                  over the world since last 11+ years.
                </h1>
                <h4 className="font-weight-400 text--light">
                  In a quite short period of time, our products and custom
                  applications have served more than 150 customers across 10
                  countries. We are expanded in 2 states of globe, therefore,
                  have direct presence in United States of America along with
                  Pakistan. We aren’t only linked with the customers of world’s
                  renowned corporations, but also with some of the
                  fastest-growing outset companies.
                </h4>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={`${styles.who_we}`}>
        <div className="container">
          <div className={`row ${styles.whowe_wrapper}`}>
            <div className={`col-lg-6 ${styles.weintro} bg--gradient`}>
              <h2 className="font-weight-700">Who we are</h2>
              <h4>
                ISoft Technologies fundamentals are work built on solid
                expertise, strong corporate culture & unity of people ready to
                contribute value.
              </h4>
              <h4>
                We aim to establish professional and fruitful corporation
                becoming reliable partners for our clients.
              </h4>
              <Link
                className={`btn--white btn-whower ${styles.btn_disabled}`}
                to="/contact-us"
              >
                <span className="text-capitalize">Schedule a meeting</span>
              </Link>
            </div>
            <div className={`col-lg-6 ${styles.wehave}`}>
              <AboutCard
                title="       OUR TEAM"
                subtitle="  World ranked front end and backend developers, project
                    managers, premium business analyst and marketers, UX/UI
                    designers and more."
                detail="40"
                subdetail="qualified experts"
                add={true}
                upto="40"
              />
              <AboutCard
                title="  OUR STORY"
                subtitle="  Founded in 2011, iSoft Technologies delivered more than 500+
                    projects for worldwide clients, continuously growing and
                    improving with our clients and experts."
                detail="11"
                subdetail="years of experience"
                upto="11"
              />
              <AboutCard
                title="  OUR DEVELOPMENT CENTERS"
                subtitle="   ISoft Technologies expanded in two of the continents of the
                    globe i.e. Asia and North America. After growing to 50+
                    employee size, our entire team prides themselves on open and
                    honest communication."
                detail="02"
                upto="2"
                subdetail="development centers"
              />
            </div>
          </div>
        </div>
      </section>

      <section className={`${styles.our_corevalues}`}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-11">
              <h2 className="font-weight-700 text-center text-capitalize mb-5">
                Our core values define us. Our values guide us in creating a
                better experience for our clients.
              </h2>
            </div>
          </div>
          <div className="web-tabs">
            <ul className="nav nav-tabs" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="teams-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#teams-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="teams-tab-pane"
                  aria-selected="true"
                >
                  <AboutTabsCard title="Team" detail="01" />
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="Passions-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#Passions-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="Passions-tab-pane"
                  aria-selected="false"
                >
                  <AboutTabsCard title="Passion" detail="02" />
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="Commitment-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#Commitment-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="Commitment-tab-pane"
                  aria-selected="false"
                >
                  <AboutTabsCard title="Commitment" detail="03" />
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="Integrity-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#Integrity-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="Integrity-tab-pane"
                  aria-selected="false"
                >
                  <AboutTabsCard title="Integrity" detail="04" />
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="Customer-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#Customer-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="Customer-tab-pane"
                  aria-selected="false"
                >
                  <AboutTabsCard title="Customer" detail="05" />
                </button>
              </li>
            </ul>
            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="teams-tab-pane"
                role="tabpanel"
                aria-labelledby="teams-tab"
                tabIndex="0"
              >
                <AboutTabsDetail
                  image={tab1}
                  heading="Team"
                  details="   Our team is the key to our success. We provide education, training sessions & opportunities to ensure that our employees are engaged and stay in contact with the rapid advancement of technology."
                />
              </div>
              <div
                className="tab-pane fade"
                id="Passions-tab-pane"
                role="tabpanel"
                aria-labelledby="Passions-tab"
                tabIndex="0"
              >
                <AboutTabsDetail
                  image={tab1}
                  heading="Passion"
                  details="    We have a passion for our work. This passion is inherit 
                                        in everything we do and breeds success in the execution of 
                                        both customers and corporate management. "
                />
              </div>
              <div
                className="tab-pane fade"
                id="Commitment-tab-pane"
                role="tabpanel"
                aria-labelledby="Commitment-tab"
                tabIndex="0"
              >
                <AboutTabsDetail
                  image={tab1}
                  heading="Commitment"
                  details=" We are committed to all aspects of our business to deliver 
                                        a consistently high level of support to our customers"
                />
              </div>
              <div
                className="tab-pane fade"
                id="Integrity-tab-pane"
                role="tabpanel"
                aria-labelledby="Integrity-tab"
                tabIndex="0"
              >
                <AboutTabsDetail
                  image={tab1}
                  heading="Integrity"
                  details="We adhere to a strict code of truthfulness and honesty in all business interactions to ensure consistency in our values, actions, expectations and customers."
                />
              </div>
              <div
                className="tab-pane fade"
                id="Customer-tab-pane"
                role="tabpanel"
                aria-labelledby="Customer-tab"
                tabIndex="0"
              >
                <AboutTabsDetail
                  image={tab1}
                  heading="Customer"
                  details="    Customer satisfaction is the guiding focus for execution 
                                        across all of our programs and is paramount to the continued 
                                        success and growth for our company."
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={`${styles.client_world}`}>
        <div className="container">
          <div className={`${styles.clients_wrapp}`}>
            <div className="row align-items-center">
              <div className="col-xl-7  col-lg-6">
                <div className={`${styles.client_country}`}>
                  <h2>
                    We've been working with amazing clients throughout the world
                  </h2>
                  <div className="row">
                    <LocationCard image={location} title="United States" />
                    <LocationCard image={location} title="United Kingdom" />
                    <LocationCard image={location} title="Canada" />
                    <LocationCard image={location} title="Australia" />
                  </div>
                </div>
              </div>
              <div className="col-xl-5 col-lg-6">
                <div className="swiper clientsSlider">
                  <div className="swiper-wrapper">
                    <div className="swiper-slide">
                      <div className="single-client">
                        <Swiper
                          ref={sliderRef}
                          loop={true}
                          effect={"fade"}
                          fadeEffect={{ crossFade: true }}
                        >
                          <SwiperSlide>
                            <AboutClientCard
                              image={testimonials1}
                              title="Adam Marcin"
                              details="Outstanding"
                              subdetails="service,"
                              info="Founder & CEO"
                              strongnext="super professional, service"
                              text="    oriented and great communication!"
                            />
                          </SwiperSlide>
                          {/* <SwiperSlide>
                            <AboutClientCard
                              image={testimonials3}
                              title="Lee Chang"
                              details="We produce quality code"
                              subdetails="     and are effective in their use of time."
                              info="Founder & CEO"
                            />
                          </SwiperSlide> */}
                        </Swiper>
                      </div>
                    </div>
                  </div>

                  <div className="client-navigation">
                    <div
                      className="swiper-button-next"
                      onClick={handleNext}
                    ></div>
                    <div
                      className="swiper-button-prev"
                      onClick={handlePrev}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-5 col-lg-6">
              <div className={`${styles.clients_box}`}>
                <h4 className="font-weight-700 text--gradient-sm">
                  How we work
                </h4>
                <p>
                  To deliver first-class software products, we build the world’s
                  finest dev teams that enable organizations, enterprises and
                  companies to grow faster with the right talents by their side.
                </p>
                <Link className="btn-gradient-border" to="/contact-us">
                  <span className="text-capitalize">Schedule a meeting</span>
                </Link>
              </div>
            </div>
            <div className="col-xl-5 offset-xl-2 col-lg-6">
              <div className={`${styles.clients_box}`}>
                <h4 className="font-weight-700 text--gradient-sm">
                  Who we work with
                </h4>
                <p>
                  Our innovative software solutions tackle specific tech
                  challenges across several industries such as Ecommerce, Travel
                  & Tourism and Education.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Explore />

      <div className="web-cta padding-top">
        <div className="container">
          <Expert />
        </div>
      </div>

      <section className={`${styles.our_teams}`}>
        <div className="container">
          <h2 className={`font-weight-700 ${styles.teams_title} text-center`}>
            Meet The Management Our Patrons - Your Partners
          </h2>

          <div className="row">
            <AboutTeam
              main={true}
              image={team1}
              title="M. Jamil Ahmed"
              details="      CEO & Founder"
            />

            <AboutTeam
              image={team2}
              title="Osman Khan"
              details="Head of Project Management"
            />
            <AboutTeam
              image={team3}
              title="Shahbaz Khokhar"
              details="Head of Marketing"
            />

            <AboutTeam
              image={team4}
              title="Kinza Saleem"
              details="Head of HR"
            />

            <AboutTeam
              image={team5}
              title="Ali Raza"
              details="Technical Lead"
            />
            <AboutTeam
              image={team6}
              title="Fayyaz Nadir"
              details="Head of UI/UX"
            />
          </div>
        </div>
      </section>
      <section className="our-office">
        <div className="container">
          <div className="office-container  text-white text-center">
            <OfficeDetail
              title="Our Office"
              details="We're incredibly lucky to workwith partners from a wide spectrum of industries who shre our enthusiasm and commitment towards delivering results."
            />
          </div>
        </div>

        <Office aboutgal={true} />
      </section>
      <Testimonials />
      <GotProject every={true} />
      <Footer contacting={true} />
    </>
  );
};

export default About;
