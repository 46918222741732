import React from "react";
import case_Green from "../../images/case-study-green.png";

import solution1 from "../../images/solutions1.png";
import yabee from "../../images/yabee.jpg";
import { CaseStudyCard } from "../../components/CaseStudy/CaseStudyCard";

import { TechStackCard } from "../../components/CaseStudy/TechStackCard";
import styles from "./GreenCase.module.css";
import { OfficeDetail } from "../../components/Office/OfficeDetail";
import Office from "../../components/Office/Office";
import GotProject from "../../components/Got_Project/GotProject";
import Footer from "../../components/Footer/Footer";
import { TabTitle } from "../../Utils/GeneralFunction";

import CaseStudyBanner from "../../components/CaseStudy/CaseStudyBanner";
import CaseStudyQuote from "../../components/CaseStudy/CaseStudyQuote";
import CaseStudySummary from "../../components/CaseStudy/CaseStudySummary";
import CaseStudySolution from "../../components/CaseStudy/CaseStudySolution";
import CaseStudyFeature from "../../components/CaseStudy/CaseStudyFeature";
const GreenCase = () => {
  TabTitle("Our Work | Yabee");

  return (
    <>
      <div className="aquacolor">
        <CaseStudyBanner
          color="aquacolor"
          title="  Yabee.me is among the multi-vendor marketplaces that are
                    powered by Laravel."
          content="It transforms Yabee.me website into a marketplace. Through a
                    dedicated dashboard, vendors can create, update and track
                    their products and orders."
          customer="Yabee.me"
          customer_site=" Yabee.me"
          linking="https://yabee.me/"
          images={case_Green}
        />

        <section className={` ${styles.casestudy_columns} position-relative`}>
          <div className={`${styles.box_design} container`}>
            <div className="row">
              <div className={`col-lg-6 ${styles.casestudy_intro}`}>
                <CaseStudyCard
                  heading="Business Need"
                  details="iSoft Technologies creates websites with complete dedication and gives special importance to the demands of clients. The product delivered by our team always showcases the quality standards and reliability of our services because each and every phase of the development procedure is carried out by keeping the needs of the particular business in mind."
                />
              </div>
              <div className={`col-lg-6 ${styles.casestudy_intro}`}>
                <CaseStudyCard
                  heading="Results"
                  details="You will be amazed to see how we have transformed your thoughts into a reality. A well-planned website will be delivered to you which will have a spectacular design along with up to the mark performance ability that will cater your long-term business objectives. Our company’s services never give our clients a chance to doubt its capabilities."
                />
              </div>
            </div>
          </div>
        </section>
        <CaseStudyQuote color="aquacolor" />

        <section className={`${styles.casestudy_summary}`}>
          <div className="container">
            <div className="row">
              <CaseStudySummary
                color="aquacolor"
                summary={true}
                heading="Summary"
                detail1="Yabee.me is among the multi-vendor marketplaces that are powered by Laravel. It transforms Yabee.me website into a marketplace. Through a dedicated dashboard, vendors can create, update and track their products and orders. As the admin, you can set global and individual commissions. Yabee.me is completely customizable according to the need of the business.

"
                detail2="  Yabee.me preserves your brand identity, especially when a vendor don’t have a separate website. If a vendor just starting out in ecommerce and want to build awareness about their products and brand, Yabee.me gives you the flexibility to cultivate and nurture a loyal customer base as the brand grow."
                detail3=" Yabee.me provides the platform for vendors that can sell to anybody, anywhere, and through multiple channels. Everything is controlled from a single dashboard and it facilitates with customize pricing for advanced users."
              />

              <CaseStudySummary color="aquacolor" location={true} />
              <CaseStudySummary
                challenge={true}
                color="aquacolor"
                content=<div
                  className={`${styles.recolor} ${styles.aquacolor}`}
                >
                  <h3 className={`${styles.highlighted_txt}`}>
                    Creating an online marketplace is about more than eye
                    catching design. For{" "}
                    <span> good ecommerce development </span>
                    which allows us to really optimize the results of the store.
                  </h3>
                  <h3 className={`${styles.highlighted_txt}`}>
                    <span>ISoft Technologies</span> faced two of the main
                    challenges while <span>designing & developing.</span> ISoft
                    technologies provided facility to <span>Yabee.me</span> to
                    create dynamic variants and clients customize plans
                  </h3>
                  <h3 className={`${styles.highlighted_txt}`}>
                    The term <span>‘variant’</span> is nothing but a set of
                    static values stored for the parameters of a report. Dynamic
                    variants are more like same, however some of the
                    <span>
                      {" "}
                      parameter values are being calculated dynamically.
                    </span>
                  </h3>
                  <h3 className={`${styles.highlighted_txt}`}>
                    The second main challenge was{" "}
                    <span>regarding customize plans.</span>
                    To provide pricing plans, customize features, allowing and
                    <span> setting up permissions</span> according to customize
                    pricing plan was the challenge faced by{" "}
                    <span>iSoft technologies.</span>
                  </h3>
                </div>
              />
            </div>
          </div>
        </section>

        <CaseStudySolution
          color="aquacolor"
          image={solution1}
          detail1=" iSoft Technology provided the facility that client can add their respective variants according to their business needs to overcome the challenge of dynamic variants. We provided the facility to create combinations of variants."
          detail2="Moreover, iSoft technologies provided with the facility to create their own respective customize plan where they can add price either monthly or yearly. ISoft technologies provided with the feature list from where user can link features to the plan from that list."
        />
        <CaseStudyFeature
          color="aquacolor"
          plane={true}
          imagemain={yabee}
          detail1="Yabee.me is a multi-vendor market place solution by iSoft Technologies is a suitable option for entrepreneurs/vendors looking to build a multi-vendor online market place. This would enable the coming together of vendors on a common platform to market their products, showcase their vendor profile, manage orders from their dedicated vendor dashboard and view sale report."
          detail2="The multi-vendor marketplace solutions for Yabee.me offers a multitude features for transforming website into market place, online payments, advanced analytics, unlimited products, customize domain link, delivery integrations and discounted code. Yabee.me also provides feature to add various users on a single account and can also register various store outlets of the same client on different locations."
        />
        <section className={`${styles.our_techs}`}>
          <div className="container">
            <h2 className="font-weight-700">Tech Stack</h2>
            <div className="row">
              <TechStackCard
                color="aquacolor"
                title="Laravel"
                detail="Laravel is one of the most popular PHP backend frameworks as its powerful built-in system makes it simpler to 
 "
              />
              <TechStackCard
                title="Vue.js"
                detail="Vue is a JavaScript framework for building user interfaces. It builds on top of standard HTML, CSS and Javascript."
                color="aquacolor"
              />
              <TechStackCard
                title="React.js"
                detail="React.js is the most used web framework among software developers all over the world as it is"
                color="aquacolor"
              />
            </div>
          </div>
        </section>
        <section className="our-office margin-bottom">
          <div className="container">
            <div className="office-container text-white text-center">
              <OfficeDetail
                title="Gallery of Project"
                details="We're incredibly lucky to workwith partners from a wide spectrum of industries who shre our enthusiasm and commitment towards delivering results."
              />
            </div>
          </div>
          <div className="office-swiper">
            {" "}
            <Office study={true} greencase={true} />
          </div>
        </section>
      </div>
      <GotProject every={true} />
      <Footer contacting={true} />
    </>
  );
};

export default GreenCase;
