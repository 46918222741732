import React from "react";
import styles from "./CaseStudiesBanner.module.css";
import arrow_r from "../../images/arrow-r.png";
import casestudy_1 from "../../images/casestudy1.jpg";
import casestudy_2 from "../../images/casestudy2.jpg";
import { CaseStudies } from "../../components/HomeScreen/CaseStudies";
import { Link } from "react-router-dom";
const CaseStudiesBanner = ({ title }) => {
  return (
    <>
      <section className={styles.casestudies}>
        <div className="container">
          <div className={styles.casestudy_title}>
            <h2 className="font-weight-700 text-capitalize">{title}</h2>
            <Link
              className={`text--gradient  ${styles.more_cases}`}
              to="/our-work"
            >
              See more <img alt="" src={arrow_r} />
            </Link>
          </div>
          <div className="row  casestudy-wrapp">
            <div className="col-lg-6">
              <CaseStudies
                headingpri="  Universal Visas"
                detailspri="  Universal Visas is a UK based visa &
                                        passport services providing company."
                image={casestudy_1}
                headingsec="Keywords"
                detailssec="     Travel | UX/UI | Development | Integrations | Technologies | Universal Visas"
                linking="/our-work/universal-visas"
              />
            </div>

            <div className="col-lg-6">
              <CaseStudies
                headingpri=" Yabee.me"
                detailspri="   Yabee.me is among the multi-vendor marketplaces
                                        that are powered by Laravel."
                image={casestudy_2}
                headingsec="Keywords"
                detailssec="    Ecommerce | Web Design | Cloud Technology | Enterprise Organization"
                linking="/our-work/yabee.me"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CaseStudiesBanner;
