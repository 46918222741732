import React from "react";
import { Link } from "react-router-dom";

import styles from "./TechBanner.module.css";
const TechBanner = ({ image, heading, detail, btn_detail, link_to }) => {
  return (
    <>
      <section className={`${styles.allcasestudy_banner}`}>
        {/* <span className={`${styles.case_vector}`}>
          <img alt="" src={contact_footer} />
        </span> */}
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-7">
              <div className={`${styles.pagecontent}`}>
                <h1>{heading}</h1>
                <h4 className="font-weight-400 text--light">{detail}</h4>
                <Link
                  className={`btn--gradient ${styles.btn_service_banner}`}
                  to={link_to}
                >
                  {btn_detail}
                </Link>
              </div>
            </div>
            <div className="col-lg-5">
              <div className={`${styles.banner_thumb}`}>
                <img alt="" src={image} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TechBanner;
