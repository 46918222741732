import React from "react";

const ContactusCard = ({ image, detail, personname }) => {
  return (
    <>
      <div className="swiper-slide">
        <div className="contact-slide">
          <div className="slide-inner">
            <h3>{detail}</h3>
            <span className="ratings">
              <img alt="" src={image} />
            </span>
          </div>
          <p> {personname} </p>
        </div>
      </div>
    </>
  );
};

export default ContactusCard;
