import React from "react";
import { Link } from "react-router-dom";
import styles from "./PositionCard.module.css";
export const PositionCard = ({ image, detail, heading, btn, linking }) => {
  return (
    <>
      <div className={styles.position_card}>
        <div className={`${styles.left}`}>
          <span className={styles.positoion_icon}>
            <img alt="" src={image} />
          </span>
          <h3 className={` ${styles.position_title}`}>
            <strong className="text-capitalize d-block font-weight-700 text-white">
              {heading}
            </strong>
            <span className="d-block font-weight-500 text--light mt-2">
              {detail}
            </span>
          </h3>
        </div>
        <Link
          className="text--gradient btn-positions font-weight-700"
          to={linking}
        >
          {btn} <i className="fa-solid fa-arrow-right-long"></i>
        </Link>
      </div>
    </>
  );
};
