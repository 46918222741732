import React from "react";
import { Link } from "react-router-dom";
import styles from "./Card.module.css";
export const Card = ({
  heading,
  subheading,
  details,
  subdetails,
  link_to,
  link_title,
}) => {
  return (
    <>
      <div className={styles.banner_slide}>
        <h2>{heading}</h2>
        <h3>{subheading}</h3>
        <p className="text--light mb-3">{details}</p>
        <p className="text--light">{subdetails}</p>
        <Link className={`btn--gradient ${styles.btn_banner}`} to={link_to}>
          {link_title}
        </Link>
      </div>
    </>
  );
};
