import React from "react";
import { CaseStudyFeatureCard } from "./CaseStudyFeatureCard";
import styles from "./CaseStudySummary.module.css";
const CaseStudySummary = ({
  heading,
  detail1,
  detail2,
  detail3,
  summary,
  location,
  challenge,
  color,
  content,
  margins,
}) => {
  return (
    <>
      {summary === true && (
        <>
          <div className={`col-lg-8 col-md-8 ${styles.summary_col}`}>
            <h2
              className={
                color === "red"
                  ? `${styles.redcolor} font-weight-700 ${styles.summary_heading}`
                  : color === "aquacolor"
                  ? `${styles.aquacolor} font-weight-700 ${styles.summary_heading}`
                  : color === "yellowcolor"
                  ? `${styles.yellowcolor} font-weight-700 ${styles.summary_heading}`
                  : color === "greencolor"
                  ? `${styles.greencolor} font-weight-700 ${styles.summary_heading}`
                  : ``
              }
            >
              <span></span> {heading}
            </h2>
            <p>{detail1}</p>
            <p>{detail2}</p>
            <p>{detail3}</p>
          </div>
        </>
      )}
      {location === true && (
        <>
          <div className={`col-lg-4 col-md-4 ${styles.summary_col}`}>
            <div
              className={`${styles.case_features} ${
                color === "red"
                  ? `${styles.redcolor}`
                  : color === "aquacolor"
                  ? `${styles.aquacolor} `
                  : color === "yellowcolor"
                  ? `${styles.yellowcolor} `
                  : color === "greencolor"
                  ? `${styles.greencolor} `
                  : ``
              }`}
            >
              <span
                className={` ${styles.iconix} ${styles.iconixindustry}`}
              ></span>
              <CaseStudyFeatureCard title="Travel" titlestrong="Industry" />
            </div>

            <div
              className={`${styles.case_features} ${
                color === "red"
                  ? `${styles.redcolor}`
                  : color === "aquacolor"
                  ? `${styles.aquacolor} `
                  : color === "yellowcolor"
                  ? `${styles.yellowcolor} `
                  : color === "greencolor"
                  ? `${styles.greencolor} `
                  : ``
              }`}
            >
              <span
                className={`${styles.iconix} ${styles.iconixlocation}`}
              ></span>
              <CaseStudyFeatureCard title="London, UK" titlestrong="Location" />
            </div>

            <div
              className={`${styles.case_features} ${
                color === "red"
                  ? `${styles.redcolor}`
                  : color === "aquacolor"
                  ? `${styles.aquacolor} `
                  : color === "yellowcolor"
                  ? `${styles.yellowcolor} `
                  : color === "greencolor"
                  ? `${styles.greencolor} `
                  : ``
              }`}
            >
              <span
                className={`${styles.iconix} ${styles.iconixduration}`}
              ></span>
              <CaseStudyFeatureCard
                title="6-10, November 2019 November 2020"
                titlestrong="SIZE & DURATION"
              />
            </div>

            <div
              className={`${styles.case_features} ${
                color === "red"
                  ? `${styles.redcolor}`
                  : color === "aquacolor"
                  ? `${styles.aquacolor} `
                  : color === "yellowcolor"
                  ? `${styles.yellowcolor} `
                  : color === "greencolor"
                  ? `${styles.greencolor} `
                  : ``
              }`}
            >
              <span className={`${styles.iconix} ${styles.iconixwedid}`}></span>
              <CaseStudyFeatureCard
                title=" Web and Mobile app"
                titlestrong="What We Did  "
                team={true}
              />
            </div>
          </div>
        </>
      )}
      {challenge === true && (
        <>
          <div
            className={`col-lg-12 ${styles.summary_col} padding-top ${
              color === "red"
                ? `${styles.redcolor}`
                : color === "aquacolor"
                ? `${styles.aquacolor}`
                : color === "yellowcolor"
                ? `${styles.yellowcolor} `
                : color === "greencolor"
                ? `${styles.greencolor} `
                : ``
            }`}
          >
            <h2 className={`font-weight-700 ${styles.challenge_heading}`}>
              <span></span>Challenges
            </h2>
            <div>{content}</div>
          </div>
        </>
      )}
    </>
  );
};

export default CaseStudySummary;
