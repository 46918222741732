import React, { useRef, useState } from "react";
import logo from "../../images/main-logo.svg";
import arrow_r from "../../images/arrow-r.png";
import call from "../../images/call.png";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import dropdown_bulb from "../../images/dropdown-blub.svg";

import { navitems } from "./navitems";
import Dropdown from "./Dropdown";
import Company from "./Company";
import { Nav } from "react-bootstrap";
const Header = () => {
  const [sidebar, setSidebar] = useState(false);

  const location = useLocation();
  const [service, setService] = useState(false);
  const [technology, setTechnology] = useState(false);
  const [company, setCompany] = useState(false);
  const ref = useRef(null);
  const ref1 = useRef(null);
  return (
    <>
      <header id="web-header">
        <Nav className="navbar navbar-expand-lg">
          <div className="container-fluid">
            <div className="flex-header">
              <Link className="navbar-brand" to="/">
                <img alt="Company Logo" src={logo} />
              </Link>
              <div className="menu-holder">
                <Link className="text--gradient btn-consult" to="/contact-us">
                  Schedule time now for free consulting!
                  <img alt="" src={arrow_r} className="btn-arrow" />
                </Link>

                <div className="collapse  navbar-collapse" id="webNav">
                  <button
                    className="toggle-mobile"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#webNav"
                    aria-controls="webNav"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <i className="fas fa-times"></i>
                  </button>
                  <ul className="navbar-nav">
                    {navitems.map((menu) => {
                      if (menu.title === "Case Studies") {
                        return (
                          <li className="nav-item" key={menu.id}>
                            <NavLink
                              to={menu.url}
                              onClick={() => sidebar(!setSidebar)}
                              className={() => {
                                if (
                                  location.pathname === "/our-work" ||
                                  location.pathname === "/our-work/yabee.me" ||
                                  location.pathname ===
                                    "/our-work/universal-visas"
                                )
                                  return "nav-link active";
                                else {
                                  return "nav-link inactive";
                                }
                              }}
                            >
                              {menu.title}
                            </NavLink>
                          </li>
                        );
                      }
                      if (menu.title === "Industries") {
                        return (
                          <li className="nav-item" key={menu.id}>
                            <NavLink
                              to={menu.url}
                              onClick={() => sidebar(!setSidebar)}
                              className={() => {
                                if (
                                  location.pathname === "/industries" ||
                                  location.pathname ===
                                    "/industries/ecommerce" ||
                                  location.pathname ===
                                    "/industries/education" ||
                                  location.pathname ===
                                    "/industries/healthcare" ||
                                  location.pathname ===
                                    "/industries/realestate" ||
                                  location.pathname ===
                                    "/industries/travel-tourism"
                                )
                                  return "nav-link active";
                                else {
                                  return "nav-link inactive";
                                }
                              }}
                            >
                              {menu.title}
                            </NavLink>
                          </li>
                        );
                      }
                      if (menu.title === "Technologies") {
                        return (
                          <li
                            className="nav-item"
                            key={menu.id}
                            onMouseEnter={() => setTechnology(true)}
                            onMouseLeave={() => setTechnology(false)}
                          >
                            <NavLink
                              to={menu.url === null}
                              onClick={() => sidebar(!setSidebar)}
                              className={() => {
                                if (
                                  location.pathname === "/db" ||
                                  location.pathname === "/testing" ||
                                  location.pathname === "/coding" ||
                                  location.pathname === "/framework" ||
                                  location.pathname === "/hosting" ||
                                  location.pathname === "/web_tech" ||
                                  location.pathname === "/integration" ||
                                  location.pathname === "/tech"
                                ) {
                                  return technology
                                    ? " nav-link active  "
                                    : "nav-link active    ";
                                } else {
                                  return technology
                                    ? " nav-link inactive  "
                                    : "nav-link inactive    ";
                                }
                              }}
                            >
                              {menu.title}
                            </NavLink>
                            {technology && (
                              <Dropdown
                                drop_link={menu.url}
                                image={dropdown_bulb}
                                detail="Wager on technologies that will work even a very long time from now."
                                heading="Technologies"
                                tech={true}
                              />
                            )}
                          </li>
                        );
                      }
                      if (menu.title === "Services") {
                        return (
                          <li
                            ref={ref}
                            className="nav-item "
                            key={menu.id}
                            onMouseEnter={() => setService(true)}
                            onMouseLeave={() => setService(false)}
                            onClick={() => {
                              setService(!service);
                            }}
                          >
                            <NavLink
                              to={menu.url}
                              onClick={() => {
                                if (service === true) {
                                  sidebar(!setSidebar);
                                }
                              }}
                              className={() => {
                                if (
                                  location.pathname ===
                                    "/services/digital-product-design" ||
                                  location.pathname ===
                                    "/services/custom-software-development" ||
                                  location.pathname ===
                                    "/services/mobile-development" ||
                                  location.pathname ===
                                    "/services/web-development" ||
                                  location.pathname ===
                                    "/services/agile-development" ||
                                  location.pathname ===
                                    "/services/software-architecture" ||
                                  location.pathname ===
                                    "/services/quality-assurance" ||
                                  location.pathname === "/services"
                                ) {
                                  return service
                                    ? " nav-link active dropdown-toggle "
                                    : "nav-link active  dropdown-toggle  ";
                                } else {
                                  return service
                                    ? " nav-link inactive dropdown-toggle "
                                    : "nav-link inactive  dropdown-toggle  ";
                                }
                              }}
                            >
                              {menu.title}
                            </NavLink>
                            {service && (
                              <Dropdown
                                onClick={() => sidebar(!setSidebar)}
                                detail="Transforming Your Idea Through Technology."
                                services={true}
                              />
                            )}
                          </li>
                        );
                      }
                      if (menu.title === "Company") {
                        return (
                          <li
                            className="nav-item"
                            ref={ref1}
                            key={menu.id}
                            onMouseEnter={() => setCompany(true)}
                            onMouseLeave={() => setCompany(false)}
                            onClick={() => setCompany(!company)}
                          >
                            <NavLink
                              to={menu.url}
                              onClick={() => {
                                if (company === true) {
                                  sidebar(!setSidebar);
                                }
                              }}
                              className={() => {
                                if (
                                  location.pathname === "/about-us" ||
                                  location.pathname === "/career" ||
                                  location.pathname ===
                                    "/career/mern-stack-developer-job-details" ||
                                  location.pathname ===
                                    "/career/full-stack-developer-job-details"
                                ) {
                                  return company
                                    ? " nav-link active dropdown-toggle "
                                    : "nav-link active   dropdown-toggle ";
                                } else {
                                  return company
                                    ? " nav-link inactive dropdown-toggle "
                                    : "nav-link inactive   dropdown-toggle ";
                                }
                              }}
                            >
                              {menu.title}
                            </NavLink>
                            {company && (
                              <Company
                                onClick={() => sidebar(!setSidebar)}
                                companies={true}
                              />
                            )}
                          </li>
                        );
                      }
                      return (
                        <li className="nav-item" key={menu.id}>
                          <NavLink
                            to={menu.url}
                            className="  nav-link "
                            onClick={() => sidebar(!setSidebar)}
                          >
                            {menu.title}
                          </NavLink>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
              <div className="nav-right">
                <div className="navbar-attr">
                  <span className="img-call">
                    <img alt="" src={call} />
                  </span>
                  <p>
                    <small className="text--light">Lets Connect</small>
                    <span>+1 276 644 6255</span>
                  </p>
                </div>

                <button
                  className="navbar-toggler collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#webNav"
                  aria-controls="webNav"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span> </span>
                  <span> </span>
                  <span> </span>
                </button>
              </div>
            </div>
          </div>
        </Nav>
      </header>
    </>
  );
};

export default Header;
