import React from "react";
import { Link, useLocation } from "react-router-dom";
import styles from "./footer.module.css";
import call from "../../images/fon.svg";
import mail from "../../images/mail.svg";
import { FooterItems } from "./FooterItems";
import { emailconfig } from "../../config/emailconfig";
const Footer = ({ footertop }) => {
  const location = useLocation();
  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };
  const emailpath = emailconfig();
  const buttons = [
    "/",
    "our-work",
    "services",
    "industries",
    "about-us",
    "contact-us",
  ];

  return (
    <>
      {footertop === true && (
        <div className={`${styles.footer_top}`}>
          <div className={`${styles.footer_top_inner}`}>
            <div className="container">
              <div className="row">
                <div className={`col-lg-7 ${styles.consult_text}`}>
                  <h3 className="font-weight-600">Locations</h3>
                  <div
                    className={`mt-4 ${styles.contact_numbers} ${styles.location_numbers} `}
                  >
                    <p>
                      <span className={`${styles.contacticon}`}>USA:</span>
                      <span>
                        iSoft Technologies LLC 3419 Virginia Beach Blvd #544
                        Virginia Beach, VA 2345
                      </span>
                    </p>
                    <p className="mt-4">
                      <span className={`${styles.contacticon}`}>Pakistan:</span>
                      <span>
                        Al Hafeez Executive, 30 Firdous Mkt Rd, Block G Gulberg III, Lahore, Punjab
                      </span>
                    </p>
                  </div>
                </div>
                <div className={`col-lg-5 ${styles.consult_text}`}>
                  <h3 className="font-weight-600">Contact us at:</h3>
                  <div className={`mt-4 ${styles.contact_numbers} `}>
                    <p className="me-3">
                      <img
                        alt=""
                        className={`${styles.contacticon}`}
                        src={call}
                      />
                      <span>USA: +1 276 644 6255</span>
                    </p>
                    <p className="me-3">
                      <img
                        alt=""
                        className={`${styles.contacticon}`}
                        src={call}
                      />
                      <span>Pak: +92 326 4175265</span>
                    </p>
                    <p className="me-3">
                      <img
                        alt=""
                        className={`${styles.contacticon}`}
                        src={mail}
                      />
                      <a
                        href="https://mail.google.com/mail/u/0/#inbox?compose=CllgCHrhThSlJztHRhRfdkXvpvTzLdLqmqSmWXqGNqJMSWsTrdwBcMfnwSrZltQxpQzHFmXKxBV"
                        onClick={() =>
                          openInNewTab(
                            "https://mail.google.com/mail/u/0/#inbox?compose=CllgCHrhThSlJztHRhRfdkXvpvTzLdLqmqSmWXqGNqJMSWsTrdwBcMfnwSrZltQxpQzHFmXKxBV"
                          )
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span>{emailpath} </span>
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <footer id="web-footer" className={`${styles.web_footer}`}>
        <div className="container">
          <div className="row">
            <div className="col-lg-8 footerNavList">
              <ul className={`${styles.footer_list}`}>
                {FooterItems.map((menu, index) => {
                  return (
                    <li key={menu.id} className={`${styles.footer_li}`}>
                      <Link
                        style={{
                          color:
                            location.pathname
                              .split("/")
                              .includes(buttons[index]) ||
                            location.pathname === buttons[index]
                              ? "#1face3"
                              : "#ffffff",
                        }}
                        to={menu.url}
                      >
                        {menu.title}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className={`col-lg-4 ${styles.btn_footer_align}`}>
              <Link
                className="text--gradient btn-footer font-weight-500"
                to="/contact-us"
              >
                Schedule time now for free consulting!
                <i
                  className={`fa-solid fa-arrow-right-long ${styles.marg_left}`}
                ></i>
              </Link>
            </div>
          </div>
          <div className={`footer-mobile ${styles.footer_copyright}`}>
            <div className="row">
              <div className="col-lg-8 col-first">
                <p className="mb-1">
                  {" "}
                  {new Date().getFullYear()} isoft technologies | All rights
                  reserved
                </p>
              </div>
              <div className="col-lg-4 col-second">
                <ul className={`${styles.social_icon}`}>
                  <li>
                    <a
                      href="https://www.linkedin.com/company/isoft-technologies-pakistan/mycompany/"
                      onClick={() =>
                        openInNewTab(
                          "https://www.linkedin.com/company/isoft-technologies-pakistan/mycompany/"
                        )
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fab fa-linkedin-in"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.facebook.com/isofttechpk/"
                      onClick={() =>
                        openInNewTab("https://www.facebook.com/isofttechpk/")
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
