import React from "react";
import tech from "../../images/tech-thumb.jpg";
import stacks from "../../images/stacks.svg";
import styles from "./LanguageCard.module.css";
const LanguageCard = () => {
  return (
    <>
      <section className={`${styles.language_section}`}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className={`${styles.language_tech}`}>
                <span className={`${styles.tech_icon}`}>
                  <img alt="" src={stacks} />
                </span>
                <h2 className="font-weight-700 mb-3">Java Script:</h2>
                <h3 className="font-weight-400 text--web txt">
                  95% of all the websites of the world are built by using the
                  scripting language, JavaScript. Its structure is simple for
                  both the users and developers, and since it is an interpreted
                  language, it saves time also. Our team creates attractive web
                  pages by utilizing JavaScript and giving a rich UI to them
                  which leads to improved user-interactivity. The JavaScript
                  websites developed by us cater the long-term business
                  objectives of the company. We incorporate this language not
                  only in web development, but also in Server Apps, Games, Web
                  Servers and so on.
                </h3>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className={`${styles.language_text}`}>
                  <h3 className="font-weight-700 text--gradient-sm">
                    REACT.js:
                  </h3>
                  <h4
                    className={`font-weight-400 text--web ${styles.commonline} mt-3 mb-4`}
                  >
                    React.js is the most used web framework among software
                    developers all over the world as it is very intuitive to
                    work with and makes it easier to interact with the layout of
                    any UI. Fast and quality assured applications can be
                    developed with React technology and the fusion of JavaScript
                    and HTML syntax in it automatically simplifies the whole
                    procedure of code writing of the project, therefore, we
                    utilize this awesome technology for creating E-Commerce
                    Apps, Messaging Apps, Tracking Apps and what not. We also
                    deliver solutions in React with powerful user interfaces
                    that provide strong functionality and tremendous visuals for
                    catering the needs of a business.
                  </h4>
                  <ul className="arrow-list">
                    <li>
                      <p
                        className={`font-weight-400 text--web ${styles.margining_left}`}
                      >
                        React offers high performance, modularity, speed of
                        development, code-reuse, cross-platform support, reduced
                        complexity and a great ecosystem.
                      </p>
                    </li>
                    <li>
                      <p
                        className={`font-weight-400 text--web ${styles.margining_left}`}
                      >
                        Our teams help Fortune 500, large and mid-size companies
                        with React development, consulting, recruiting services
                        and hands-on training services.
                      </p>
                    </li>
                    <li>
                      <p
                        className={`font-weight-400 text--web ${styles.margining_left}`}
                      >
                        Our experts can help you with performance improvement,
                        code quality, code coverage, CI/CD and implementing
                        React and JavaScript best practices.
                      </p>
                    </li>
                  </ul>
                  <h3 className="font-weight-700 text--gradient-sm mt-4 pt-lg-3">
                    Vue.js:
                  </h3>
                  <h4
                    className={`font-weight-400 text--web ${styles.commonline} mt-3 `}
                  >
                    Vue is a JavaScript framework for building user interfaces.
                    It builds on top of standard HTML, CSS and JavaScript, and
                    provides a declarative and component-based programming model
                    that helps you efficiently develop user interfaces, be it
                    simple or complex.
                  </h4>
                </div>
              </div>
              <div className="col-lg-6">
                <div className={`${styles.tech_thumb}`}>
                  <img alt="" src={tech} />
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className={`${styles.language_tech} mt-5`}>
                <span className={`${styles.tech_icon}`}>
                  <img alt="" src={stacks} />
                </span>
                <h2 className="font-weight-700 mb-3">CSS:</h2>
                <h3 className="font-weight-400 text--web txt mb-4">
                  Cascading style sheets are used to format the layout of Web
                  pages. They can be used to define text styles, table sizes,
                  and other aspects of Web pages that previously could only be
                  defined in a page's HTML.
                </h3>
                <ul className="arrow-list">
                  <li>
                    <p
                      className={`font-weight-400 text--web ${styles.margining_left}`}
                    >
                      CSS helps Web developers create a uniform look across
                      several pages of a Web site. Instead of defining the style
                      of each table and each block of text within a page's HTML,
                      commonly used styles need to be defined only once in a CSS
                      document. Once the style is defined in cascading style
                      sheet, it can be used by any page that references the CSS
                      file. Plus, CSS makes it easy to change styles across
                      several pages at once.
                    </p>
                  </li>
                  <li>
                    <p
                      className={`font-weight-400 text--web ${styles.margining_left}`}
                    >
                      While CSS is great for creating text styles, it is helpful
                      for formatting other aspects of Web page layout as well.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default LanguageCard;
