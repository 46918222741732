import React from "react";
import Banner from "../../components/Banner/Banner";
import MainTextCard from "../../components/Development/MainTextCard";
import contact_footer from "../../images/contact-footer.png";
import agile_dev from "../../images/agile-dev.png";
import styles from "./SoftwareArch.module.css";
import AgileDevCard from "../../components/AgileDev/AgileDevCard";
import Aboutisoft from "../../components/About/Aboutisoft";

import work from "../../images/team.jpg";

import OurApproach from "../../components/Approach/OurApproach";
import Footer from "../../components/Footer/Footer";
import GotProject from "../../components/Got_Project/GotProject";
import approach from "../../images/approach.svg";
import software1 from "../../images/software1.svg";
import software2 from "../../images/software2.svg";
import software3 from "../../images/software3.svg";
import software4 from "../../images/software4.svg";
import { TabTitle } from "../../Utils/GeneralFunction";
const SoftwareArch = () => {
  TabTitle("Services | Software Architecture");
  return (
    <>
      <Banner
        agile={true}
        heading="Software Architecture"
        detail=" You can build a dependable and dynamic product core"
        btn_detail="Start a project"
        image={contact_footer}
        image_mobile={agile_dev}
        image_large={agile_dev}
      />

      <MainTextCard
        heading="Our cloud developers will help you avoid building over-engineered solutions that badly affect your business outcomes."
        detail="Tell us your milestones, and we’ll tell you which software systems and solution stacks offer you high availability, scalability, and security that beats an on premise setup. Scaling up? Work in pair with architects who developed a various software."
      />
      <section className={`${styles.feature_section}`}>
        <div className="container">
          <div className="row">
            <Aboutisoft
              arch={true}
              extra={true}
              everyimage={work}
              agile={true}
              mob={true}
              sub_heading=" The best way to ensure the success of your business software is to build it on the best platform."
              heading=" Architecture Work Matters"
              detail=" Our software architecture process is about more than technology. It’s a collaborative effort between our team and our clients. We’re not just looking to build a particular application or piece of software; we’re solving a business challenge. We consider all the technology and systems your company works with to best determine how the product we’re building will fit and provide value to your operations. We also assess the latest technologies to make sure we’re creating a cutting-edge solution that will work for your business now and into the future. Our technology and software architecture choices are based upon industry best standards and ensure that your platform will be supportable and maintainable long term. It’s that forward-thinking approach and collaborative effort that ensures that what we build will contribute to your bottom line and help your business grow."
            />

            <AgileDevCard
              heading="Consider the latest technology"
              detail=" From machine learning and artificial intelligence to virtual reality to blockchain, we keep up with the latest technology trends to make sure we’re assembling the best, and most effective, architectural building blocks into a platform for your business."
              image={software1}
            />
            <AgileDevCard
              heading="Build on the power of community"
              detail=" When we select a technology, we consider what the support looks like around it as well. With open source frameworks or well-supported technologies like .NET, you can get a community of innovators around the world contributing to the core software architecture. The more people are actively using and building on a code base, the easier it will be to innovate, and support and maintain into the future."
              image={software2}
            />

            <AgileDevCard
              heading="Put the pieces together"
              detail="We use a combination of custom development (front-end development and back-end development) and existing tools to make sure we build a solution on the best software architecture for your business in the most efficient way."
              image={software3}
            />
            <AgileDevCard
              heading="Partner with your business"
              detail="No matter what we’re building, software architecture consulting and core engineering needs to be a collaborative process to be the most effective. By working with clients to come up with the best solutions possible, we ensure that we’re following the right path and hitting your milestones for success."
              image={software4}
            />
          </div>
        </div>
      </section>
      <OurApproach
        heading="    Why Businesses Choose Us For Mobile Application Development?"
        title_main="Our Approach"
        image={approach}
        detail1="  No matter what we’re building, software architecture consulting and core engineering needs to be a collaborative process to be the most effective. By working with clients to come up with the best solutions possible, we ensure that we’re following the right path and hitting your milestones for success.
"
        detail2=" Working with our experts, you can be sure that your software architecture and design is controlled by the smartest minds here. They know a lot about software architecture services, including design, domains, methods, and technologies."
      />
      <GotProject every={true} />
      <Footer contacting={true} />
    </>
  );
};

export default SoftwareArch;
