import React, { useRef, useState } from "react";

import emailjs from "@emailjs/browser";
import fon from "../../images/fon.svg";
import mail from "../../images/mail.svg";
import styles from "./Got_Project.module.css";
import { useForm } from "react-hook-form";
import sendimage from "../../images/check.png";
import errorimage from "../../images/cross.png";
import { emailconfig } from "../../config/emailconfig";
const GotProject = (event) => {
  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };
  const form = useRef();

  const [statusMessage, setStatusMessage] = useState("");

  const sendEmail = () => {
    emailjs
      .sendForm(
        "service_g23ejtd",
        "template_kisl90i",
        form.current,
        "wcYZLEqqRwdVFLlcY"
      )
      .then(
        () => {
          setStatusMessage("yes");
          setTimeout(() => {
            setStatusMessage(null);
          }, 10000);
        },
        () => {
          setStatusMessage("no");
          setTimeout(() => {
            setStatusMessage(null);
          }, 10000);
        }
      );
  };
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const finalsubmit = (datas, e) => {
    sendEmail(datas);
    reset();
  };
  const resetmsg = () => {
    setStatusMessage(null);
  };
  const emailpath = emailconfig();
  return (
    <>
      <section className={styles.get_consultation}>
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className={styles.consult_text}>
                <h2 className="font-weight-700">Got a Project?</h2>
                <h4 className="mt-3 mb-5">
                  Custom Solutions for companies and individuals. Fill in our
                  short form. It will only take a minute.
                </h4>
                <h3 className="font-weight-600">Contact us at:</h3>
                <div className={`${styles.contact_numbers} mt-4`}>
                  <p className="me-3">
                    <img alt="" className={`${styles.contacticon}`} src={fon} />
                    <span>USA: +1 276 644 6255</span>
                  </p>
                  <p className="me-3">
                    <img alt="" className={styles.contacticon} src={fon} />
                    <span>Pak: +92 326 4175265</span>
                  </p>
                  <p className="me-3">
                    <img alt="" className={styles.contacticon} src={mail} />

                    <a
                      href="https://mail.google.com/mail/u/0/#inbox?compose=CllgCHrhThSlJztHRhRfdkXvpvTzLdLqmqSmWXqGNqJMSWsTrdwBcMfnwSrZltQxpQzHFmXKxBV"
                      onClick={() =>
                        openInNewTab(
                          "https://mail.google.com/mail/u/0/#inbox?compose=CllgCHrhThSlJztHRhRfdkXvpvTzLdLqmqSmWXqGNqJMSWsTrdwBcMfnwSrZltQxpQzHFmXKxBV"
                        )
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span>{emailpath}</span>
                    </a>
                  </p>
                </div>
                <h3 className="font-weight-600 mt-5">Locations</h3>
                <div
                  className={`${styles.contact_numbers}  ${styles.location_numbers} mt-4`}
                >
                  <p>
                    <span className={`${styles.contacticon}`}>USA:</span>
                    <span>
                      iSoft Technologies LLC 3419 Virginia Beach Blvd #544
                      Virginia Beach, VA 2345
                    </span>
                  </p>
                  <p className="mt-4">
                    <span className={styles.contacticon}>Pakistan:</span>
                    <span>
                      Al Hafeez Executive, 30 Firdous Mkt Rd, Block G Gulberg III, Lahore, Punjab
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className={styles.consult_form_box}>
                <h2 className="font-weight-700 text-capitalize">
                  Let’s chat over a brew.
                </h2>
                <h3 className="font-weight-600 mt-2 mb-4">
                  Coffee, tea or something stronger...
                </h3>
                <form
                  ref={form}
                  onSubmit={handleSubmit(finalsubmit)}
                  className="form-consult"
                >
                  <div className="form-group mb-3">
                    <label htmlFor="csName" className="form-label">
                      Name*
                    </label>
                    <input
                      type="text"
                      name="user_name"
                      onClickCapture={resetmsg}
                      className={`form-control ${
                        errors.user_name ? `form-control-required` : ``
                      }`}
                      {...register("user_name", {
                        required: true,
                      })}
                      placeholder="How should we address you?"
                    />
                    {errors.user_name && (
                      <span className="validate-msg">Name is required</span>
                    )}
                  </div>
                  <div className="form-group mb-3">
                    <label htmlFor="csMail" className="form-label">
                      Email*
                    </label>
                    <input
                      type="email"
                      name="user_email"
                      onClickCapture={resetmsg}
                      {...register("user_email", {
                        required: true,
                        pattern:
                          /^(([^<>()\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      })}
                      className={`form-control ${
                        errors.user_email ? `form-control-required` : ``
                      }`}
                      placeholder="Let’s start a chain."
                    />
                    {errors.user_email && (
                      <span className="validate-msg">
                        Check your email address
                      </span>
                    )}
                  </div>
                  <div className="form-group mb-3">
                    <label htmlFor="csPhone" className="form-label">
                      Phone*
                    </label>
                    <input
                      type="tel"
                      name="user_cell"
                      onClickCapture={resetmsg}
                      {...register("user_cell", {
                        required: true,
                      })}
                      className={`form-control ${
                        errors.user_cell ? `form-control-required` : ``
                      }`}
                      placeholder="So nothing gets lost in translation."
                    />
                    {errors.user_cell && (
                      <span className="validate-msg">
                        Cell number is required
                      </span>
                    )}
                  </div>
                  <div className="form-group mb-3">
                    <label htmlFor="csComent" className="form-label">
                      Comment*
                    </label>
                    <input
                      onClickCapture={resetmsg}
                      {...register("comment", {
                        required: true,
                      })}
                      type="text"
                      name="comment"
                      className={`form-control ${
                        errors.comment ? `form-control-required` : ``
                      }`}
                      placeholder="Discruption starts here. How can we help?"
                    />
                    {errors.comment && (
                      <span className="validate-msg">Comment is required</span>
                    )}
                  </div>
                  <div className="succes-mail-msg">
                    <p>
                      {statusMessage === "yes" && (
                        <div>
                          <img
                            alt=""
                            className="message-image"
                            src={sendimage}
                          />
                          <span>Your mail has been submitted!</span>
                        </div>
                      )}
                      {statusMessage === "no" && (
                        <div>
                          <img
                            alt=""
                            className="message-image"
                            src={errorimage}
                          />
                          <span>Your mail has not sent.</span>
                        </div>
                      )}
                    </p>
                  </div>
                  <button value="Send" type="submit" className="btn--gradient">
                    Get our attention
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default GotProject;
