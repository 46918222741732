import React from "react";

export const AboutClientCard = ({
  image,
  title,
  details,
  subdetails,
  info,
  text,
  strongnext,
}) => {
  return (
    <>
      {/* <span className="client-photo">
        <img alt="" src={image} />
      </span> */}
      <h3 className="font-weight-700">{title}</h3>
      <h4>
        <strong>{details} </strong>
        {subdetails}
        <strong>{strongnext}</strong>
        {text}
      </h4>
      <p>{info}</p>
    </>
  );
};
