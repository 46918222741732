import React from "react";
import styles from "./LocationCard.module.css";
export const LocationCard = ({ image, title }) => {
  return (
    <>
      <div className="col-6">
        <h3 className={styles.client_location}>
          <img alt="" src={image} />
          <span>{title}</span>
        </h3>
      </div>
    </>
  );
};
