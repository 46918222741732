import React from "react";
import { Link } from "react-router-dom";
import RequirementCard from "../../components/Career_Detail/RequirementCard";
import careerdetail from "../../images/team.jpg";
import styles from "./CareerDetail.module.css";
import GotProject from "../../components/Got_Project/GotProject";
import Footer from "../../components/Footer/Footer";
import { TabTitle } from "../../Utils/GeneralFunction";
import CareerDetailCarad from "../../components/Career/CareerDetailCarad";
import CareerDetailContent from "../../components/Career/CareerDetailContent";
import CareerDetailReq from "../../components/Career/CareerDetailReq";
import ApplyForm from "../../components/Career/ApplyForm";

const CareerDetailPhp = () => {

  TabTitle("Career | Full-Stack-Developer-Job-Details");
  return (
    <>
      <section className={`${styles.career_detail_banner}`}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5 ">
              <div className={`${styles.pagecontent}`}>
                <CareerDetailCarad
                  title="Full Stack Technical Lead"
                  location="Lahore, Pakistan"
                  address="CONTACT ADDRESS"
                />
                <span className={`${styles.banner_arrow} text--gradient-sm`}>
                  <i className="fa-solid fa-arrow-down-long"></i>
                </span>
                <a 
                  className={`${styles.btn_careerdetail}`}
                  target="_blank"
                  href="https://mail.google.com/mail/u/0/#inbox?compose=GTvVlcSMVxbzBZxKmkQzMlbFnddVHdxJVRqDPjNWDszHVPNjxqGDrHXLzLnsKMjJsNHZHTgblnJvd&subject=aihtshamali@gmail.com" rel="noreferrer">
                  hr@isoft-tech.pk <i className="fa-solid fa-arrow-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="careerform-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <CareerDetailContent title=" Our teams don’t just read up on the latest technology trends, they create and influence them too. They are also usually some of the first ones to play around with cutting-edge technology that leads to great solutions" />
            </div>
            <div className="col-lg-6 offset-lg-1">
              <ApplyForm />
            </div>
          </div>
          <CareerDetailContent
            details={true}
            detail1=" We have had the fortune to work with some of the most professional
            people; those who are also great human beings. We hope you can join
            us soon, learn the ropes and work parallely, achieving great things
            together."
            detail2="  ISoft Technologies has a well-established training program that aims
            at continuous learning and skill development. This ensure that our
            employees are always up-to-date with the latest in technologies. We
            also get them into varied projects that help enhance their skill
            sets as well as expose them to the most sought after in technology."
          />
        </div>
      </section>
      <section className={`${styles.detailtext_section}`}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className={`${styles.img_thumb}`}>
                <img alt="" src={careerdetail} />
              </div>
              <div className={`${styles.career_requirement}`}>
                <CareerDetailReq
                  title="Senior Full Stack Developer"
                  content="  ISoft Technologies is looking for a proactive, team-oriented,
                  and motivated Senior Full Stack Developer to join our team. We
                  value a go-getter attitude, the drive to get things done and
                  the empathy to understand our client’s needs and wants. Our
                  team prides itself on working hard and having fun while doing
                  it. We believe in the power of bringing like-minded people
                  together."
                  heading="Requirements"
                />

                <ul className={`${styles.career_list}`}>
                  <RequirementCard
                    detail="  BS/MS degree in Computer Science/Engineering/MIS or
                      similar relevant field with previous working experience as
                      a PHP / Laravel developer for at least 4 years."
                  />
                  <RequirementCard
                    detail="     In depth knowledge of object-oriented PHP and Laravel 5
                      PHP Framework and hands on experience with SQL schema
                      design, SOLID principles, REST API design, MySQL profiling
                      and query optimization."
                  />
                  <RequirementCard detail="    Software testing (PHPUnit, PHPSpec, Behat)." />
                  <RequirementCard detail="    Creative and efficient problem solver." />
                  <RequirementCard
                    detail="     Elastic search, Docker, CI/CD AWS EC2, S3 and RDS and soap
                      services as plus point."
                  />
                  <RequirementCard
                    detail="      At least 3 years of experience in Vue.js with strong
                      understanding of its core principles."
                  />
                  <RequirementCard
                    detail="       Experience building Responsive websites for web, tablet
                      and mobile devices."
                  />
                  <RequirementCard
                    detail="  Ability to write efficient, secure, clean, and scalable
                      JavaScript code."
                  />
                  <RequirementCard
                    detail="   Familiarity with the Vue.js ecosystem and working
                      experience with HTML5 and CSS3."
                  />
                  <RequirementCard detail="     Knowledge of server-side rendering." />
                  <RequirementCard detail="    Nuxtjs, SCSS, tailwind and localization." />
                  <RequirementCard
                    detail="   Experience of using third party UI components like
                      vuetify, buefy, primevue etc."
                  />
                </ul>
                <CareerDetailReq heading="Responsibilities" />
                <ul className={`${styles.career_list}`}>
                  <RequirementCard
                    detail="   Work with development team and product managers to ideate
                      software solutions according to industry standards."
                  />
                  <RequirementCard
                    detail="   Full stack development and maintenance of existing web
                      applications."
                  />
                  <RequirementCard
                    detail="  Develop and maintain integrations with third party
                      vendors."
                  />
                  <RequirementCard detail=" Design and create effective and secure APIs." />
                  <RequirementCard
                    detail="   Help discover, diagnose and fix any bugs or performance
                      issues."
                  />
                  <RequirementCard
                    detail="  Respond and troubleshoot any downtime events and create
                      systems and procedures to prevent them from happening.
                    </h4>"
                  />

                  <RequirementCard detail="   Test software to ensure responsiveness and efficiency." />
                  <RequirementCard
                    detail="   Work with Product Management to clarify requirements, work
                      on development timelines, release schedule and ensure
                      completed features meet requirements and acceptance
                      criteria."
                  />
                  <RequirementCard
                    detail=" Provide ongoing and emergency support of applications and
                      processes as needed."
                  />
                  <RequirementCard detail="    CI/CD experience will be a plus." />
                </ul>
              </div>
              <Link
                to="/contact-us"
                className={`btn--gradient ${styles.btn_apply}`}
              >
                Apply Now
              </Link>
            </div>
          </div>
        </div>
      </section>
      <GotProject every={true} />
      <Footer contacting={true} />
    </>
  );
};

export default CareerDetailPhp;
