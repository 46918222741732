import React from "react";
import Banner from "../../components/Banner/Banner";
import contact_footer from "../../images/contact-footer.png";
import csoftdev from "../../images/banner-csoftdev.png";
import MainTextCard from "../../components/Development/MainTextCard";
import TechStackCard from "../../components/TechStack/TechStackCard";
import Aboutisoft from "../../components/About/Aboutisoft";
import Devlopment from "../../components/Development/Devlopment";
import styles from "./CustomSoftDetail.module.css";
import edu_industry from "../../images/web2.png";
import ecommerece from "../../images/web1.png";
import ecommerece1 from "../../images/web4.png";
import ecommerece2 from "../../images/customS.png";
import custom_soft from "../../images/team.jpg";
import ui from "../../images/web3.png";

import GotProject from "../../components/Got_Project/GotProject";
import Footer from "../../components/Footer/Footer";
import { TabTitle } from "../../Utils/GeneralFunction";

const CustomSoftDetail = () => {
  TabTitle("Services | Custom Software Development");
  return (
    <>
      <Banner
        image={contact_footer}
        heading="Custom Software Development Services"
        detail="Build or scale a competitive product ready for future growth and millions of users.
"
        image_mobile={csoftdev}
        image_large={csoftdev}
        btn_detail="Start a project"
      />
      <MainTextCard
        heading="Our custom software development services meet a critical need
                  as a solution to a wide range of complex business problems."
        detail=" We are a Virginia-based custom software development company
                  with an emphasis on client collaboration, communication, and
                  passion for building quality customized software applications.
                  With our software engineering and continuous development
                  teams, you have a software development partner for the long
                  haul. Check out the custom software development services we
                  offer below or read more about our approach to building custom
                  software solutions."
      />
      <TechStackCard />
      <section className={`${styles.feature_section} pb-0`}>
        <div className="container">
          <div className="row">
            <Aboutisoft
              everyimage={custom_soft}
              custom_head={true}
              agile={true}
              custom={true}
              heading="Software for your business-specific requirements"
              cent={true}
              detail="Custom software development is a way to get superior software due to advanced functionality a custom solution provides. Building on the experience from 700 projects, we offers custom software development services to power business processes of companies in 8+ industries."
              detail_next="One place - everyone you need: Business Analysts, Product Designers, Project Managers, QA Engineers and Full-stack developers. Expect our custom software development team to be proactive throughout the project with an abundance of improvement suggestions that will get business moving even in areas such as search engine optimization."
            />
          </div>
        </div>
      </section>
      <Devlopment
        linking=""
        no="01."
        title="Scalable teams & secure infrastructure"
        detail=" As a custom software development company, rather than the typical web design agency, we are software engineering focused. We make sure your custom software solution is built with quality custom programming and architecture. With our team, your customized software is scalable, extendable, and maintainable.
"
        image={ecommerece}
        industry={true}
      />
      <Devlopment
        linking=""
        no="02."
        title=" Business-tailored architecture"
        detail="Always designed and develop with your company's growth and the product's scalability in mind. Analysis of requirements, business idea, and clarified formulation of goals. To follow business logic and provide scalability for future growth. Our team knows it instinctively."
        image={edu_industry}
      />
      <Devlopment
        linking=""
        no="03."
        title="World-className UX/UI"
        detail="With a strong strategy in hand, we’re able to design the app’s experience and what it will look like on the front-end. We combine analytical insights with a keen understanding of design standards for each of the major mobile platforms. We build intuitive experiences that get users what they want when they want it and connect them to the client’s business faster and more seamlessly. We Design to help your business with user adoption.
"
        image={ecommerece1}
        industry={true}
      />
      <Devlopment
        linking=""
        no="04."
        title="Thorough quality assurance"
        detail="Our custom software developers, architects, project managers, business analysts, and quality assurance specialists have a passion for building quality custom applications that solve complex business problems with user friendly custom web applications, systems integrations using modern restful API services, automation of business processes, and more. Our experience includes developing large enterprise-className line of business applications and architecting multi-tenanted scalable solutions.
"
        approach={true}
        image={ui}
      />
      <Devlopment
        linking=""
        no="05."
        title="Our Approach"
        heading="Why Businesses Choose Us For Custom Software Development?"
        detail="At iSoft Technologies, talent brings in enthusiasm, focus, and passion that lets them face even the hardest challenges. We follow the software development life cycle tailored to the project size and client preferences. Typical software development projects begin with discovery which involve senior software architects and business analysts who evaluate and document the technical & business requirements as epics and features. With that information, they devise a delivery plan & cost estimate for the overall project.
"
        ourapp={true}
        image={ecommerece2}
        industry={true}
        sub_detail="Once approved, we assemble a group of software engineers, project managers, quality assurance engineers, and others organized into scrum teams. Work is broken-down further into user stories and tasks which are organized into sprints of work be completed by the engineering team. Custom programming is regularly committed to code repositories and continuously integrated and deployed into development and test environments. This process allows the work to be delivered and deployed into production regularly getting it in front of users as quickly as possible allowing for changes to be incorporated early and saving refactoring work and money in the long run."
      />
      <GotProject every={true} />
      <Footer contacting={true} />
    </>
  );
};

export default CustomSoftDetail;
