import React, { useState } from "react";
import styles from "./Tab.module.css";

const Tabs = ({ heading, detail, web }) => {
  const [showMore, setShowMore] = useState(false);

  return (
    <>
      {web === true ? (
        <div className="row justify-content-center">
          <div className="col-lg-9">
            <h3 className="font-weight-700 mb-1">{heading}</h3>
            <h4 className={`font-weight-400 text--web ${styles.commonline}`}>
              {showMore ? detail : `${detail.substring(0, 250)}`}
              <button
                className={`${styles.showmore}`}
                onClick={() => setShowMore(!showMore)}
              >
                {showMore ? "Show less" : "...Show more"}
              </button>
            </h4>
          </div>
        </div>
      ) : (
        <>
          <h3 className="font-weight-700 mb-1">{heading}</h3>

          <h4 className={`font-weight-400 text--web ${styles.commonline}`}>
            {showMore ? detail : `${detail.substring(0, 200)}`}
            <button
              className={`${styles.showmore}`}
              onClick={() => setShowMore(!showMore)}
            >
              {showMore ? "Show less" : "...Show more"}
            </button>
          </h4>
        </>
      )}
    </>
  );
};

export default Tabs;
