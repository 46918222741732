import React from "react";
import { Link } from "react-router-dom";
import styles from "./DigitalProductCard.module.css";
const DigitalProductCard = ({ no, title, heading, detail, show, white }) => {
  return (
    <>
      {white === true ? (
        <div className={`col-lg-6 ${styles.digital_text}`}>
          <p className=" font-weight-600 text-white">{no}</p>
          <h2 className="font-weight-700 mb-2 text-white">{title}</h2>
          <p className="font-weight-600 text--web text-white">{heading}</p>
          <h4 className="font-weight-400 text--web text-white">{detail}</h4>
          {show === true && (
            <Link
              className={`text--gradient ${styles.btn_empathise} font-weight-700`}
              to="/"
            >
              Learn more
              <i className="fa-solid fa-arrow-right-long"></i>
            </Link>
          )}
        </div>
      ) : (
        <div className={`col-lg-6 ${styles.digital_text}`}>
          <p className="text--gradient font-weight-600">{no}</p>
          <h2 className="font-weight-700 mb-2">{title}</h2>
          <p className="font-weight-600 text--web">{heading}</p>
          <h4 className="font-weight-400 text--web">{detail}</h4>
          {show === true && (
            <Link
              className={`text--gradient ${styles.btn_empathise} font-weight-700`}
              to="/"
            >
              Learn more
              <i className="fa-solid fa-arrow-right-long"></i>
            </Link>
          )}
        </div>
      )}
    </>
  );
};

export default DigitalProductCard;
