import React from "react";
import { Link } from "react-router-dom";

import styles from "./Banner.module.css";
const Banner = ({
  heading,
  detail,
  btn_detail,
  image,
  image_mobile,
  image_large,
  digital,
  agile,
}) => {
  return (
    <>
      {digital === true ? (
        <>
          <section className={`${styles.service_detail_banner}`}>
            {/* <span className={`${styles.serv_vector1}`}>
              <img alt="" src={image} />
            </span> */}
            <div className="container">
              <div className="row">
                <div className="col-lg-7">
                  <div className={`${styles.pagecontent}`}>
                    <h1>{heading}</h1>
                    <h4 className="font-weight-400 text--light">{detail}</h4>
                    <Link
                      className={`btn--gradient ${styles.btn_service_banner}`}
                      to="/contact-us"
                    >
                      {btn_detail}
                    </Link>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className={`${styles.banner_thumb1}`}>
                    <img alt="" src={image_mobile} />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className={`container ${styles.showonmobile}`}>
            <div className={`${styles.banner_thumb_mobile}`}>
              <img alt="" src={image_mobile} />
            </div>
          </div>
        </>
      ) : (
        <>
          <section className={`${styles.service_detail_banner}`}>
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <div className={`${styles.pagecontent}`}>
                    <h1>{heading}</h1>
                    <h4 className="font-weight-400 text--gradient">{detail}</h4>
                    <Link
                      className={`btn--gradient ${styles.btn_service_banner}`}
                      to="/contact-us"
                    >
                      {btn_detail}
                    </Link>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div
                    className={
                      agile === true
                        ? `${styles.banner_thumb2}`
                        : `${styles.banner_thumb}`
                    }
                  >
                    <img alt="" src={image_large} />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className={`container ${styles.showonmobile}`}>
            <div className={`${styles.banner_thumb_mobile}`}>
              <img alt="" src={image_mobile} />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Banner;
