import React from "react";
import "./OfficeDetail.module.css";
export const OfficeDetail = ({ title, details, ouroffice }) => {
  return (
    <>
      <h2 className="font-weight-700">{title}</h2>
      <p>{details}</p>
    </>
  );
};
