import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { companydropdown, techdropdown } from "./navitems";
import { servicedropdown } from "./navitems";
const Dropdown = ({ detail, tech, services, companies, onClick }) => {
  // const [service, setService] = useState(false);
  const [technology, setTechnology] = useState(false);
  const [company, setCompany] = useState(false);
  return (
    <>
      <ul className={technology ? "dropdown-menu clicked" : "dropdown-menu"}>
        <li>
          <div className="dropdown-inner">
            <div className="drop-right">
              <p className="text--gradient">{detail}</p>
            </div>
          </div>
        </li>

        {tech === true && (
          <div>
            {techdropdown.map((items) => {
              return (
                <li className="inner-drop" key={items.id}>
                  <NavLink
                    to={items.url}
                    onClick={() => setTechnology(!technology)}
                  >
                    <span className="drop-iconing">
                      <img src={items.display} alt="" />
                    </span>
                    {items.title}
                  </NavLink>
                </li>
              );
            })}
          </div>
        )}

        {services === true && (
          <div>
            {servicedropdown.map((items) => {
              return (
                <li className="inner-drop" key={items.id}>
                  <NavLink to={items.url} onClick={onClick}>
                    {items.title}{" "}
                  </NavLink>
                </li>
              );
            })}
          </div>
        )}
      </ul>
      {companies === true && (
        <ul className="dropdown-menu dropdown-menu-small">
          {companydropdown.map((items) => {
            return (
              <li key={items.id}>
                <NavLink to={items.url} onClick={() => setCompany(!company)}>
                  {items.title}
                </NavLink>
              </li>
            );
          })}
        </ul>
      )}
    </>
  );
};

export default Dropdown;
