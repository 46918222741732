import React from "react";
import { Link } from "react-router-dom";
import styles from "./BannerCoding.module.css";
const BannerCoding = ({ image, detail, heading, btn_detail }) => {
  return (
    <>
      <section className={`${styles.technology_banner}`}>
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <div className={`${styles.pagecontent}`}>
                <h1>{heading}</h1>
                <h4 className="font-weight-400 text--light">{detail}</h4>
                <Link className="btn--gradient btn-service-banner" to="/">
                  {btn_detail}
                </Link>
              </div>
            </div>
            <div className="col-lg-5">
              <div className={`${styles.banner_thumb}`}>
                <img alt="" src={image} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BannerCoding;
