import React from "react";
import styles from "./IndustriesDevs.module.css";
import {
  ecommerce,
  realestate,
  education,
  healthcare,
  travel,
} from "../../components/Industries/IndustriesPages";
import { useState } from "react";
import { Link } from "react-router-dom";
const IndustriesDevs = ({
  imagebig,
  title,
  heading,
  page,
  textcontent,
  textmain,
}) => {
  const [selected] = useState(
    page === "ecomm"
      ? ecommerce
      : page === "education"
      ? education
      : page === "realestate"
      ? realestate
      : page === "health"
      ? healthcare
      : page === "travel"
      ? travel
      : ""
  );

  return (
    <>
      <section className={`${styles.ecommerce_devs}`}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12 text-center">
              <h2 className="font-weight-700 text-capitalize">{title}</h2>
              <h4 className="font-weight-700 text--gradient mt-3">{heading}</h4>
            </div>
            <div className={`${styles.ecommerce_wrapp}`}>
              {selected.map((item) => {
                return (
                  <div key={item.id} className={`${styles.ecommerce_dev}`}>
                    <span>
                      <img alt="" src={item.image} />
                    </span>
                    <h4>
                      <strong>{item.heading}</strong>
                      {item.text}
                    </h4>
                  </div>
                );
              })}
            </div>
          </div>
          <div className={`${styles.commerece_thumb}`}>
            <img alt="" src={imagebig} />
            <div className="row">
              <div className="col-lg-9">
                <h3 className="font-weight-700">{textmain}</h3>
                <h4 className="text--gradient font-weight-700">
                  {textcontent}
                </h4>
              </div>
              <div className="col-lg-3">
                <Link
                  className={`btn--gradient ${styles.btn_industry_contact}`}
                  to="/contact-us"
                >
                  Contact us
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default IndustriesDevs;
