import React from "react";

import GotProject from "../../components/Got_Project/GotProject";
import Footer from "../../components/Footer/Footer";
import DbCard from "../../components/DBpage/DbCard";
import bannerhosting from "../../images/banner-hosting.png";
import BannerCoding from "../../components/Banner/BannerCoding";

import stacks from "../../images/stacks.svg";
const Hosting = () => {
  return (
    <>
      {" "}
      <BannerCoding
        image={bannerhosting}
        heading="Hostings"
        detail="  Build or rescale your project with programming minds 
                            rated 4.9 on Clutch. Using React.js, React Native, 
                            TypeScript & Next.js."
        btn_detail="Describe the project"
      />
      <DbCard
        sql={true}
        duo_list={true}
        title="Microsoft Azure:"
        image={stacks}
        detail="Microsoft Azure has three main services: Infrastructure as a Service (IaaS), Platform as a Service (PaaS), and Software as a Service (SaaS)"
        list1="Azure offers Web, mobile, storage, database, data analytics, artificial intelligence and security services."
        list2="At iSoft Technologies, we have Azure Insiders, Microsoft Regional Directors and awarded Microsoft Regional Directors."
        sec_detail="Amazon web service is an online platform that provides scalable and cost-effective cloud computing solutions."
        sec_title="Amazon Web Services:"
        hosting={true}
        list3=" AWS is a broadly adopted cloud platform that offers several on-demand operations like compute power, database storage, content delivery, etc., to help corporates scale and grow.
"
        list4="AWS is a comprehensive, easy to use computing platform offered Amazon. The platform is developed with a combination of infrastructure as a service (IaaS), platform as a service (PaaS) and packaged software as a service (SaaS) offerings."
      />
      <GotProject every={true} />
      <Footer contacting={true} />
    </>
  );
};

export default Hosting;
