import React from "react";
import styles from "./TechExpertiesCard.module.css";
export const TechExpertiesCard = ({ image }) => {
  return (
    <>
      <div className="col-3">
        <div className={styles.experty_container}>
          <img alt="" src={image} />
        </div>
      </div>
    </>
  );
};
