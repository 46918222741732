import React from "react";
import IndustriesBanner from "../../components/Banner/IndustriesBanner";
import CaseStudiesBanner from "../../components/CaseStudy/CaseStudiesBanner";
import MainTextCard from "../../components/Development/MainTextCard";
import Footer from "../../components/Footer/Footer";
import GotProject from "../../components/Got_Project/GotProject";
import IndustriesAcc from "../../components/Industries/IndustriesAcc";
import IndustriesDevs from "../../components/Industries/IndustriesDevs";
import IndustriesExperties from "../../components/Industries/IndustriesExperties";
import ecommimage from "../../images/industry-travel.jpg";
import { TabTitle } from "../../Utils/GeneralFunction";
import styles from "./IndustriesEcommerce.module.css";
const IndustriesTravel = () => {
  TabTitle("Industries | Travel & Tourism");
  return (
    <>
      <IndustriesBanner
        heading="Helping Travel & Tourism Businesses Take a Step Toward Digital Transformation"
        subheading="Travel & Tourism Software Development Services"
        detail="iSoft Technologies is a travel & tourism software development company that develops the app with the fusion of technology and creativity, making it one of its kind."
        background_image="travel"
        industry_btn={true}
        ind_btn_text=" Request a proposal"
      />
      <MainTextCard
        page="ecomm"
        heading=" Scale with Our Ecommerce App Development Services"
        detail=<div>
          <h2 className="font-weight-700">
            Scale with our Travel & Tourism Software development services
          </h2>

          <h3 className={`font-weight-400 text--web pt-1 ${styles.content} h3-22-font`}>
            Travelers and tourists always go for such websites where they can find each and every detail regarding the place they want to visit. iSoft Technologies always keeps this point in mind while developing a travel & tourism software.
          </h3>
          <h4 className={`font-weight-400 text--web pt-1 ${styles.content}`}>
            We provide every minute detail on the page so that the customer doesn’t get confused at any point. 
            The pictures of different destinations are set up in order to give the website visitors an idea about the look and culture of the place they desire to visit. 
            They can easily know for what reason the city or country is famous for along with the overall expense they will have to bear for their visit or vacations.
          </h4>
        </div>
      />
      <IndustriesDevs
        page="travel"
        title="Travel & Tourism Software Development"
        heading="Providing relevant and efficient software services"
        imagebig={ecommimage}
        textmain="Get profit from a bespoke Travel & Tourism solution"
        textcontent="Our proficient developers will provide you the best Travel & Tourism solution that will make your business prosperous"
      />
      <IndustriesExperties
        title="Our Travel & Tourism Development Expertise"
        heading="We use the most suitable and advanced technologies while developing a Travel & Tourism website. Our team acknowledges this as its priority to deliver a great product to the client, therefore, we design the website in such a manner that truly fascinates the customer and makes one more excited about traveling. The pictures of the destinations are always given so that the customer gets familiar with the aura of the place. We create separate sections/pages that give information regarding the services and facilities provided by the company. Customized Travel & Tourism software development services are offered so that you see your idea transforming into a reality.

"
        page="travel"
      />
      <CaseStudiesBanner title="Travel & Tourism case studies" />
      <IndustriesAcc
        page="travel"
        heading="Travel & Tourism web development all your questions answered"
        content="Find the answers below to the most common questions regarding Travel & Tourism app development or contact us directly.
"
      />
      <GotProject every={true} />
      <Footer contacting={true} />
    </>
  );
};

export default IndustriesTravel;
