import React from "react";
import styles from "./TechStackCard.module.css";
export const TechStackCard = ({ title, detail, color }) => {
 
  return (
    <>
      <div
        className={`col-lg-4 col-sm-6 ${styles.techs_col}  ${
          color === "red"
            ? `${styles.redcolor}`
            : color === "aquacolor"
            ? `${styles.aquacolor}`
            : color === "yellowcolor"
            ? `${styles.yellowcolor}`
            : color === "greencolor"
            ? `${styles.greencolor}`
            : ``
        }`}
      >
        <div className={`${styles.techstacks}`}>
          <h4 className={`${styles.techstacks}`}>
            <span className="font-weight-700">{title}</span>
            <i className="fa-solid fa-arrow-up-long"></i>
          </h4>
          <p>{detail}</p>
        </div>
      </div>
    </>
  );
};
