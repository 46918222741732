import React from "react";
import styles from "./BenefitCard.module.css";
export const BenefitCard = ({ image, title, detail }) => {
  return (
    <>
      <div className={`${styles.perks_box}  col-md-6`}>
        <span className={styles.perks_icon}>
          <img alt="" src={image} />
        </span>
        <h2 className="font-weight-700 text-capitalize">{title}</h2>
        <h4 className="font-weight-400 text--web">{detail}</h4>
      </div>
    </>
  );
};
