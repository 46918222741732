import React from "react";

import vector4 from "../../images/vector4-1.png";
import vector2 from "../../images/vector2-1.png";

const OurApproach = ({
  detail1,
  detail2,
  image,
  title,
  title_main,
  heading,
  custom,
}) => {
  return (
    <>
      <section className="prototype-section bg--gradient uiux-dev">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-oder-last">
              <div className="digital-vectors xl">
                <span className="vector1">&nbsp;</span>
                <span className="vector2">
                  <img alt="" src={vector2} />
                </span>
                <span className="vector3">&nbsp;</span>
                <span className="vector4">
                  <img alt="" src={vector4} />
                </span>
                <div className="text-elipse elipse-graylg">
                  <span className="elipse-icon mb-0">
                    <img alt="" src={image} />
                  </span>
                </div>
              </div>
            </div>
            <div className="col-lg-6 digital-text col-oder-fst">
              <h2 className="font-weight-700 mb-2 text-white">{title_main}</h2>
              <p className="font-weight-600 text-white">{heading}</p>
              <h4 className="font-weight-400 text-white">{detail1}</h4>
              <h4 className="font-weight-400 text-white">{detail2}</h4>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OurApproach;
