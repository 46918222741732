import shopify from "../../images/shopyfy.png";
import laravel from "../../images/laravel.png";
import mysql from "../../images/mysql-1.png";
import vuejs from "../../images/vuejs.png";
import woo from "../../images/woo.png";
import postgres from "../../images/postgres.png";
import laravelCodeigniter from "../../images/laravelCodeigniter.png";
import php1 from "../../images/php1.png";
import reactpic from "../../images/react.png";

export const Ecommerce = [
  {
    id: 1,
    image: laravel,
    text: "Laravel",
  },
  {
    id: 2,
    image: mysql,
    text: "MySQL",
  },
  {
    id: 3,
    image: php1,
    text: "PHP",
  },
  {
    id: 4,
    image: reactpic,
    text: "React.js",
  },
  {
    id: 5,
    image: vuejs,
    text: "VueJs",
  },
  {
    id: 6,
    image: postgres,
    text: "Postgres",
  },
];

export const RealEstate = [
  {
    id: 1,
    image: shopify,
    text: "Shopify",
  },
  {
    id: 2,
    image: woo,
    text: "WooCommerce",
  },
  {
    id: 3,
    image: php1,
    text: "PHP",
  },
  {
    id: 4,
    image: reactpic,
    text: "React.js",
  },
];

export const Education = [
  {
    id: 1,
    image: mysql,
    text: "MySQL",
  },
  {
    id: 2,
    image: laravel,
    text: "Laravel",
  },
  {
    id: 3,
    image: vuejs,
    text: "VueJs",
  },
  {
    id: 4,
    image: reactpic,
    text: "React.js",
  },
  {
    id: 5,
    image: postgres,
    text: "Postgres",
  },
];

export const HealthCare = [
  {
    id: 1,
    image: mysql,
    text: "MySQL",
  },
  {
    id: 2,
    image: laravelCodeigniter,
    text: "Laravel/Codeignitor",
  },
  {
    id: 3,
    image: php1,
    text: "PHP",
  },
  {
    id: 4,
    image: reactpic,
    text: "React.js",
  },
];
export const Travel = [
  {
    id: 1,
    image: mysql,
    text: "MySql",
  },
  {
    id: 2,
    image: laravelCodeigniter,
    text: "Laravel/Codeignitor",
  },
  {
    id: 3,
    image: php1,
    text: "PHP",
  },
  {
    id: 4,
    image: vuejs,
    text: "Vue.js",
  },
];
