import React from "react";
import styles from "./Freamework.module.css";
import CodingCard from "../../components/Coding/CodingCard";
import GotProject from "../../components/Got_Project/GotProject";
import Footer from "../../components/Footer/Footer";
import DbCard from "../../components/DBpage/DbCard";
import bannerframe from "../../images/banner-framework.png";
import BannerCoding from "../../components/Banner/BannerCoding";
import stacks1 from "../../images/stacks1.svg";
import stacks from "../../images/stacks.svg";
const Freamework = () => {
  return (
    <>
      <BannerCoding
        image={bannerframe}
        heading="Frame Works"
        detail="  Build or rescale your project with programming minds 
                            rated 4.9 on Clutch. Using React.js, React Native, 
                            TypeScript & Next.js."
        btn_detail="Describe the project"
      />
      <DbCard
        sql={true}
        title="Laravel:"
        image={stacks}
        duo_list={true}
        detail="Laravel is one of the most popular PHP backend frameworks as its powerful built-in system makes it simpler to automate a lot of repetitive coding tasks. On the other hand, its syntax is easy to understand and the built-in libraries are quite useful. We use this robust and scalable technology for developing various types of applications including Educational Websites, E-learning Websites and Blogging Applications."
        list1=" Laravel aims to make the development process a pleasing one for the developer without sacrificing application functionality. Happy developers make the best code. To this end, we've attempted to combine the very best of what we have seen in other web frameworks, including frameworks implemented in other languages, such as Ruby on Rails, ASP.NET MVC, and Sinatra."
        list2=" Laravel is accessible, yet powerful, providing powerful tools needed for large, robust applications. A superb inversion of control container, expressive migration system, and tightly integrated unit testing support give you the tools you need to build any application with which you are tasked."
        sec_detail="CodeIgniter is a PHP MVC framework used for developing web applications rapidly. CodeIgniter provides out of the box libraries for connecting to the database and performing various operations like sending emails, uploading files, managing sessions, etc."
        sec_title="CodeIgniter:"
        listing1={true}
        listing2={true}
        list3="The built-in features of CodeIgniter are designed to work independently without relying too much on other components. The framework uses the Model-View-Controller architectural design. It is well documented, and they are good books, tutorials and answered forum questions on CodeIgniter.
"
        list4="CodeIgniter comes with some libraries, and helpers users out of the box. It is easy to master for anyone who is already familiar with PHP. In CodeIgniter user requests a resource, the controller responds first. The controller understands the user request then request the necessary data if it is important"
      />

      <DbCard
        title="Nuxt.js:"
        image={stacks1}
        detail="Nuxt.js can be defined as a free, open-source web application framework that is based on Vue.js, and Node.js. The framework is often referred to as a “meta-framework” for universal applications, with the term universal meaning the possibility of creating JavaScript web views with the Vue.js single file component system and the ability to function both as in-browser SPA views (single page application) and as server-rendered web views which can be rehydrated to full SPA functionality."
        next={true}
        firstline={true}
        secline={true}
        thirdline={true}
        line1="      Apart from that, the framework also enables users to view
                                contents or parts of content fully server pre-rendered
                                and served in a static site generation manner. This
                                approach ensures reduced interactivity time and better
                                SEO when compared to traditional SPAs because the full
                                content of the page is served by the server before any
                                client-side JS-action can be executed."
        line2="  To be more precise, Nuxt.js can enable developers to
                                maintain both the positives that come from traditional
                                server-side rendered pages and the advanced user interface
                                of Single Page Apps, not to mention the improved interactivity
                                they often provide."
        line3="  What the Nuxt.js framework does best is simplifying the
                                app setup and configuration enabling them to develop the
                                UI portions of the app faster and more streamlined, like
                                they would in a Vue.js single file application."
      />
      <CodingCard
        list={true}
        grad={true}
        second={true}
        image_main={stacks}
        heading="Next.js:"
        detail="Next.js is a JavaScript framework that allows developers to come up with server-side views and static web applications using React. It allows for a very simple and straightforward project journey since there's no need for configuring the basics, instead, the initial stages of the projects can be done by executing a few short and simple commands."
        line1=" Next.js was inspired by PHP along with all the beneficial features of other JavaScript modules that allow developers to export different app components, testing said components, and downloading new ones."
        oneline={true}
      />
      <CodingCard
        list={true}
        grad={true}
        second={true}
        image_main={stacks}
        heading="Nest.js:"
        detail="Nest (NestJS) is a framework for building efficient, scalable Node.js server-side applications. It uses progressive JavaScript, is built with and fully supports TypeScript (yet still enables developers to code in pure JavaScript) and combines elements of OOP (Object Oriented Programming), FP (Functional Programming), and FRP (Functional Reactive Programming)."
        line1="  Under the hood, Nest makes use of robust HTTP Server frameworks like Express (the default) and optionally can be configured to use Fastify as well!"
        line2=" Nest provides a level of abstraction above these common Node.js frameworks (Express/Fastify), but also exposes their APIs directly to the developer. This gives developers the freedom to use the myriad of third-party modules which are available for the underlying platform."
        oneline={true}
        secline={true}
      />

      <div className={`${styles.margining_top}`}></div>
      <GotProject every={true} />
      <Footer contacting={true} />
    </>
  );
};

export default Freamework;
