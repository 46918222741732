import React from "react";
import styles from "./CaseStudySolution.module.css";

const CaseStudySolution = ({ detail1, detail2, image, color }) => {
  return (
    <>
      <section
        className={`${styles.casestudy_solutions} ${
          color === "red"
            ? `${styles.redcolor}`
            : color === "aquacolor"
            ? `${styles.aquacolor}`
            : color === "yellowcolor"
            ? `${styles.yellowcolor}`
            : color === "greencolor"
            ? `${styles.greencolor}`
            : ``
        }`}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-8">
              <h2 className={`font-weight-700 ${styles.solutions_heading}`}>
                <span className={`${styles.casestudy_solutions_span}`}></span>
                Solutions
              </h2>
              <p>{detail1}</p>
              <p>{detail2}</p>
            </div>
            <div className="col-lg-4">
              <div className={`${styles.solution_thumb}`}>
                <img alt="" src={image} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CaseStudySolution;
