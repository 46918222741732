import React from "react";
import styles from "./CareerDetailReq.module.css";
const CareerDetailReq = ({ title, content, heading }) => {
  return (
    <>
      <div className={`${styles.career_requirement}`}>
        <h2 className="font-weight-700">{title}</h2>
        <h4 className="font-weight-400 text--web">{content}</h4>
        <h3 className="font-weight-700">{heading}</h3>
      </div>
    </>
  );
};

export default CareerDetailReq;
