import dropdowns1 from "../../images/dropdown-s1.svg";
import dropdowns2 from "../../images/dropdown-s2.svg";
import dropdowns3 from "../../images/dropdown-s3.svg";
import dropdowns4 from "../../images/dropdown-s4.svg";
import dropdowns5 from "../../images/dropdown-s5.svg";
import dropdowns6 from "../../images/dropdown-s6.svg";
import dropdowns7 from "../../images/dropdown-s7.svg";

export const navitems = [
  {
    id: 1,
    title: "Home",
    url: "/",
  },
  {
    id: 2,
    title: "Our Work",
    url: "/our-work",
  },
  {
    id: 3,
    title: "Services",
    url: "/services",
  },
  {
    id: 4,
    title: "Industries",
    url: "/industries",
  },
  // {
  //   id: 5,
  //   title: "Technologies",
  //   url: "/tech",
  // },
  {
    id: 6,
    title: "Company",
    url: "/about-us",
  },
  {
    id: 7,
    title: "Contact Us",
    url: "/contact-us",
  },
];
export const techdropdown = [
  {
    id: 1,
    title: "Coding Language",
    url: "/Coding",
  },
  {
    id: 2,
    title: "Frame Works",
    url: "/Framework",
  },
  {
    id: 3,
    title: "Database",
    url: "/Database",
  },
  {
    id: 4,
    title: "Hosting",
    url: "/Hosting",
  },
  {
    id: 5,
    title: "Web Technologies",
    url: "/Web-Technologies",
  },
  {
    id: 6,
    title: "Testing/Quality",
    url: "/Testing/Quality",
  },

  {
    id: 7,
    title: "Integrations",
    url: "/Integration",
  },
];
export const servicedropdown = [
  {
    id: 1,
    title: "Digital Product Design",
    url: "/services/digital-product-design",
    display: dropdowns1,
  },
  {
    display: dropdowns2,
    id: 2,
    title: "Agile Developments Teams On Demand",
    url: "/services/agile-development",
  },
  {
    id: 3,
    title: "Web Development",
    url: "/services/web-development",
    display: dropdowns3,
  },
  {
    id: 4,
    title: "Mobile Development",
    url: "/services/mobile-development",
    display: dropdowns4,
  },
  {
    id: 5,
    title: "Software Architecture",
    url: "/services/software-architecture",
    display: dropdowns5,
  },
  {
    id: 6,
    title: "Custom Software Development",
    url: "/services/custom-software-development",
    display: dropdowns6,
  },

  {
    id: 7,
    title: "Quality Assurance As a Service",
    url: "/services/quality-assurance",
    display: dropdowns7,
  },
];
export const companydropdown = [
  {
    id: 1,
    title: "About Us",
    url: "/about-us",
  },
  {
    id: 2,
    title: "Career",
    url: "/career",
  },
];
