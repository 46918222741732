import React from "react";
import { Link } from "react-router-dom";
import styles from "./IndustriesBanner.module.css";
const IndustriesBanner = ({
  heading,
  subheading,
  detail,
  ind_btn_text,
  industry_btn,
  background_image,
}) => {
  return (
    <>
      <section
        className={`${
          background_image === "main"
            ? `${styles.industries_banner} `
            : background_image === "ecommerce"
            ? `${styles.industry_detail_banner}`
            : background_image === "education"
            ? `${styles.industry_detail_banneredu}`
            :background_image === "realEstate"
            ? `${styles.industry_detail_bannerrealEstate}`
            :background_image === "health"
            ? `${styles.industry_detail_bannerhealth}`
            :background_image === "travel"
            ? `${styles.industry_detail_bannertravel}`
            :
            ``
        }`}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className={`${styles.pagecontent}`}>
                <h4
                  className={`text--gradient pagetitle text-uppercase font-weight-700  ${styles.indust}`}
                >
                  {heading}
                </h4>
                <h1
                  className={`text-capitalize ${
                    background_image === "main"
                      ? `${styles.main_subheading}`
                      :
                       background_image === "ecommerce"
                      ? `${styles.ecomm_subheading}`
                      : 
                      background_image === "education"
                      ? `${styles.edu_subheading}`
                      : 
                      background_image === "realEstate"
                      ? `${styles.edu_subheading}`
                      : background_image === "health"
                      ? `${styles.edu_subheading}`
                      : background_image === "travel"
                      ? `${styles.edu_subheading}`
                      :
                      ``
                  }`}
                >
                  {subheading}
                </h1>
                <h4
                  className={`font-weight-400 text-white ${
                    background_image === "main"
                      ? `${styles.main_subdetail}`
                      : background_image === "ecommerce"
                      ? `${styles.ecomm_subdetail}`
                      : background_image === "education"
                      ? `${styles.edu_subdetail}`
                      : background_image === "realEstate"
                      ? `${styles.edu_subdetail}`
                      :  background_image === "health"
                      ? `${styles.edu_subdetail}`
                      : background_image === "travel"
                      ? `${styles.edu_subdetail}`
                      :
                      ``
                  }`}
                >
                  {detail}
                </h4>
                {industry_btn === true && (
                  <Link
                    className={`btn--gradient ${styles.all_btn}`}
                    to="/"
                  >
                    {ind_btn_text}
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default IndustriesBanner;
