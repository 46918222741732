import React from "react";
import "./QualityCard.css";
export const QualityCard = ({ title, image }) => {
  return (
    <>
      <div className="col-4">
        <div className="experty-container bg-white">
          <img src={image} alt="" />
        </div>
        <p className="font-weight-600 mt-1 mb-0">{title}</p>
      </div>
    </>
  );
};
