import React from "react";
import case_vector from "../../images/banner-yellow.png";

import solution_png from "../../images/solutions-yellow.png";

import feature_big from "../../images/veil.jpg";

import { CaseStudyCard } from "../../components/CaseStudy/CaseStudyCard";

import { TechStackCard } from "../../components/CaseStudy/TechStackCard";

import styles from "./YellowCase.module.css";

import { OfficeDetail } from "../../components/Office/OfficeDetail";
import Office from "../../components/Office/Office";
import GotProject from "../../components/Got_Project/GotProject";
import Footer from "../../components/Footer/Footer";
import { TabTitle } from "../../Utils/GeneralFunction";

import CaseStudyBanner from "../../components/CaseStudy/CaseStudyBanner";
import CaseStudyQuote from "../../components/CaseStudy/CaseStudyQuote";
import CaseStudySummary from "../../components/CaseStudy/CaseStudySummary";
import CaseStudySolution from "../../components/CaseStudy/CaseStudySolution";
import CaseStudyFeature from "../../components/CaseStudy/CaseStudyFeature";
const YellowCase = () => {
  TabTitle("Our Work | Behind The Veil");
  return (
    <>
      <div className="yellowcolor">
        <CaseStudyBanner
          images={case_vector}
          color="yellowcolor"
          title=" Behind the Veil is an event organizing website. It offers multiple services and venues for making weddings full of thrill and excitement."
          content="It offers visa & passport services to the people of diverse nationalities who are willing to travel to any country across the globe. "
          customer="Behind the Veil"
          disarrow={true}
          // customer_site="behindtheveil.com"
          linking="https://yabee.me/"
        />

        <section className={` ${styles.casestudy_columns} position-relative`}>
          <div className={`${styles.box_design} container`}>
            <div className="row">
              <div className={`col-lg-6 ${styles.casestudy_intro}`}>
                <CaseStudyCard
                  heading="Business Need"
                  details="iSoft Technologies doesn’t believe only in making its clients happy but their customers too, so the website was developed according to the point of view of the person getting married, every service was mentioned along with its pictures and crucial details. The development of the website was done by keeping its purpose in mind and it was made appealing but technical at the same time."
                />
              </div>
              <div className={`col-lg-6 ${styles.casestudy_intro}`}>
                <CaseStudyCard
                  heading="Result"
                  details="An awesome website was delivered which had all the elements related to its purpose and theme. It had a wonderful design along with smart technicalities. The performance ability of the website was really good and it was going well with the business needs of the company."
                />
              </div>
            </div>
          </div>
        </section>
        <CaseStudyQuote color="yellowcolor" />

        <section className={`${styles.casestudy_summary}`}>
          <div className="container">
            <div className="row">
              <CaseStudySummary
                color="yellowcolor"
                summary={true}
                heading="Summary"
                detail1="Behind the Veil” is an event organizing website. It offers multiple services and venues for making bride and bridegroom’s special day more exciting and joyful. The website basically has a whole list of magnificent halls and banquets where anyone can arrange their weddings. Pictures of the venues are present so that people can choose the one which fulfils their requirements, also they get a feeling that they are actually present at the venue and observing its interior, and this is how the decision-making process gets easier for them. For the convenience of the customers, the capacity and price of the venue is mentioned too. Not only this, wedding cakes are also available there so that a pinch of sweetness is added to one’s beautiful wedding day. Moreover, tremendous decorations are done according to the demands of the customer and pictures of decorated venues are given. Details regarding catering services are present on the website as well so deciding the menu for the guests isn’t difficult either.



"
                detail2="Contact details of the caterers, wedding planners and suppliers are also provided. People register themselves on the website and get their wedding arranged, they have an option to get the whole wedding managed by Behind the Veil or they can simply avail any single offer as well. Quotations are sent to the interested customers by the suppliers for giving them the estimate of the costs regarding the services they have asked for."
              />

              <CaseStudySummary color="yellowcolor" location={true} />
              <CaseStudySummary
                challenge={true}
                color="yellowcolor"
                content=<div className={`${styles.yellowcolor} `}>
                  <h3 className={`${styles.highlighted_txt}`}>
                    We know that a wedding day is the most special, exciting and
                    amazing day of everyone’s life and that is why the main
                    <span> challenge faced by iSoft Technologies </span>
                    while <span>developing the website</span> was to make it
                    thrilling. It was so crucial to add the{" "}
                    <span>element of excitement in the website</span> in order
                    to make it truly an event organizing platform and providing
                    an exceptional user experience to the customers.
                    <span>Furthermore, </span>giving easy way to suppliers for
                    <span> quotation management</span> was equally important so
                    that no inconvenience occurs.
                  </h3>
                </div>
              />
            </div>
          </div>
        </section>

        <CaseStudySolution
          image={solution_png}
          color="yellowcolor"
          detail1="ISoft Technologies incorporated a wonderful fusion of colors in the website for making it attractive, the component of thrill was also added by efficiently putting the pictures of the venues, cakes and food in the form of different pages along with superb transitions and themes. Boxes were made in which the details regarding the particular service were given so that the customer doesn’t get confused or have to contact the supplier just for getting an idea of the prices.
"
        />
        <CaseStudyFeature
          color="yellowcolor"
          plane={true}
          imagemain={feature_big}
          detail1="Being an event organizing website, Behind the Veil has everything that a person who is getting married requires for his wedding functions like banquets, cakes, food, decorations and much more. Clear and high-quality pictures of all the services are provided to the customers along with the price range. Additionally, contact numbers of suppliers, wedding planners, and caterers are also given for facilitating the customers. People can register themselves by simply connecting with the supplier or by filling a simple form. They can also get quotations regarding the services they are interested in or for getting their whole wedding planned by Behind the Veil."
        />
        <section className={`${styles.our_techs}`}>
          <div className="container">
            <h2 className="font-weight-700">Tech Stack</h2>
            <div className="row">
              <TechStackCard
                color="yellowcolor"
                title="PHP"
                detail="PHP is utilized by 78.9% of the websites all over the world. This open-source scripting language"
              />
              <TechStackCard
                color="yellowcolor"
                title="Laravel"
                detail="Laravel is one of the most popular PHP backend frameworks as its powerful built-in system makes it simpler to "
              />
              <TechStackCard
                color="yellowcolor"
                title="Vue.js"
                detail="Vue is a JavaScript framework for building user interfaces. It builds on top of standard HTML, CSS and Javascript."
              />
              <TechStackCard
                color="yellowcolor"
                title="MySQL"
                detail="The world’s most popular database, MySQL greatly benefits businesses because of its "
              />
              <TechStackCard
                color="yellowcolor"
                title="Saas"
                detail="SASS is a CSS (cascading style sheet) pre-processor that assists with lessening redundancies in CSS."
              />
              <TechStackCard
                color="yellowcolor"
                title="Oauth 2.0"
                detail="OAuth 2.0 is the industry-standard protocol for authorization. it focuses on client developer simplicity while"
              />
            </div>
          </div>
        </section>
        <section className="our-office margin-bottom">
          <div className="container">
            <div className="office-container text-white text-center">
              <OfficeDetail
                title="Gallery of Project"
                details="We're incredibly lucky to workwith partners from a wide spectrum of industries who shre our enthusiasm and commitment towards delivering results."
              />
            </div>
          </div>
          <div className="office-swiper">
            {" "}
            <Office study={true} yellowcase={true} />
          </div>
        </section>
      </div>
      <GotProject every={true} />
      <Footer contacting={true} />
    </>
  );
};

export default YellowCase;
