import React from "react";
import styles from "./DbCard.module.css";
import stacks from "../../images/stacks.svg";
const DbCard = ({
  sql,
  image,
  title,
  detail,
  list1,
  list2,
  sec_title,
  sec_detail,
  list3,
  list4,
  listing1,
  listing2,
  hosting,
  next,
  firstline,
  secline,
  thirdline,
  line1,
  line2,
  line3,
  bg_image,
  duo_list,
}) => {
  return (
    <>
      {sql === true ? (
        <section className={`${styles.language_section}`}>
          <div className="container">
            <div className={`row ${styles.language_tech}`}>
              <div className="col-lg-12">
                <span className={`${styles.tech_icon}`}>
                  <img alt="" src={image} />
                </span>
                <h2 className="font-weight-700 mb-3">{title}</h2>
              </div>
              <div className="col-lg-6">
                <h3 className="font-weight-400 text--web txt">{detail}</h3>
              </div>
              <div className="col-lg-6">
                <ul className="arrow-list mt-lg-0 mt-4">
                  <li>
                    <p
                      className={`font-weight-400 text--web ${styles.margining_left}`}
                    >
                      {list1}
                    </p>
                  </li>
                  {duo_list === true && (
                    <li>
                      <p
                        className={`font-weight-400 text--web ${styles.margining_left}`}
                      >
                        {list2}
                      </p>
                    </li>
                  )}
                </ul>
              </div>
            </div>
            {hosting === true ? (
              <>
                <div className={`${styles.hosting_margin}`}></div>
                <div className={`row ${styles.language_tech}`}>
                  <div className="col-lg-12">
                    <span className={`${styles.tech_icon}`}>
                      <img alt="" src={image} />
                    </span>
                    <h2 className="font-weight-700 mb-3">{sec_title}</h2>
                  </div>
                  <div className="col-lg-6">
                    <h3 className="font-weight-400 text--web txt">
                      {sec_detail}
                    </h3>
                  </div>
                  <div className="col-lg-6">
                    <ul className="arrow-list mt-lg-0 mt-4">
                      <li>
                        <p
                          className={`font-weight-400 text--web ${styles.margining_left}`}
                        >
                          {list3}
                        </p>
                      </li>
                      {duo_list === true && (
                        <li>
                          <p
                            className={`font-weight-400 text--web ${styles.margining_left}`}
                          >
                            {list4}
                          </p>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </>
            ) : (
              <div className="row">
                <div className={`col-lg-12 ${styles.language_tech} mt-5`}>
                  <span className={`${styles.tech_icon}`}>
                    <img alt="" src={stacks} />
                  </span>
                  <h2 className="font-weight-700 mb-3">{sec_title}</h2>
                  <h3 className="font-weight-400 text--web txt mb-4">
                    {sec_detail}
                  </h3>
                  <ul className="arrow-list">
                    {listing1 === true && (
                      <li>
                        <p
                          className={`font-weight-400 text--web ${styles.margining_left}`}
                        >
                          {list3}
                        </p>
                      </li>
                    )}
                    {listing2 === true && (
                      <li>
                        <p
                          className={`font-weight-400 text--web ${styles.margining_left}`}
                        >
                          {list4}
                        </p>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            )}
          </div>
        </section>
      ) : (
        <section
          className={
            bg_image === true
              ? `${styles.withbg} ${styles.codinglang}`
              : `${styles.codinglang} bg--gradient`
          }
        >
          <div className="container">
            <div className="row">
              <div className={`col-lg-12 ${styles.language_tech}`}>
                <span className={`${styles.tech_icon}`}>
                  <img alt="" src={image} />
                </span>
                <h2 className="font-weight-700 mb-3 text-white">{title}</h2>
                <h3 className="font-weight-400 text-white txt mb-4">
                  {detail}
                </h3>
                {next === true && (
                  <ul className="arrow-list text-white">
                    {firstline === true && (
                      <li>
                        <p
                          className={`font-weight-400  ${styles.margining_left}`}
                        >
                          {line1}
                        </p>
                      </li>
                    )}
                    {secline === true && (
                      <li>
                        <p
                          className={`font-weight-400  ${styles.margining_left}`}
                        >
                          {line2}
                        </p>
                      </li>
                    )}
                    {thirdline === true && (
                      <li>
                        <p
                          className={`font-weight-400  ${styles.margining_left}`}
                        >
                          {line3}
                        </p>
                      </li>
                    )}
                  </ul>
                )}
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default DbCard;
