import React from "react";

import { CaseStudies } from "../../components/HomeScreen/CaseStudies";

import styles from "./AllCaseStudies.module.css";
import GotProject from "../../components/Got_Project/GotProject";
import Footer from "../../components/Footer/Footer";
import TechBanner from "../../components/Banner/TechBanner";
import casestudies from "../../images/casestudy.png";

import { TabTitle } from "../../Utils/GeneralFunction";
import {
  casestudyfilter,
  products,
} from "../../components/CaseStudy/casestudyfilter";
import { useState } from "react";

const AllCaseStudies = () => {
  TabTitle("iSoft Technologies - Our Work");
  const [data, setData] = useState(products);
  const filterResults = (productitem) => {
    const results = products.filter((curprod) => {
      return curprod.category === productitem;
    });
    setData(results);
  };
  const [name, setName] = useState("All");
  const [active, setActive] = useState("All");

  return (
    <>
      <TechBanner
        image={casestudies}
        detail="  We collaborate with innovative companies at every stage, 
                            acting as a partner as they accelerate and meet their goals.
                             Take a look at some of our favorite projects to date."
        heading=" Our Work"
        btn_detail="Get started!"
        link_to="/contact-us"
      />
      <section className={`${styles.casestudies_all}`}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="casestudy-heading">
                <h2 className="font-weight-700">Take a look at our projects</h2>
                <h4 className={`${styles.commonline} text--web`}>
                  Not just services, it’s a Process
                </h4>
              </div>
              <div className={`${styles.filters_buttons}`}>
                <ul>
                  <li
                    className={`${styles.filter_button} ${
                      active === "All" && `${styles.links}`
                    }`}
                    onClick={() => {
                      setData(products);
                      setActive("All");
                    }}
                  >
                    All
                  </li>
                  {casestudyfilter.map((items) => {
                    return (
                      <li
                        className={`${styles.filter_button} ${
                          active === items && `${styles.links}`
                        } `}
                        key={items.id}
                        onClick={() => {
                          filterResults(items.title);
                          setActive(items);
                        }}
                      >
                        {items.title}
                      </li>
                    );
                  })}
                </ul>
                <div className="dropdown dropdown-filters">
                  <li
                    className="dropdown-toggle"
                    role="button"
                    id="dropFilter"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {name}
                  </li>
                  <ul className="dropdown-menu">
                    <li
                      className="filter-button active"
                      onClick={() => {
                        setData(products);
                        setName("All");
                      }}
                    >
                      All
                    </li>
                    {casestudyfilter.map((items) => {
                      return (
                        <li
                          className="filter-button"
                          key={items.id}
                          onClick={() => {
                            filterResults(items.title);
                            setName(items.title);
                          }}
                        >
                          {items.title}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            {data.map((product) => {
              return (
                <div key={product.id} className="col-lg-6">
                  <CaseStudies
                    headingpri={product.p_heading_pri}
                    detailspri={product.p_detail_pri}
                    image={product.p_image}
                    headingsec={product.p_heading_sec}
                    detailssec={product.p_detail_sec}
                    linking={product.url}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </section>

      <GotProject every={true} />
      <Footer contacting={true} />
    </>
  );
};

export default AllCaseStudies;
