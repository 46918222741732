import React from "react";
import styles from "./OfficeCard.module.css";
export const OfficeCard = ({ image, study }) => {
  return (
    <>
      <div className={`${styles.swiper_height} swiper-slide`}>
        <div
          className={
            study === true
              ? `${styles.office_slide_study}`
              : `${styles.office_slide}`
          }
        >
          <img className={`${styles.office_img}`} alt="" src={image} />
        </div>
      </div>
    </>
  );
};
