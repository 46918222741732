import React from "react";

export const AboutTabsCard = ({ title, detail }) => {
  return (
    <>
      <span className="tb-text">{title}</span>
      <span className="tb-number">{detail}</span>
    </>
  );
};
