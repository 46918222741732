import React from "react";
import styles from "./MainTextCard.module.css";
import vector2 from "../../images/vector2.png";
import vector4 from "../../images/vector4.png";
const MainTextCard = ({ page, detail }) => {
  return (
    <>
      <section
        className={
          `${page === "ecomm"}`
            ? `${styles.industry_commerece_section}`
            : `${styles.company_section}`
        }
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className={`${page === "ecomm"} ` ? `col-lg-12` : `col-xl-11`}>
              <div
                className={`hilighted-text text-center ${styles.hilighted_text_main}`}
              >
                <span className={`${styles.vector1}`}>&nbsp;</span>
                <span className={`${styles.vector2}`}>
                  <img alt="" src={vector2} />
                </span>
                <span className={`${styles.vector3}`}>&nbsp;</span>
                <span className={`${styles.vector4}`}>
                  <img alt="" src={vector4} />
                </span>
                {detail}
              </div>
              <hr className="m-0"></hr>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MainTextCard;
