export const FooterItems = [
  {
    id: 1,
    title: "Home",
    url: "/",
  },
  {
    id: 2,
    title: "Our Work",
    url: "/our-work",
  },
  {
    id: 3,
    title: "Services",
    url: "/services",
  },
  {
    id: 4,
    title: "Industries",
    url: "/industries",
  },
  // {
  //   id: 5,
  //   title: "Technologies",
  //   url: "/tech",
  // },
  {
    id: 6,
    title: "About Us",
    url: "/about-us",
  },
  {
    id: 7,
    title: "Contact Us",
    url: "/contact-us",
  },
];
