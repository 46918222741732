import React from "react";
import heads from "../../images/heads.svg";
import notepad from "../../images/notepad.svg";
import banner_service from "../../images/service1.png";
import test from "../../images/tests.svg";
import experty1 from "../../images/experty1.png";
import mobile_dev from "../../images/mobile-dev.png";
import experty2 from "../../images/experty2.png";
import experty7 from "../../images/experty7.png";
import experty9 from "../../images/experty9.png";
import experty3 from "../../images/experty3.png";
import mobiledev from "../../images/mobiledev2.png";
import { DevelopmentCard } from "../../components/Sevice/DevelopmentCard";
import { QualityCard } from "../../components/Sevice/QualityCard";
import { DigitalProductCard } from "../../components/Sevice/DigitalProductCard";
import styles from "./servicespage.module.css";
import GotProject from "../../components/Got_Project/GotProject";
import Footer from "../../components/Footer/Footer";
import qa1 from "../../images/qa1.svg";
import qa2 from "../../images/qa2.svg";
import qa3 from "../../images/qa3.svg";
import qa4 from "../../images/qa4.svg";
import qa5 from "../../images/qa5.svg";
import qa6 from "../../images/qa6.svg";
import software1 from "../../images/software1.svg";
import software2 from "../../images/software2.svg";
import software3 from "../../images/software3.svg";
import software4 from "../../images/software4.svg";
import { Link } from "react-router-dom";
import { TabTitle } from "../../Utils/GeneralFunction";
const Service = () => {
  TabTitle("iSoft Technologies - Services");
  return (
    <>
      <section className={`${styles.services_banner}`}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className={`${styles.pagecontent}`}>
                <h4
                  className={`text--gradient ${styles.pagetitle} font-weight-700`}
                >
                  Providing all tech development services under one roof
                </h4>
                <h1>Transforming Your Idea Through Technology.</h1>
                <h4 className="font-weight-400 text--light">
                  Powering full cycle custom development services for past 12
                  years. Get partnered with our tech rock stars such as cloud
                  architects, frontend/backend developers, product designers.
                </h4>
              </div>
            </div>
            <div className="col-lg-6">
              <div className={`${styles.service_banner_thumb}`}>
                <img alt="" src={banner_service} />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={`${styles.text_section}`}>
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <h4 className="font-weight-400 text--web">
                Powering full cycle custom development services for past 12
                years. Get partnered with our tech rock stars such as cloud
                architects, frontend/backend developers, product designers.
              </h4>
            </div>
            <div className="col-lg-6">
              <div className={`${styles.img_section}`}>
                <img alt="" src={banner_service} />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={`${styles.digitalproducts}`}>
        <div className="container">
          <div className="row ">
            <div
              className={`col-xl-5 col-lg-6 ${styles.digitalproducts_inner}`}
            >
              <h2 className="font-weight-700">
                <span>Digital</span> Product Design
              </h2>
              <p className="text--web font-weight-600">
                Transforming crude Idea into redefined Product.
              </p>
              <h4 className="font-weight-400 text--web">
                As a product designing company, we designer will put life into
                your raw idea. We offer hi-tech design according to your needs.
                From the business analysis and discovery stage to the last
                button that makes User Experience, we provide services as a team
                that forms your product using transparent plan processes and
                delivering fabulous results.
              </h4>
              <Link
                className="text--gradient btn-digitalproducts font-weight-700"
                to="/services/digital-product-design"
              >
                Learn more <i className="fa-solid fa-arrow-right-long"></i>
              </Link>
            </div>
            <div className="col-xl-6 offset-xl-1 col-lg-6">
              <div className={`${styles.digital_lem_wrapp}`}>
                <DigitalProductCard
                  image={heads}
                  title="Empathize"
                  heading="01"
                  even={true}
                />
                <DigitalProductCard
                  image={notepad}
                  title="Define"
                  heading="02"
                />
                <DigitalProductCard
                  even={true}
                  image={heads}
                  title="Ideate"
                  heading="03"
                />
                <DigitalProductCard
                  image={notepad}
                  title="Prototype"
                  heading="04"
                />
                <DigitalProductCard
                  even={true}
                  image={test}
                  title="Test"
                  heading="05"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className={`${styles.agile_development} ${styles.dev_agile} ${styles.dev_strategies}`}
      >
        <div className="container">
          <div className="row">
            <div
              className={`col-xl-5 col-lg-6 exp-wrap ${styles.col_oder_last} ${styles.text_start}`}
            >
              <p className="font-weight-600 mt-0 text-start">Web development</p>
              <div className={`${styles.experty_wrapp} row`}>
                <DevelopmentCard image={experty7} />
                <DevelopmentCard image={experty9} />
                <DevelopmentCard image={experty1} />
              </div>
              <p className="font-weight-600 mt-5 text-start">
                Mobile Development
              </p>
              <div className={`${styles.experty_wrapp} row`}>
                <DevelopmentCard image={experty1} />
                <DevelopmentCard image={mobiledev} />
              </div>
            </div>
            <div
              className={`col-xl-5 offset-xl-2 col-lg-6 ${styles.col_oder_fst}`}
            >
              <h2 className="font-weight-700">
                Agile Development Teams On Demand
              </h2>
              <p className="text--web font-weight-600">
                Fill the talent gap for your custom software projects in a few
                weeks
              </p>
              <h4 className="font-weight-400 text--web">
                With access to over 170+ proactive and social professionals, you
                can form a durable development team with frontend, backend, or
                cloud services that can assist you for years.
              </h4>
              <h4 className="font-weight-400 text--web pb-3">
                In their work, they follow 9 years of best custom software
                development practices we documented. You’ll be positively
                surprised with the very first sprint.
              </h4>
              <Link
                className="text--gradient btn-digitalproducts font-weight-700"
                to="/services/agile-development"
              >
                Learn more <i className="fa-solid fa-arrow-right-long"></i>
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section className={`${styles.web_development} ${styles.dev_strategies}`}>
        <div className={`container ${styles.vec_img}`}>
          <div className="row">
            <div className="col-xl-5 col-lg-6">
              <h2 className="font-weight-700">Web Development</h2>
              <p className="text--web font-weight-600">
                Crafters of custom made Web development solution For digital
                future.
              </p>
              <h4 className="font-weight-400 text--web">
                As a custom web application development company, we offer web
                application development services to provide any kind of
                enterprise or Industry with one-of-a-kind web products.
              </h4>
              <h4 className="font-weight-400 text--web pb-3">
                With custom software solutions, we help businesses build user
                confidence and turn site visitors into paying customers.
              </h4>
              <Link
                className="text--gradient btn-digitalproducts font-weight-700"
                to="/services/web-development"
              >
                Learn more <i className="fa-solid fa-arrow-right-long"></i>
              </Link>
            </div>
            <div
              className={`col-xl-5 offset-xl-2   col-lg-6 ${styles.text_start}`}
            >
              {/* <p className="font-weight-600 mt-0">Web development</p> */}
              <div className={`${styles.experty_wrapp} row`}>
                <DevelopmentCard image={experty7} />
                <DevelopmentCard image={experty9} />
                <DevelopmentCard image={experty1} />
              </div>
              {/* <p className="font-weight-600 mt-5">Mobile Development</p> */}
              <div className={`${styles.experty_wrapp} row`}>
                <DevelopmentCard image={experty3} />
                <DevelopmentCard image={experty2} />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={`${styles.mob_development} ${styles.dev_strategies}`}>
        <div className={`container ${styles.vec_img}`}>
          <div className="row">
            <div className={`col-xl-5 col-lg-6 ${styles.col_oder_last}`}>
              <div className={`${styles.mob_dev_thumb}`}>
                <img alt="" src={mobile_dev} />
              </div>
            </div>
            <div
              className={`col-xl-5 offset-xl-2 col-lg-6 ${styles.col_oder_fst}`}
            >
              <h2 className="font-weight-700 text-white">Mobile Development</h2>
              <p className="text--light font-weight-600">
                Mobile development services That hasten product Development &
                growth
              </p>
              <h4 className="font-weight-400 text-white">
                We'll assist you with planning, create, and publish an iOS or
                Android software that will get general praise for its client
                experience - we've done it before with different complex
                projects.
              </h4>
              <h4 className="font-weight-400 text-white">
                It tends to be a part based native application or hybrid
                application that will make your clients coming back. For sure,
                our mobile development services will fit right in.
              </h4>
              <Link
                className="text--gradient btn-digitalproducts font-weight-700"
                to="/services/mobile-development"
              >
                Learn more <i className="fa-solid fa-arrow-right-long"></i>
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section
        className={`${styles.web_development} ${styles.dev_strategies} ${styles.software_arch}`}
      >
        <div className={`container ${styles.vec_img}`}>
          <div className="row">
            <div className="col-xl-5 col-lg-6">
              <h2 className="font-weight-700">Software Architecture</h2>
              <p className="text--web font-weight-600">
                Helpers in changing Business goals with our Software
                architecture services
              </p>
              <h4 className="font-weight-400 text--web">
                Technology moves rapid than ever, and you want to get up to
                catch. Higher versatility, execution, and cost streamlining
                thanks to volatile software architecture development.
              </h4>
              <h4 className="font-weight-400 text--web pb-3">
                Our software architects will assist you to establish a secure,
                adaptable, and proficient software development process that
                addresses your needs.
              </h4>
              <Link
                className="text--gradient btn-digitalproducts font-weight-700"
                to="/services/software-architecture"
              >
                Learn more <i className="fa-solid fa-arrow-right-long"></i>
              </Link>
            </div>
            <div
              className={`col-xl-5 offset-xl-2 exp-wrap col-lg-6 ${styles.text_start}`}
            >
              <p className="font-weight-600 mt-0 text-start">Web development</p>
              <div className={`${styles.experty_wrapp} row`}>
                <QualityCard image={software1} title="Microservices" />
                <QualityCard image={software2} title="Serverless" />
                <QualityCard image={software3} title="Re-architecting" />
              </div>
              <p className="font-weight-600 mt-5 text-start">
                Mobile Development
              </p>
              <div className={`${styles.experty_wrapp} row`}>
                <QualityCard image={software4} title="Cloud Migration" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className={`${styles.agile_development} ${styles.dev_strategies} ${styles.customs_development}`}
      >
        <div className={`container ${styles.vec_img}`}>
          <div className="row">
            <div
              className={`col-xl-5 col-lg-6 exp-wrap ${styles.col_oder_last} ${styles.text_start}`}
            >
              {/* <p className="font-weight-600 mt-0">Web development</p> */}
              <div className={`${styles.experty_wrapp} row`}>
                <DevelopmentCard image={experty7} />
                <DevelopmentCard image={experty9} />
                <DevelopmentCard image={experty1} />
              </div>
              {/* <p className="font-weight-600 mt-5">Mobile Development</p> */}
              <div className={`${styles.experty_wrapp} row`}>
                <DevelopmentCard image={experty3} />
                <DevelopmentCard image={experty2} />
              </div>
            </div>
            <div
              className={`col-xl-5 offset-xl-2 col-lg-6 ${styles.col_oder_fst}`}
            >
              <h2 className="font-weight-700">Custom Software Development</h2>
              <p className="text--web font-weight-600">
                Your desired scalable team For stem to stern Development
              </p>
              <h4 className="font-weight-400 text--web">
                Get your custom software architected and created by specialists.
                Speed up your timeline for go-to-market. Software upholds your
                business development, and you really want more from it.
              </h4>
              <h4 className="font-weight-400 text--web pb-3">
                We've assisted over 20+ businesses with building versatile
                products with certainty. Technology executives chose our
                development experts due to their skills tested in 4+ industries.
              </h4>
              <Link
                className="text--gradient btn-digitalproducts font-weight-700"
                to="/services/custom-software-development"
              >
                Learn more <i className="fa-solid fa-arrow-right-long"></i>
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section
        className={`${styles.web_development} ${styles.dev_strategies} ${styles.software_qa}`}
      >
        <div className={`container ${styles.vec_img}`}>
          <div className="row">
            <div className="col-xl-5 col-lg-6">
              <h2 className="font-weight-700">
                Quality Assurance As a Service
              </h2>
              <p className="text--web font-weight-600">
                Ensuring quality & success Of your product
              </p>
              <h4 className="font-weight-400 text--web">
                Oversee risk by preventing issues and defects. Protect your
                products quality and success. Keep an astonishing encounter that
                your clients can depend on. Manual, automated, accessibility,
                compatibility, execution. Our QA specialists are in well
                knowledge regarding all parts of the QA process.
              </h4>
              <Link
                className="text--gradient btn-digitalproducts font-weight-700"
                to="/services/quality-assurance"
              >
                Learn more <i className="fa-solid fa-arrow-right-long"></i>
              </Link>
            </div>
            <div
              className={`col-xl-5 offset-xl-2 exp-wrap col-lg-6 ${styles.col_last}`}
            >
              <div className={`${styles.experty_wrapp} row text-center`}>
                <QualityCard image={qa1} title="Business analysis" />
                <QualityCard image={qa2} title="Manual testing" />
                <QualityCard image={qa3} title="Test automation" />
              </div>
              <div className={`${styles.experty_wrapp} row text-center mt-3`}>
                <QualityCard image={qa4} title="Performance testing" />
                <QualityCard image={qa5} title="Mobile apps testing" />
                <QualityCard image={qa6} title="Security testing" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <GotProject every={true} />
      <Footer contacting={true} />
    </>
  );
};

export default Service;
