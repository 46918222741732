import React from "react";
import Banner from "../../components/Banner/Banner";
import experty1 from "../../images/experty1.png";
import android from "../../images/android.png";
import Tabs from "../../components/Tabs/Tabs";
import apple from "../../images/apple.png";
import work1 from "../../images/mobile2.jpg";
import styles from "./MobileDev.module.css";
import Footer from "../../components/Footer/Footer";
import GotProject from "../../components/Got_Project/GotProject";
import mobile_dev from "../../images/team.jpg";
import vector4 from "../../images/vector4.png";
import vector2 from "../../images/vector2.png";
import approach from "../../images/approach.svg";
import contact_footer from "../../images/contact-footer.png";
import agile_dev from "../../images/agile-dev.png";
import Aboutisoft from "../../components/About/Aboutisoft";
import communication from "../../images/communication.svg";
import scale from "../../images/scale.svg";
import mobile from "../../images/mobile1.jpg";
import experts from "../../images/experts.svg";
import testing from "../../images/testing.svg";
import manage from "../../images/manage.svg";
import MainTextCard from "../../components/Development/MainTextCard";
import AgileDevCard from "../../components/AgileDev/AgileDevCard";
import MobExpert from "../../components/Expert/MobExpert";
import OurApproach from "../../components/Approach/OurApproach";
import { TabTitle } from "../../Utils/GeneralFunction";
const MobileDevDetail = () => {
  TabTitle("Services | Mobile Development");
  return (
    <>
      <Banner
        heading="Mobile Development"
        detail="Connect With Your Users"
        btn_detail="Start a project"
        image={contact_footer}
        image_mobile={agile_dev}
        image_large={agile_dev}
        agile={true}
      />

      <MainTextCard
        heading="We built our first mobile app in 2011."
        detail="Since then, we’ve helped thousands of companies connect with people wherever they are in the world. Expand and develop your company via incentive applications that increase distribution and foster engagement. Break away from the competition and forge ahead on your digital transformation journey by utilizing mobile technology and expertise. Our custom mobile and IoT solutions are used for everything from streamlining diabetes care to auto-steering tractors to tracking and sharing bike rides. We have experience integrating native and hybrid mobile apps with devices like smart sensors, medical equipment, thermal imaging cameras, building automation systems, tractors, and many others."
      />

      <section className={`${styles.taber_section}`}>
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className={`${styles.tab_thumb}`}>
                <img alt="" src={mobile} />
              </div>
            </div>
            <div className="col-lg-6">
              <h2 className="font-weight-700 mb-4">Tech stack</h2>
              <div className="tech-tabs">
                <ul
                  className={`nav nav-tabs ${styles.mob_nav}`}
                  id="myTab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="One-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#One-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="One-tab-pane"
                      aria-selected="true"
                    >
                      <span className={`tech-thumb ${styles.tech_des}`}>
                        <img alt="" src={android} />
                      </span>
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="Two-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#Two-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="Two-tab-pane"
                      aria-selected="false"
                    >
                      <span className={`tech-thumb ${styles.tech_des}`}>
                        <img alt="" src={apple} />
                      </span>
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="Three-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#Three-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="Three-tab-pane"
                      aria-selected="false"
                    >
                      <span className={`tech-thumb ${styles.tech_des}`}>
                        <img alt="" src={experty1} />
                      </span>
                    </button>
                  </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="One-tab-pane"
                    role="tabpanel"
                    aria-labelledby="One-tab"
                    tabIndex="0"
                  >
                    <Tabs
                      heading="Android (Java/Kotlin)"
                      detail=" Our teams don’t need strict top-down management. They have
                      clear goals and a shared mission. In such environment, the
                      success of the team is perceived as the success of each
                      individual."
                    />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="Two-tab-pane"
                    role="tabpanel"
                    aria-labelledby="Two-tab"
                    tabIndex="0"
                  >
                    <Tabs
                      heading="IOS"
                      detail=" Apple provides tools and resources for creating iOS apps and accessories for these devices. OS application development is the process of making mobile applications for Apple hardware, including iPhone, iPad and iPod Touch."
                    />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="Three-tab-pane"
                    role="tabpanel"
                    aria-labelledby="Three-tab"
                    tabIndex="0"
                  >
                    <Tabs
                      heading="React Native"
                      detail="More than 15% of the 500 US apps are built with React Native, it is actually the mobile industry’s hot favorite framework for developing applications. Since its programming language is optimized for mobile devices, it works super-fast. Moreover, React Native saves time and money too because its code is cross-platform so as a result two apps are created at a time, the maintenance and updates are also done on both the apps simultaneously so the future costs can be reduced as well. iSoft Technologies use this magnificent technology on multiple platforms like IOS, android and even web applications too. With React Native, we deliver projects like Classic E-Commerce Apps, Lifestyle Apps, Event Apps and more.
"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr className="mb-0"></hr>
        </div>
      </section>
      <section className={`${styles.working_section}`}>
        <div className="container">
          <div className="row">
            <Aboutisoft
              everyimage={mobile_dev}
              extra={true}
              mob={true}
              sub_heading="Create technologically savvy software that breaks the mould"
              heading="   Design and develop state-of-the-art mobile apps with ease"
              detail="   One of the biggest misconceptions teams make going agile 
                        is the idea that Agile means “Work Without a Plan.” Often, 
                        companies with this philosophy end up confronting a mad race 
                        at the end of the project to hit deadlines and budget. 
                        We believe in creating an entire roadmap; specific enough 
                        to provide visibility into timelines. Through our communication 
                        and reporting department, clients get real-time updates into 
                        the timelines and budget forecasts. You will always know your 
                        project’s status at the level of detail you prefer, and it 
                        will be clear, consistent, and aligned to your needs."
            />

            <AgileDevCard
              heading="Business-tailored architecture"
              detail="  Analysis of requirements, business idea, and clarified formulation of goals. To follow business logic and provide scalability for future growth. Our team knows it instinctively."
              image={communication}
            />
            <AgileDevCard
              heading="Fast iterations & reliable CD"
              detail=" Design and development of iOS, Android and Cross-platform applications. Our experience allowed our team to develop ready-made templates that make it much easier to get a project off the ground. No time wasted. That's part of our mobile app development process."
              image={experts}
            />
            <AgileDevCard
              heading="World-className UX/UI"
              detail="With a sound mobile strategy in hand, we’re able to design the app’s experience and what it will look like on the front-end. We combine analytical insights with a keen understanding of design standards for each of the major mobile platforms. Our goal is always to reduce the friction of the mobile experience. We build intuitive experiences that get users what they want when they want it and connect them to the client’s business faster and more seamlessly."
              image={scale}
              large={true}
            />
            <AgileDevCard
              heading="Comprehensive QA"
              detail=" We apply automated and manual testing for all kinds of products developed to provide the proper product quality. Each deploy is accompanied by QA engineer and includes both manual and automated tests. They helps find bugs, improve performance and discover blockers."
              image={testing}
            />
            <AgileDevCard
              heading="Support"
              detail="Problems response and troubleshooting if the client reports any bugs. Custom web development solutions help you deploy your improvements more frequently and achieve better performance."
              image={manage}
            />
          </div>
          <hr className="mb-0"></hr>
        </div>
      </section>
      <section className={`${styles.expertin_section}`}>
        <div className={`container ${styles.expertin_inner}`}>
          <span className={`${styles.vector1}`}>&nbsp;</span>
          <span className={`${styles.vector2}`}>
            <img alt="" src={vector2} />
          </span>
          <span className={`${styles.vector3}`}>&nbsp;</span>
          <span className={`${styles.vector4}`}>
            <img alt="" src={vector4} />
          </span>
          <div className="row">
            <div className="col-lg-6">
              <h2 className="font-weight-700">
                We are experts in developing iOS, Android and Cross-platform
                apps.
              </h2>
              <div className={`${styles.expertin_thumb}`}>
                <img alt="" src={work1} />
              </div>
            </div>
            <div className="col-lg-6">
              <MobExpert
                detail="   We develop native iOS mobile apps. We can implement machine 
                            learning algorithms, touch ID or Face ID authorizations 
                            and integrate Apple Pay into mobile applications."
                title="iOS"
                image={communication}
              />

              <MobExpert
                detail=" ISoft Technologies develops native Android apps. 
                            We have expertise in GPS tracking and real-time 
                            synchronization implementation, as well as third 
                            party integrations, like payment systems."
                title="Android"
                image={communication}
              />
              <MobExpert
                detail=" We develop Cross-platform apps. Our team creates apps 
                            that are fully focused on the client’s needs. Developed 
                            apps can run across multiple device platforms."
                title="Cross-Platform"
                image={communication}
              />
            </div>
          </div>
        </div>
      </section>
      <OurApproach
        heading="    Why Businesses Choose Us For Mobile Application Development?"
        title_main="Our Approach"
        image={approach}
        detail1="   At iSoft Technologies, our top mobile app development 
                        services help clients craft usable digital products 
                        that increase retention, enhance user experience, 
                        and lower costs. Custom software and mobile applications 
                        evolve and scale with your business. What's more, by 
                        creating stable, secure apps, you build customer trust 
                        and maximize performance."
        detail2="  We offer native and cross-platform mobile solutions, 
                        KPI tracking, cloud support, and UX review, helping 
                        build easy-to-use, attention-retaining applications. 
                        Our extensive experience in having developed custom 
                        mobile applications for an exhaustive list of clients 
                        has given us the skills and expertise to work with any 
                        challenging mobile application development project. 
                        Our expert developers combine their skills and experience 
                        with industry best practices to provide the best mobile 
                        application for your business needs."
      />
      <GotProject every={true} />
      <Footer contacting={true} />
    </>
  );
};

export default MobileDevDetail;
